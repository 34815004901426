import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Flex } from '@moonpig/launchpad-components'
import {
  IconSystemPlusFilled,
  IllustrationCard,
} from '@moonpig/launchpad-assets'

const StyledBundleCardPlaceholder = styled.div`
  position: relative;
  display: block;
  width: 35%;
  border: 2px dashed currentColor;
  ${s({
    borderRadius: 2,
  })}
  &::before {
    content: '';
    display: block;
    padding-top: 144.46%;
  }
`
const StyledIllustrationCard = styled(IllustrationCard)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
`
const StyledPlusIcon = styled(IconSystemPlusFilled)`
  display: block;
  width: 8.5%;
`

const StyledGiftImage = styled.img`
  display: block;
  width: 50%;
  ${s({
    borderRadius: 2,
  })}
`

export const BundleImage: FC<{
  giftImageSrc: string
  giftTitle: string
}> = ({ giftImageSrc, giftTitle }) => {
  return (
    <Flex
      data-testid="bundle-image"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      color="colorPrimary02"
      bgcolor="colorBackground01"
    >
      <StyledBundleCardPlaceholder>
        <StyledIllustrationCard />
      </StyledBundleCardPlaceholder>
      <StyledPlusIcon />
      <StyledGiftImage src={giftImageSrc} alt={giftTitle} />
    </Flex>
  )
}
