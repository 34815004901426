import React, { FC, useEffect } from 'react'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { HeroWithPlacard } from './components/WithPlacard'
import { createPromotionImpressionGAEvent } from '../../analytics/createPromotionImpressionGAEvent'
import { FormattedCTAs, useCtaActions } from '../CtaAction/useCtaActions'
import { TextAndImageContainedHero } from './components/WithTextAndImageContained'
import {
  ModuleDynamicHeroFragment,
  ModuleHeroFragment,
} from './__generated__/fragment'
import { ModuleSection } from '../../components/ModuleContainer'
import { Module } from '../types'
import { ContainedFullWidthHero } from './components/ContainedFullWidthHero'

const getHeroForLayout = ({
  layout,
  module,
  formattedCtas,
}: {
  layout?: string
  module: ModuleHeroFragment | ModuleDynamicHeroFragment
  formattedCtas: FormattedCTAs
}) => {
  if (layout === 'home') {
    return <TextAndImageContainedHero {...module} ctas={formattedCtas} />
  }

  if (layout === 'blog' && module.heroVariant === 'FullWidth') {
    return <ContainedFullWidthHero {...module} />
  }

  return <HeroWithPlacard {...module} ctas={formattedCtas} />
}

export const HeroModule: FC<
  Module<ModuleHeroFragment | ModuleDynamicHeroFragment>
> = ({ module, context: { index, layout } }) => {
  const { mapActions } = useCtaActions()
  const { __typename, title, ctas, promotionId } = module
  const formattedCtas = mapActions({
    ctas,
    trackingData: { moduleIndex: index, moduleType: __typename },
    title,
    experiment: promotionId || 'Not bucketed',
  })

  useEffect(() => {
    trackGAEvent(
      createPromotionImpressionGAEvent(
        __typename,
        title,
        promotionId || 'Not bucketed',
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module])

  return (
    <ModuleSection data-testid="module-hero">
      {getHeroForLayout({
        layout,
        module,
        formattedCtas,
      })}
    </ModuleSection>
  )
}
