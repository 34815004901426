import React from 'react'
import { Box, Flex, Image } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { BundleImage } from '../BundleImage/BundleImage'
import { BundleProduct } from '../types'

const StyledImage = styled(Image)`
  height: 100%;
  margin: auto;
  display: block;
`

export const createCarouselImages = ({ gift }: { gift: BundleProduct }) => {
  const CombinedImage = (
    <Flex
      height="100%"
      key={gift.id}
      bgcolor="colorBackground02"
      alignItems="center"
    >
      <Box p={5} bgcolor="colorBackground01">
        <BundleImage giftImageSrc={gift.imageUrls[0]} giftTitle={gift.title} />
      </Box>
    </Flex>
  )

  const GiftImages = gift.imageUrls.map((giftImageUrl, index) => (
    <StyledImage
      key={giftImageUrl}
      data-testid="gift-image"
      src={giftImageUrl}
      alt={`${gift.title} ${index}`}
    />
  ))

  return [CombinedImage, ...GiftImages]
}
