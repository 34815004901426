import { gql } from '@moonpig/web-core-graphql'

export const ModuleSEOFragment = gql`
  fragment ModuleSEO on ModuleSEO {
    items {
      title
      text
      richText
    }
  }
`
