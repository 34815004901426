import React, { FC, useEffect } from 'react'
import { styled, breakpointDown, breakpoint } from '@moonpig/launchpad-utils'
import { Box, Grid, Text, PrimaryButton } from '@moonpig/launchpad-components'
import { ImageCarousel } from '@moonpig/web-shared-components'
import { system as s } from '@moonpig/launchpad-system'
import { DispatchCutOffBanner, ProductTabs } from '@moonpig/web-shared-products'
import { useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { BundleProduct, TrackingData } from '../types'
import { useAddBundleToBasket } from '../useAddBundleToBasket'
import { BundleContents } from './BundleContents'
import { createCarouselImages } from './createCarouselImages'
import { BundlePills } from '../BundlePills/BundlePills'
import { useFindLocaleText } from '../../../text-localisation'
import { createViewItemGaEvent } from './createViewItemGaEvent'
import { createSelectCardClickGAEvent } from './createSelectContentGAEvent'

const StyledDetailsWrapper = styled(Box)`
  text-align: center;
  ${breakpoint('md')} {
    text-align: left;
  }
`

const StyledCtaContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  ${s({
    borderTop: { xs: 1, md: 0 },
    borderColor: { xs: 'colorBorder04', md: 'none' },
    boxShadow: { xs: 4, md: 0 },
  })}
  ${breakpointDown('md')} {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    background-color: white;
    ${s({ p: 6, pb: 0 })}
  }
`

const StyledModalContainer = styled(Box)`
  width: 850px;
  ${s({
    pb: { xs: 13, md: 0 },
  })}
  ${breakpointDown('md')} {
    min-height: 100vh;
  }
`

const StyledFullPrice = styled(Text)`
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  ${s({
    color: 'colorBlack70',
    mr: 4,
  })}
`
type BundleDetailsProps = {
  gift: BundleProduct
  title: string
  formattedFullPrice: string
  formattedDiscountPrice?: string
  trackingData: TrackingData
  onAddToBasketError: () => void
}

export const BundleDetails: FC<BundleDetailsProps> = ({
  title,
  onAddToBasketError,
  gift,
  formattedFullPrice,
  trackingData,
  formattedDiscountPrice,
}) => {
  const { addBundleToBasket } = useAddBundleToBasket(gift, trackingData)
  const carouselItems = createCarouselImages({ gift })
  const { trackGAEventOnce } = useTrackGAEventOnce()
  const localiseText = useFindLocaleText()

  useEffect(() => {
    trackGAEventOnce(createViewItemGaEvent({ trackingData, gift }))
  }, [trackGAEventOnce, gift, trackingData])

  const addToBasketCallback = async () => {
    trackGAEventOnce(createSelectCardClickGAEvent(gift))
    const bundleAddedToBasket = await addBundleToBasket()
    if (!bundleAddedToBasket) onAddToBasketError()
  }
  return (
    <>
      <StyledModalContainer>
        <Grid
          gap
          itemScope
          itemType="https://schema.org/Product"
          itemRef="productInformationScope"
        >
          <Box width={{ xs: 1, md: 1 / 2 }}>
            <Box mb={6} position="relative">
              <ImageCarousel
                key={'ImageCarousel_key_id'}
                items={carouselItems}
                title={title}
              />
            </Box>
          </Box>
          <StyledDetailsWrapper width={{ xs: 1, md: 1 / 2 }}>
            <Text
              typography={{
                xs: 'typeMobileDisplay02',
                md: 'typeDesktopDisplay04',
              }}
              as={'p'}
              mb={6}
              itemProp="name"
            >
              {title}
            </Text>
            <Box mb={6}>
              {formattedDiscountPrice ? (
                <>
                  <StyledFullPrice typography="typeBodyLead">
                    {formattedFullPrice}
                  </StyledFullPrice>
                  <Text
                    typography={{
                      xs: 'typeMobileDisplay03',
                      md: 'typeDesktopDisplay05',
                    }}
                  >
                    {formattedDiscountPrice}
                  </Text>
                </>
              ) : (
                <>
                  <Text typography="typeBodyLead">From </Text>
                  <Text
                    typography={{
                      xs: 'typeMobileDisplay03',
                      md: 'typeDesktopDisplay05',
                    }}
                  >
                    {formattedFullPrice}
                  </Text>
                </>
              )}
            </Box>
            <Box mb={6}>
              <BundlePills pills={gift.pills} />
            </Box>
            <Box>
              <Box mx={{ xs: -5, md: 0 }} mb={6}>
                <BundleContents giftTitle={gift.title} />
                <DispatchCutOffBanner department={gift.department} />
              </Box>
              <StyledCtaContainer>
                <PrimaryButton
                  width="100%"
                  onClick={() => addToBasketCallback()}
                  mb={6}
                >
                  {localiseText('find.bundle.modal.add_to_basket')}
                </PrimaryButton>
              </StyledCtaContainer>
            </Box>
          </StyledDetailsWrapper>
          <meta content={gift.id} itemProp="sku" />
        </Grid>
        <ProductTabs
          description={localiseText(
            'find.bundle.modal.description',
            gift.description,
          )}
          productId={gift.id}
          department={gift.department}
        />
      </StyledModalContainer>
    </>
  )
}
