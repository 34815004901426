import { Box } from '@moonpig/launchpad-components'
import {
  createPromoClickEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import React, { FC } from 'react'

import { useRemindersModalContext } from '@moonpig/web-core-context'
import { IllustrationBell } from '@moonpig/launchpad-assets'
import { CtaStrip } from '../../components/CtaStrip'
import { ModuleCTAStrip } from '../../types'

type TrackingData = { moduleIndex: number; moduleType: string }

const RemindersCTAStripModule: FC<
  ModuleCTAStrip & {
    trackingData: TrackingData
  }
> = ({ text, backgroundImage, cta, trackingData, banner }) => {
  const modalContext = useRemindersModalContext()
  const trackEvent = createPromoClickEvent({
    title: text,
    ctaTitle: cta.title,
    ...trackingData,
  })

  const onClick = () => {
    trackGAEvent(trackEvent)
    modalContext.setShow(true)
  }

  return (
    <Box pb={banner ? { xs: 1, lg: 2 } : { xs: 6, lg: 8 }}>
      <CtaStrip
        compact={banner}
        title={text}
        data-testid="reminders-cta-strip-module"
        backgroundImageUrl={backgroundImage?.url}
        icon={<IllustrationBell />}
        cta={{
          title: cta.title,
        }}
        onClick={onClick}
      />
    </Box>
  )
}

export { RemindersCTAStripModule }
