import React, { useEffect, useMemo, useReducer, useState } from 'react'
import {
  getParentDepartment,
  useLuxLabel,
  useLuxMeasure,
  getSearchCriteria,
} from '@moonpig/web-core-utils'
import {
  LARGE_CARD_SIZE_ID,
  DispatchCutOffQuery,
  ProductInitialVariantSelectionProvider,
  GIANT_CARD_SIZE_ID,
  RecentlyViewedProvider,
  ProductModalProvider,
  FavouritesProvider,
  E_CARD_SIZE_ID,
  PageTypeEnum,
  BasketProvider,
  DispatchCutOffGQL,
  DispatchCutOffQueryVariables,
} from '@moonpig/web-shared-products'
import {
  PageEnvironment,
  PageOptions,
  PageContext,
} from '@moonpig/web-core-app'
import { logger } from '@moonpig/web-core-monitoring'
import { useRouter } from '@moonpig/web-core-routing'
import Head from 'next/head'
import {
  Region as GraphQLRegion,
  SortOrder,
} from '@moonpig/web-explore-types-graphql'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { useApolloClient } from '@moonpig/web-core-graphql'
import { Region } from '@moonpig/web-core-types'
import { useFlags } from '@moonpig/web-explore-flags'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { Router } from 'next/router'
import { Gallery, GalleryDetails } from '../../components/Gallery'
import {
  getContent,
  getSearchParameters,
  getSearchContext,
  SearchContextProvider,
  useLuxAddExperimentData,
} from '../../utils'
import { PageContent, PageMeta } from '../../types'
import { CmsContent, SearchFilters } from './types'
import { ProductSearchData } from '../../utils/productSearch'
import {
  ProductListingPageProvider,
  reducer,
  initialState,
} from '../../utils/productListingPageContext'
import {
  GALLERY_PAGE_SIZE,
  GALLERY_PAGE_TYPE,
  GALLERY_RESULTS_TYPE,
  notRudeFilter,
} from '../../constants'
import { useGalleryLoadMore } from '../../utils/gallery/galleryLoadMore'
import { getHeadLinksTags } from '../../utils/gallery/galleryPagination'
import { getExperimentsFromCampaigns } from '../../utils/getExperimentsFromCampaigns'
import {
  ServiceOptions,
  SearchFeatures,
  SearchRequestResponse,
  createServices,
  loadFeatures,
  loadOptions,
} from '../../services'
import { useSearch } from '../../utils/search/useSearch'
import { useTrackLoadedProducts } from '../../utils/useTrackLoadedProducts'
import { useTrackSearchEvent } from '../../utils/useTrackSearchEvent'
import { SearchFilter } from '../../services/types/services'
import { useSearchStore } from '../../store/SearchStore'
import { removeDuplicateFilters } from '../../utils/FilteringUtils/filterItemUtils'
import { DrawerProvider } from '../../contexts/drawer'

type GalleryPageProps = {
  initialResponse: SearchRequestResponse
  page: number
  details: GalleryDetails
  content: CmsContent
  pageOptions?: PageOptions
  searchFilters: SearchFilters
  experiments?: { [key: string]: string } | undefined
  initialData?: ProductSearchData
  urlFacets: {
    label?: string
    facetKey: string
    group: string
    userApplied?: boolean
  }[]
  modified?: boolean
  dispatchCutOffData?: DispatchCutOffQuery
  shouldDisplaySoldOutMessage?: boolean
  environment?: PageEnvironment
  meta?: PageMeta
  groupCardProject?: string
  options: ServiceOptions
  features: SearchFeatures
  region: Region
}

const getVariantKeyFromFacet = ({
  initialFacets,
}: {
  initialFacets: SearchFilters['facets']
}): string | undefined => {
  const initialSelectedFacet = initialFacets.find(({ facetKey }) =>
    ['giant-cards', 'large-cards', 'e-cards'].includes(facetKey as string),
  )

  switch (initialSelectedFacet?.facetKey) {
    case 'giant-cards':
      return GIANT_CARD_SIZE_ID
    case 'large-cards':
      return LARGE_CARD_SIZE_ID
    case 'e-cards':
      return E_CARD_SIZE_ID
  }
}

export const GalleryPage: PageContent<GalleryPageProps> = ({
  page: initialPage,
  initialResponse,
  details,
  content,
  searchFilters,
  experiments,
  urlFacets,
  modified = false,
  dispatchCutOffData,
  shouldDisplaySoldOutMessage,
  environment,
  meta,
  groupCardProject,
  options,
  features,
  region,
}) => {
  const { department, searchTerm } = details
  const router = useRouter()
  const client = useApolloClient()
  const flags = useFlags()
  const { loggedIn } = useLoggedIn()

  const createGalleryPageContextData = useSearchStore(
    store => store.createPageContext,
  )
  const filtersFromStore = useSearchStore(store => store.selectedFilters)
  const loadFilters = useSearchStore(store => store.loadFilters)
  const sortValue: SortOrder = useSearchStore(store => store.sortValue)
  const filteringEventFromStore = useSearchStore(store => store.filteringEvent)

  const services = useMemo(() => {
    const context = {
      region,
      options,
      features,
      client,
      cookies: getBrowserCookies(),
    }

    return createServices(context)
  }, [client, features, options, region])

  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    /* istanbul ignore next */
    const handleRouteStart = () => {
      setScrollY(window.scrollY)
    }

    /* istanbul ignore next */
    const handleRouteComplete = () => {
      window.scrollTo(0, scrollY)
    }

    Router.events.on('routeChangeStart', handleRouteStart)
    Router.events.on('routeChangeComplete', handleRouteComplete)

    return () => {
      Router.events.off('routeChangeStart', handleRouteStart)
      Router.events.off('routeChangeComplete', handleRouteComplete)
    }
  })

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    modified,
    initialOffset: initialPage * GALLERY_PAGE_SIZE - GALLERY_PAGE_SIZE,
  })

  const clientSideRoutingEnabled = false

  const combinedSearchFilters = useMemo(() => {
    const preappliedFacets = searchFilters.facets
    const appliedFacets = filtersFromStore.map(f => ({
      facetKey: f.id,
      group: f.parent,
      userApplied: true,
    }))

    return removeDuplicateFilters([
      ...appliedFacets,
      ...preappliedFacets,
      ...urlFacets,
    ]).map(x => ({
      key: x.facetKey,
      group: x.group,
      userApplied: x.userApplied,
    }))
  }, [filtersFromStore, searchFilters.facets, urlFacets])

  const {
    loadedState,
    products,
    loading,
    count,
    loadMore,
    totalClickRankDocumentCount,
  } = useSearch({
    services,
    initialResponse,
    parameters: {
      filters: combinedSearchFilters,
      sort: sortValue,
      ...details,
    },
  })

  useLuxLabel('Gallery Page')
  useLuxMeasure(clientSideRoutingEnabled)
  const luxAbTestCustomData = [
    ...Object.entries(experiments ?? {}).map(([name, value]) => ({
      name: `experiment_${name}`,
      value,
    })),
    {
      name: 'search_department',
      value: department.length
        ? getParentDepartment(department[0])
        : 'ALL_CARDS',
    },
    {
      name: 'search_product_count',
      value: count.toString(),
    },
    {
      name: 'search_applied_filters',
      value: filtersFromStore.length.toString(),
    },
    {
      name: 'search_preapplied_filters',
      value: searchFilters.facets.length.toString(),
    },
    {
      name: 'user_is_authenticated',
      value: loggedIn.toString(),
    },
  ]
  useLuxAddExperimentData(luxAbTestCustomData)

  useTrackSearchEvent({
    correctedSearchTerm: '',
    department,
    filteringEvent: filteringEventFromStore,
    filters: combinedSearchFilters,
    numberOfResults: count,
    searchTerm: details.searchTerm,
    sort: sortValue,
    pageTitle: content.title,
    pageType: GALLERY_PAGE_TYPE,
  })

  useTrackLoadedProducts({
    ...loadedState,
    region,
    products,
    totalCount: count,
    searchTerm,
    department,
    sortOrder: sortValue,
    pageType: GALLERY_PAGE_TYPE,
    pageTitle: content.title,
    facets: combinedSearchFilters.map(f => ({
      facetKey: f.key,
      ...f,
    })),
  })

  const filtersQuery = (clientFilters: SearchFilter[]) =>
    services.loadFilters({
      facets: clientFilters,
      departments: department,
      promotionId: searchFilters.promotionId,
      searchTerm,
    })

  useEffect(() => {
    createGalleryPageContextData({
      pageContext: { title: content.title, type: GALLERY_PAGE_TYPE, router },
    })
    loadFilters({
      query: filtersQuery,
      initialFilters: searchFilters.facets.map(f => ({
        key: f.facetKey,
        group: f.group,
      })),
      initialSortValue: sortValue,
      urlFilters: urlFacets.map(f => ({
        key: f.facetKey,
        group: f.group,
        userApplied: f.userApplied,
      })),
      results: { count },
      useOptimisticRendering: flags['enable-filter-optimistic-render'],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', payload: loading })
  }, [loading])

  const { onLoadMoreCallback } = useGalleryLoadMore({
    dispatch,
    loadMoreCallback: loadMore,
    totalCount: count,
    state,
  })

  const headLinkTags = getHeadLinksTags({
    environment,
    path: details?.url,
    canonicalSubPath: meta && meta.canonicalSubPath,
    offset: initialPage * GALLERY_PAGE_SIZE - GALLERY_PAGE_SIZE,
    count,
  })

  const galleryMetaTitle = String(meta?.title.split('|')[0].trim())

  const variantKey = getVariantKeyFromFacet({
    initialFacets: searchFilters.facets,
  })

  return (
    <ProductInitialVariantSelectionProvider variantKey={variantKey}>
      <RecentlyViewedProvider>
        <BasketProvider>
          <FavouritesProvider
            location={content.title}
            enableToastNotification={flags['enable-fav-toast-notification']}
          >
            <DrawerProvider>
              <ProductModalProvider
                context={{
                  location: content.title,
                  pageType: PageTypeEnum.GALLERY,
                  filters: [...searchFilters.facets],
                  totalNumberOfProducts: count,
                  flags,
                }}
              >
                <ProductListingPageProvider
                  productListingContext={[state, dispatch]}
                >
                  <SearchContextProvider
                    departments={department}
                    searchTerm={searchTerm}
                    presetFacets={searchFilters.facets}
                  >
                    {headLinkTags && (
                      <Head>
                        {headLinkTags.canonical && (
                          <link
                            rel="canonical"
                            key="content-head-canonical"
                            data-testid="content-head-canonical"
                            href={headLinkTags.canonical}
                          />
                        )}
                        {headLinkTags.prev && (
                          <link rel="prev" href={headLinkTags.prev} />
                        )}
                        {headLinkTags.next && (
                          <link rel="next" href={headLinkTags.next} />
                        )}
                        <meta
                          property="og:url"
                          content={headLinkTags.canonical || undefined}
                        />
                      </Head>
                    )}
                    <Gallery
                      content={content}
                      metaTitle={galleryMetaTitle}
                      details={details}
                      totalCount={count}
                      products={products}
                      presetFacets={searchFilters.facets}
                      loadMoreProducts={onLoadMoreCallback}
                      experiments={experiments}
                      dispatchCutOffData={dispatchCutOffData}
                      shouldDisplaySoldOutMessage={shouldDisplaySoldOutMessage}
                      groupCardProject={groupCardProject}
                      totalClickRankDocumentCount={totalClickRankDocumentCount}
                    />
                  </SearchContextProvider>
                </ProductListingPageProvider>
              </ProductModalProvider>
            </DrawerProvider>
          </FavouritesProvider>
        </BasketProvider>
      </RecentlyViewedProvider>
    </ProductInitialVariantSelectionProvider>
  )
}

GalleryPage.getInitialProps = async ({
  environment,
  flags,
  getAllCampaigns,
  getCookie,
  getExperiments,
  graphQL,
  modules,
  path,
  platform,
  query: typedQuery,
  region,
}) => {
  const cookies = {
    mnpg_exclude_rude_products: getCookie('mnpg_exclude_rude_products'),
  }
  const experiments = await getExperiments()

  const searchParameters = getSearchParameters(modules)

  const searchCriteria = getSearchCriteria(
    typedQuery,
    searchParameters.departments,
  )

  const galleryDepartments = searchCriteria.department
  const galleryParentDepartment = getParentDepartment(galleryDepartments[0])

  const options = loadOptions({
    query: typedQuery,
    pageType: GALLERY_PAGE_TYPE,
    platform,
    department: galleryParentDepartment,
  })

  const features = loadFeatures({
    experiments,
    region,
    flags,
  })

  const context = {
    region,
    options,
    features,
    client: graphQL,
    cookies,
  }

  const services = createServices(context)

  const query = typedQuery as unknown as PageContext['query']
  const getAllCampaignsPromise = getAllCampaigns()

  const urlFacets = searchCriteria.filters

  if (searchCriteria.promotionId) {
    searchParameters.searchFilters.promotionId = searchCriteria.promotionId
  }

  const dispatchCutOffPromise = graphQL.query<
    DispatchCutOffQuery,
    DispatchCutOffQueryVariables
  >({
    query: DispatchCutOffGQL,
    variables: {
      filter: [galleryDepartments[0], galleryParentDepartment],
      region: region as GraphQLRegion,
      preview: undefined,
    },
  })

  const searchTerm = typedQuery.q || searchParameters?.searchTerm

  const content = getContent(modules)

  const page = (Number(query.offset || 0) || 0) / GALLERY_PAGE_SIZE + 1

  const { parts } = typedQuery

  const excludeRude =
    cookies.mnpg_exclude_rude_products === 'true' &&
    !parts?.includes('naughty') &&
    !parts?.includes('rude')

  const combinedFacets = [
    ...searchParameters.searchFilters.facets,
    ...urlFacets,
    ...(excludeRude ? [notRudeFilter] : []),
  ]

  const searchFilters: SearchFilter[] = removeDuplicateFilters(combinedFacets)
    .filter(x => x.facetKey)
    .map(x => ({ group: x.group, key: x.facetKey, userApplied: x.userApplied }))

  try {
    const initialResponse = await services.search({
      searchTerm,
      filters: searchFilters,
      departments: galleryDepartments,
      sponsoredProducts: searchParameters.sponsoredProducts,
      promotionId: searchParameters.searchFilters.promotionId,
      page,
    })

    const { data: dispatchCutOffData } = await dispatchCutOffPromise

    const { products, totalCount } = initialResponse
    const shouldDisplaySoldOutMessage = products && products.length === 0

    return {
      props: {
        initialResponse,
        page,
        details: {
          region,
          url: path,
          department: galleryDepartments,
          searchTerm,
          promotionId: searchParameters?.searchFilters?.promotionId,
          sponsoredProducts: searchParameters.sponsoredProducts,
        },
        searchFilters: searchParameters.searchFilters,
        content,
        experiments: getExperimentsFromCampaigns(await getAllCampaignsPromise),
        urlFacets,
        dispatchCutOffData,
        shouldDisplaySoldOutMessage,
        environment,
        groupCardProject: query.groupCardProject as string,
        options,
        features,
        region: region as Region,
      },
      pageOptions: {
        search: getSearchContext(modules),
        tracking: {
          type: GALLERY_RESULTS_TYPE,
          sortby: 'popularity - default',
          listData: {
            results: totalCount,
            type: GALLERY_RESULTS_TYPE,
            name: content && content.title,
            terms: searchTerm,
            filters: searchParameters && searchParameters.searchFilters,
          },
        },
      },
    }
  } catch (error) {
    logger.fixToday('Gallery products query failure', undefined, error)
    return {
      pageError: {
        statusCode: 500,
      },
    }
  }
}
