import { BundleGiftConfig } from './types'

export const BUNDLE_GIFT_SKUS: BundleGiftConfig[] = [
  { sku: 'HAMP505', pills: ['selling-fast'] },
  { sku: 'AR422948', pills: ['favourite'] },
  { sku: 'BEER402', pills: ['selling-fast'] },
  { sku: 'AR420715', pills: ['favourite'] },
  { sku: 'BEER409', pills: ['selling-fast'] },
  { sku: 'NVLTY237B', pills: ['exclusive'] },
  { sku: 'NVLTY273B', pills: ['exclusive'] },
  { sku: 'SWEE683', pills: ['favourite'] },
  { sku: 'NWSFG2-DS', pills: ['selling-fast'] },
  { sku: 'CHOC799', pills: ['selling-fast'] },
  { sku: 'AR422955', pills: ['exclusive'] },
  { sku: 'NVLTY209B', pills: ['exclusive'] },
  { sku: 'CHOC1340', pills: ['exclusive'] },
  { sku: 'NVLTY274B', pills: ['exclusive'] },
  { sku: 'SFT1126', pills: ['favourite'] },
  { sku: 'ALC275', pills: ['selling-fast'] },
  { sku: 'CHOC1331', pills: ['favourite'] },
  { sku: 'AR420814', pills: ['selling-fast'] },
  { sku: 'AR422949', pills: ['favourite'] },
  { sku: 'NVLTY368', pills: ['selling-fast'] },
  { sku: 'BEER400', pills: ['favourite'] },
  { sku: 'NVLTY278B', pills: ['exclusive'] },
  { sku: 'ALC611', pills: ['exclusive'] },
  { sku: 'CHOC1142', pills: ['selling-fast'] },
]
