import React, { FC } from 'react'
import { Text } from '@moonpig/launchpad-components'
import { ModuleBlogPostDate } from '../../types'

export const BlogPostDateModule: FC<ModuleBlogPostDate> = ({ date }) => {
  const newDate = new Date(date)
  const dateTimeFormat = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  const formattedDate = dateTimeFormat.format(newDate)

  return (
    <Text typography="typeBodyCaption" color="colorBlack40">
      {formattedDate}
    </Text>
  )
}
