import { ContentModule } from '../../../types'
import { PipelineStep } from '../pipeline'
import { MODULE_CTA_STRIP } from '../constants'

const CTA_STRIP_KEY = 'logged-in'
const LOGGED_IN_VALUE = 'yes'
const LOGGED_OUT_VALUE = 'no'

export const ctaStripStep: PipelineStep<ContentModule> =
  ({ loggedIn }, next) =>
  modules => {
    const processedModules = next(modules)
    const LOGGED_IN_VARIANT_VALUE = loggedIn
      ? LOGGED_IN_VALUE
      : LOGGED_OUT_VALUE

    const filteredModules = processedModules.filter(module => {
      if (
        module.type === MODULE_CTA_STRIP &&
        module.meta?.variation?.key === CTA_STRIP_KEY
      ) {
        return module.meta.variation.value === LOGGED_IN_VARIANT_VALUE
      }

      return true
    })

    return [...filteredModules]
  }
