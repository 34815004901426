import React, { FC, useEffect, useReducer, useState } from 'react'
import { Modal as ModalContainer } from '@moonpig/launchpad-components'
import {
  trySetLocalStorage,
  tryGetLocalStorage,
} from '../../utils/localStorage'
import { ModuleEmailCaptureFragment } from './__generated__/fragment'
import { useExitIntent } from '../../utils/useExitIntent'
import { EmailForm, ThankYou } from './components'
import {
  privacyNoticeClickEventData,
  modalDismissSelectContent,
  track,
  initialImpression,
} from './tracking/events'
import { initialState, reducer } from './store/reducer'
import { useActions } from './store/actions'
import { HAS_DISMISSED_NEWSLETTER_SUBSCRIPTION_MODAL_KEY } from './store/constants'

export const Modal: FC<ModuleEmailCaptureFragment> = ({
  heading,
  text,
  thankYouHeading,
  thankYouText,
}) => {
  const isModal = true
  const { exitIntent } = useExitIntent()
  const [{ error, showThankYou }, dispatch] = useReducer(reducer, initialState)
  const { registerEmail } = useActions(dispatch, true)

  const isDismissed = tryGetLocalStorage(
    HAS_DISMISSED_NEWSLETTER_SUBSCRIPTION_MODAL_KEY,
  )
  const [isOpen, setOpen] = useState(false)

  const handleDismiss = () => {
    trySetLocalStorage(HAS_DISMISSED_NEWSLETTER_SUBSCRIPTION_MODAL_KEY, 'true')

    track(modalDismissSelectContent({ showThankYou }))

    setOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      track(initialImpression({ isModal }))
    }
  }, [isOpen, isModal])

  useEffect(() => {
    setOpen(exitIntent && !isDismissed)
  }, [exitIntent, isDismissed])

  return (
    <ModalContainer
      testId="module-email-capture-modal"
      label="Newsletter Subscription"
      isOpen={isOpen}
      onDismiss={handleDismiss}
      hasCloseButton
      closeButtonLabel="Close"
      mobilePagePeek
      colours={{ background: 'colorBackground03' }}
    >
      {showThankYou ? (
        <ThankYou
          heading={thankYouHeading}
          text={thankYouText}
          showContinueShopping
          onContinueShoppingClicked={handleDismiss}
        />
      ) : (
        <EmailForm
          heading={heading}
          text={text}
          onSubmit={registerEmail}
          privacyNoticeEventData={privacyNoticeClickEventData({ isModal })}
          error={error}
        />
      )}
    </ModalContainer>
  )
}
