import React, { FC, useState } from 'react'
import { Image, Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { YoutubeIconButton } from './YoutubeIconButton'

type EmbeddedVideoProps = {
  id: string
  title: string
}

type IframeWrapperProps = {
  isPlaying: boolean
}

const StyledIframeWrapper = styled(Box)<IframeWrapperProps>`
  background-color: black;
  position: relative;

  padding-bottom: 56.25%;
  height: 0;

  img {
    width: 100%;
    height: 100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledThumbnailBox = styled(Box)`
  img,
  button {
    position: absolute;
  }
`

export const EmbeddedVideo: FC<EmbeddedVideoProps> = ({ id, title }) => {
  const [playVideo, setPlayVideo] = useState<boolean>(false)

  return (
    <Box maxWidth="800px" margin="0 auto">
      <StyledIframeWrapper isPlaying={playVideo}>
        {playVideo ? (
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${id}?autoplay=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={title}
          />
        ) : (
          <>
            <StyledThumbnailBox position="absolute" width="100%" height="100%">
              <Image
                width="100%"
                height="100%"
                src={`https://img.youtube.com/vi/${id}/maxresdefault.jpg`}
                alt={title}
              />
              <YoutubeIconButton
                width="100%"
                height="100%"
                onClick={() => setPlayVideo(true)}
              />
            </StyledThumbnailBox>
          </>
        )}
      </StyledIframeWrapper>
    </Box>
  )
}
