import React from 'react'
import {
  CarouselState,
  CarouselControls,
  CarouselPreviousButton,
  CarouselNextButton,
  UnstyledCarouselRoot,
  UnstyledCarouselScrollArea,
  CarouselItem,
  Chip,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useIsMobile } from '../../utils'

const StyledCarouselRoot = styled(UnstyledCarouselRoot)`
  ${s({ mb: { xs: 6, lg: 8 } })}
`

const StyledCarouselScrollArea = styled(UnstyledCarouselScrollArea)`
  ${s({
    px: { xs: 6, lg: 0 },
    gap: { xs: 8, md: 16 },
    justifyContent: { xs: 'initial', md: 'center' },
  })}
`

type Props<T extends { title: string }> = {
  tabs: T[]
  selectedTab: T
  onTabSelectionChanged: (selectedTab: T) => void
  ariaLabel: string
}

export const TabCarousel = <T extends { title: string }>({
  tabs,
  selectedTab,
  onTabSelectionChanged,
  ariaLabel,
}: Props<T>) => {
  const showScrollButtons = !useIsMobile()

  return (
    <CarouselState>
      <StyledCarouselRoot aria-label={ariaLabel}>
        {showScrollButtons && (
          <CarouselControls>
            <CarouselPreviousButton />
            <CarouselNextButton />
          </CarouselControls>
        )}
        <StyledCarouselScrollArea>
          {tabs.map(list => (
            <CarouselItem key={list.title} tabIndex={-1}>
              <Chip
                selected={selectedTab === list}
                onClick={() => onTabSelectionChanged(list)}
              >
                {list.title}
              </Chip>
            </CarouselItem>
          ))}
        </StyledCarouselScrollArea>
      </StyledCarouselRoot>
    </CarouselState>
  )
}
