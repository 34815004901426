import { debounce } from '@moonpig/launchpad-utils'
import { useEffect, useState } from 'react'

export const MOUSE_DEPTH_TRIGGER_FACTOR = 0.5
export const WINDOW_EDGE_THRESHOLD = 32
export const SCROLL_DEPTH_TRIGGER_FACTOR = 1.5
export const SCROLL_EVENT_LISTENER_DEBOUNCE_DELAY = 100
export const MOUSE_EVENT_LISTENER_DEBOUNCE_DELAY = 25

export const useExitIntent = () => {
  const [exitIntent, setExitIntent] = useState(false)

  useEffect(() => {
    const scrollDepthTriggerPoint =
      window.innerHeight * SCROLL_DEPTH_TRIGGER_FACTOR
    const mouseDepthTriggerPoint =
      window.innerHeight * MOUSE_DEPTH_TRIGGER_FACTOR

    let currentScrollPoint = window.scrollY
    let scrolledPastTrigger = false
    let mousePastTrigger = false

    const handleWindowScroll = debounce(() => {
      /* istanbul ignore else */
      if (!exitIntent) {
        if (window.scrollY > scrollDepthTriggerPoint) {
          scrolledPastTrigger = true
        }

        if (scrolledPastTrigger && window.scrollY < currentScrollPoint) {
          setExitIntent(true)
        }
        currentScrollPoint = window.scrollY
      }
    }, SCROLL_EVENT_LISTENER_DEBOUNCE_DELAY)

    const handleMouseMove = debounce((event: MouseEvent) => {
      /* istanbul ignore else */
      if (!exitIntent) {
        if (event.clientY > mouseDepthTriggerPoint) {
          mousePastTrigger = true
        }

        if (mousePastTrigger && event.clientY < WINDOW_EDGE_THRESHOLD) {
          setExitIntent(true)
        }
      }
    }, MOUSE_EVENT_LISTENER_DEBOUNCE_DELAY)

    window.addEventListener('scroll', handleWindowScroll)
    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
      window.removeEventListener('mousemove', handleMouseMove)
    }
  })

  return { exitIntent }
}
