import React, { FC } from 'react'
import styled from 'styled-components'
import { system as s } from '@moonpig/launchpad-system'
import { Box, Pill, Text } from '@moonpig/launchpad-components'
import {
  CardProductTile,
  ProductItemEventEnum,
  createPersonaliseGAEvent,
  createProductItemGAEvent,
} from '@moonpig/web-shared-products'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { getDepartmentTitle } from '@moonpig/web-core-utils'
import { Product } from '@moonpig/web-shared-favourites'
import { ContinueJourneyProduct } from '../../types'

type ProductCardProps = {
  product: ContinueJourneyProduct
  tracking: {
    pageLocation: string
    carouselLocation: number
    totalItems: number
    componentName: string
    productIndex: number
  }
  label: string
  showPill: boolean
  isFavourited: boolean
  handleFavourite: (
    product: Product,
    isFavouriteSelected: boolean,
    index: number,
  ) => Promise<{ removeWithConfirmation: boolean }>
}

const PILLS_CONTAINER_MIN_HEIGHT = '30px'

const StyledPillContainer = styled.div`
  display: inline-block;
  min-height: ${PILLS_CONTAINER_MIN_HEIGHT};
  box-sizing: content-box;
  ${() =>
    s({
      pb: 6,
    })};
`

const StyledBox = styled(Box)<{ isDraftProduct?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${({ isDraftProduct }) => {
    return s({
      marginRight: isDraftProduct ? 6 : 0,
      paddingBottom: 6,
      width: {
        xs: '36vw',
        md: '248px',
      },
    })
  }}
`

export const ProductCard: FC<ProductCardProps> = ({
  product,
  tracking: {
    pageLocation,
    carouselLocation,
    totalItems,
    componentName,
    productIndex,
  },
  showPill,
  label,
  isFavourited,
  handleFavourite,
}) => {
  const handleClick = () => {
    trackGAEvent(
      createProductItemGAEvent({
        eventType: ProductItemEventEnum.SELECT_ITEM,
        product,
        index: 0,
        label: `${pageLocation} | pick up where you left off | ${label.toLowerCase()} | carousel | ${carouselLocation}/${totalItems} | ${
          product.id
        }`,
        listName: `${pageLocation} | pick up where you left off | ${label.toLowerCase()} | carousel`,
        variant: product.masterVariant.title,
      }),
    )
    if (product.isDraftProduct) {
      trackGAEvent(
        createPersonaliseGAEvent({
          componentName,
          productData: {
            id: product.id,
            name: product.title,
            price: product.masterVariant.price.centAmount,
            quantity: 1,
            category: product.category.name,
          },
        }),
      )
    }
  }

  return (
    <StyledBox isDraftProduct={product.isDraftProduct}>
      <StyledPillContainer>
        {showPill && <Pill label={label} variant="marketing" />}
      </StyledPillContainer>
      <Box width={1}>
        <CardProductTile
          href={product.url}
          image={{
            src: product.image,
            alt: product.title,
          }}
          onClick={handleClick}
          favourite={
            !product.isDraftProduct
              ? {
                  isSelected: isFavourited,
                  onSelect: () =>
                    handleFavourite(product, !!isFavourited, productIndex),
                }
              : undefined
          }
        />
      </Box>
      {getDepartmentTitle(product.category.department) !== 'cards' && (
        <Text mt={6} typography="typeBodyCaption">
          {product.title}
        </Text>
      )}
    </StyledBox>
  )
}
