import React, { FC, useEffect } from 'react'
import {
  Box,
  Container,
  PrimaryButton,
  Flex,
  Image,
  Text,
} from '@moonpig/launchpad-components'
import { useStore } from '@moonpig/web-core-stores'
import { system as s } from '@moonpig/launchpad-system'
import { Link } from '@moonpig/web-core-routing'
import { breakpointUp, styled, useTheme } from '@moonpig/launchpad-utils'
import { IllustrationBackgroundReminders } from '@moonpig/launchpad-assets'
import { useInView } from 'react-intersection-observer'
import { ProductTileWithBasketHandler } from '@moonpig/web-shared-products'
import { useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { Markup } from '@moonpig/web-shared-components'
import { useFindLocaleText } from '../../../../text-localisation'
import mascot from './assets/upcoming-occasion-mascot.png'
import balloons from './assets/upcoming-occasion-desktop-balloons.svg'
import {
  upcomingOccasionsV2ImpressionEvent,
  upcomingOccasionsV2LoadEvent,
  upcomingOccasionV2ShopForReminderEvent,
} from './analytics'
import { AnimatedBox } from './AnimatedBox'
import { UpcomingReminderOccasionQuery } from './__generated__/ShopForReminderModule'

export type UpcomingReminderOccasion_nextUnfulfilledReminder = NonNullable<
  UpcomingReminderOccasionQuery['nextUnfulfilledReminder']
>

type Reminder = UpcomingReminderOccasion_nextUnfulfilledReminder

type ShopForReminderContentProps = {
  loading: boolean
  error: boolean
  reminder: Reminder | undefined | null
}

enum State {
  LOADING,
  NO_REMINDER,
  LOADED_REMINDER,
  ERROR,
}

type ComponentState =
  | {
      state: State.LOADING
    }
  | { state: State.NO_REMINDER }
  | { state: State.LOADED_REMINDER; reminder: Reminder }

const useShopForReminderState = ({
  reminder,
  loading,
}: ShopForReminderContentProps): ComponentState => {
  if (loading) return { state: State.LOADING }
  if (reminder) return { state: State.LOADED_REMINDER, reminder }
  return { state: State.NO_REMINDER }
}

const BalloonContainer = styled(Image)`
  ${breakpointUp('md')} {
    display: none;
  }

  ${breakpointUp('lg')} {
    display: unset;
    position: absolute;
    right: -50px;
    top: -25px;
  }
`

const DesktopBackgroundImageTopLeft = styled(IllustrationBackgroundReminders)`
  position: absolute;
  top: -15px;
  left: -25px;
  height: 60%;
`

const DesktopMascotContainer = styled(Image)`
  ${breakpointUp('md')} {
    width: 100px;
  }

  ${breakpointUp('lg')} {
    width: 170px;
  }
`

const DesktopMainText = styled(Text)`
  ${s({
    typography: {
      md: 'typeDesktopDisplay04',
      lg: 'typeDesktopDisplay02',
      xl: 'typeDesktopDisplay01',
    },
  })}
`

const DesktopBackgroundImageBottomRight = styled(
  IllustrationBackgroundReminders,
)`
  position: absolute;
  bottom: -15px;
  right: -15px;
  height: 60%;
`

const Loaded: FC<{ reminder: Reminder }> = ({ reminder }) => {
  const localiseText = useFindLocaleText()
  const { store } = useStore()
  const theme = useTheme()

  const { trackGAEventOnce } = useTrackGAEventOnce()

  const [componentInViewRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      trackGAEventOnce(upcomingOccasionsV2ImpressionEvent)
    }
  }, [inView, trackGAEventOnce])

  const trackSelectContent = () => {
    trackGAEventOnce(upcomingOccasionV2ShopForReminderEvent)
  }

  const headingText = localiseText('find.reminder_card.heading', {
    name: reminder.name,
    occasion: reminder.occasion,
    withLineBreak: true,
  })

  const daysUntilText = localiseText('find.shop_for_reminder.days_until', {
    dateOfEvent: new Date(reminder.nextDate),
    emphasisedTextColour: 'colorInteractionButton',
  })

  const productList = {
    title: headingText,
    position: 'na',
    totalNumberOfLists: 'na',
  }

  return (
    <Box ref={componentInViewRef}>
      <AnimatedBox p={5} display={{ md: 'none' }} m="auto">
        <Box borderRadius={2} bgcolor="colorBackground03" p={6}>
          <Flex>
            <Image src={mascot} height="110px" width="102px" loading="eager" />
            <Text typography="typeDisplay02" mb={6}>
              <Markup htmlString={headingText} />{' '}
              <Markup htmlString={daysUntilText} />
            </Text>
          </Flex>
          <Text as="p" width="100%" textAlign="center" mb={4}>
            {localiseText('find.shop_for_reminder.sub_text')}
          </Text>
          <Flex
            mb={6}
            justifyContent="space-between"
            maxWidth="500px"
            mx="auto"
          >
            {reminder.recommendations[0].products
              .slice(0, 3)
              .map((product, index) => (
                <Box width={{ xs: '30%', sm: '30%' }} key={product.id}>
                  <ProductTileWithBasketHandler
                    product={product}
                    productsLength={reminder.recommendations[0].products.length}
                    index={index}
                    pageName={'home'}
                    productList={productList}
                    showProductTabs
                    hideStickyCta
                  />
                </Box>
              ))}
          </Flex>

          <Link href={`/${store.id}/account/reminders/${reminder.id}`}>
            <PrimaryButton width="100%" onClick={trackSelectContent}>
              {localiseText('find.shop_for_reminder.cta', {
                name: reminder.name,
              })}
            </PrimaryButton>
          </Link>
        </Box>
      </AnimatedBox>

      <AnimatedBox
        display={{ xs: 'none', md: 'block' }}
        my={8}
        minHeight="188px"
      >
        <Container limitWidth>
          <Flex>
            <Box
              width={{ md: 4 / 12, lg: 5 / 12 }}
              display="flex"
              justifyContent="start"
              alignItems="center"
              mr={8}
            >
              <DesktopMascotContainer src={mascot} loading="eager" />

              <DesktopMainText mb={6}>
                <Markup htmlString={headingText} />{' '}
                <Markup htmlString={daysUntilText} />
              </DesktopMainText>
            </Box>

            <Box width={{ md: 8 / 12, lg: 7 / 12 }} position="relative">
              <DesktopBackgroundImageTopLeft />
              <DesktopBackgroundImageBottomRight />

              <Box
                p={6}
                borderRadius={2}
                bgcolor="colorBackground03"
                width="100%"
                display="flex"
                minHeight="188px"
                position="relative"
              >
                <Flex
                  mb={4}
                  justifyContent="space-between"
                  alignItems="center"
                  width={7 / 12}
                  height={1}
                  mr={6}
                  my="auto"
                >
                  {reminder.recommendations[0].products
                    .slice(0, 3)
                    .map((product, index) => (
                      <Box width="30%" key={product.id}>
                        <ProductTileWithBasketHandler
                          product={product}
                          productsLength={
                            reminder.recommendations[0].products.length
                          }
                          index={index}
                          pageName={'home'}
                          productList={productList}
                          showProductTabs
                          hideStickyCta
                        />
                      </Box>
                    ))}
                </Flex>
                <Box
                  width={5 / 12}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  position="relative"
                >
                  <Text
                    typography={{
                      xs: 'typeMobileDisplay05',
                      md: 'typeDesktopDisplay06',
                    }}
                    fontWeight={400}
                    mb={5}
                  >
                    <Markup
                      htmlString={localiseText(
                        'find.shop_for_reminder.desktop_sub_text',
                        {
                          name: reminder.name,
                          emphasisedTextColour:
                            theme.colors.colorInteractionButton,
                        },
                      )}
                    />
                  </Text>

                  <Link href={`/${store.id}/account/reminders/${reminder.id}`}>
                    <PrimaryButton width="100%" onClick={trackSelectContent}>
                      {localiseText('find.shop_for_reminder.cta', {
                        name: reminder.name,
                      })}
                    </PrimaryButton>
                  </Link>
                  <BalloonContainer src={balloons} />
                </Box>
              </Box>
            </Box>
          </Flex>
        </Container>
      </AnimatedBox>
    </Box>
  )
}

export const ShopForReminderContent: FC<
  ShopForReminderContentProps
> = props => {
  const componentState = useShopForReminderState(props)

  const { trackGAEventOnce } = useTrackGAEventOnce()

  useEffect(() => {
    trackGAEventOnce(upcomingOccasionsV2LoadEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  switch (componentState.state) {
    case State.LOADED_REMINDER:
      return <Loaded reminder={componentState.reminder} />
    default:
      return null
  }
}
