import { useTrustpilotScript } from '@moonpig/web-shared-products'
import { TRUSTPILOT_BUSINESS_UNIT_ID } from '@moonpig/web-core-brand/config'
import { useLocale } from '@moonpig/web-core-stores'
import React, { FC, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Box } from '@moonpig/launchpad-components'

const IMPRESSION_EVENT_DATA = {
  event: 'impression',
  content_data: {
    content_type: 'explore | trustpilot widget | company score',
    item_id: 'trustpilot widget',
  },
  event_data: {
    category: 'explore',
    action: 'view trustpilot widget',
    label: 'trustpilot widget | company score',
    non_interaction: true,
    value: undefined,
  },
}

const StyledTrustPilotWidget = styled(Box)`
  ${s({
    p: 6,
    bgcolor: 'colorBackground03',
  })}
  &:empty {
    padding: 0;
  }
`

export const CustomerSatisfaction: FC = () => {
  const locale = useLocale()
  useTrustpilotScript()

  const { trackGAEventOnce } = useTrackGAEventOnce()

  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      trackGAEventOnce(IMPRESSION_EVENT_DATA)
    }
  }, [inView, trackGAEventOnce])

  return (
    <StyledTrustPilotWidget
      data-testid="trustpilot-widget"
      className="trustpilot-widget"
      ref={ref}
      data-locale={locale.language}
      data-template-id="5406e65db0d04a09e042d5fc"
      data-businessunit-id={TRUSTPILOT_BUSINESS_UNIT_ID}
      data-style-height="28px"
      data-style-width="100%"
    />
  )
}
