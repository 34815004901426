import { useQuery } from '@moonpig/web-core-graphql'
import { useMemo } from 'react'
import { useStoreId } from '@moonpig/web-core-stores'
import {
  GetDraftDesignsQuery,
  GetDraftDesignsQueryVariables,
  GetDraftDesignProductLookupQuery,
  GetDraftDesignProductLookupQueryVariables,
} from '../../queries/__generated__/GetDraftDesigns'
import { GetDraftDesigns_designs_Designs_items } from '../../queries/types-graphql'
import {
  GetDraftDesignProductLookupGQL,
  GetDraftDesignsGQL,
} from '../../queries/getDraftDesigns'
import { ContinueJourneyProduct } from '../../types'

const getDesignImageUrl = (
  design: GetDraftDesigns_designs_Designs_items,
  productImageUrl: string,
): string => {
  if (design.images.__typename === 'RendererServiceError') {
    return productImageUrl
  }

  return design.images.images[0].variants.small.url
}

const getDesignEditUrl = (
  productKey: string,
  sku: string | null,
  sizeId: string,
  store: string,
): string => {
  return `/${store}/customise/product/${productKey}/${sizeId}/?productVariantSku=${sku}`
}

export const useDraftProducts = () => {
  const { data: draftData } = useQuery<
    GetDraftDesignsQuery,
    GetDraftDesignsQueryVariables
  >(GetDraftDesignsGQL, {
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        limit: 5,
      },
    },
  })

  const designsResult = draftData?.designs
  const designsResultSuccess = designsResult?.__typename === 'Designs'

  const productKeys = designsResultSuccess
    ? designsResult.items.map(design => design.productKey)
    : /* istanbul ignore next */ []

  const { data: productData } = useQuery<
    GetDraftDesignProductLookupQuery,
    GetDraftDesignProductLookupQueryVariables
  >(GetDraftDesignProductLookupGQL, {
    ssr: false,
    fetchPolicy: 'no-cache',
    skip: productKeys.length === 0,
    variables: {
      productIds: productKeys,
    },
  })

  const store = useStoreId()

  const draftProducts: ContinueJourneyProduct[] = useMemo(
    () =>
      designsResult?.__typename !== 'Designs'
        ? /* istanbul ignore next */ []
        : designsResult.items.reduce<ContinueJourneyProduct[]>(
            (acc, design) => {
              const product = productData?.productLookup.products.find(
                p => p.id === design.productKey.toLowerCase(),
              )

              /* istanbul ignore else */
              if (product !== undefined && design) {
                acc.push({
                  id: product.id,
                  url: getDesignEditUrl(
                    design.productKey.toLowerCase(),
                    product.masterVariant.sku,
                    product.masterVariant.sizeId.toString(),
                    store,
                  ),
                  title: product.title,
                  image: getDesignImageUrl(
                    design,
                    product.masterVariant.images[0].url,
                  ),
                  masterVariant: product.masterVariant,
                  category: product.category,
                  isDraftProduct: true,
                  isSponsored: false,
                })
              }
              return acc
            },
            [],
          ),
    [designsResult, productData, store],
  )

  return draftProducts
}
