import React, { FC, forwardRef, PropsWithChildren } from 'react'
import { Box, Container, Flex, Heading } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { breakpointDown, styled } from '@moonpig/launchpad-utils'
import { ModuleErrorBoundary } from '@moonpig/web-shared-products'

type ModuleContainerProps = {
  'data-testid'?: string
  key?: number | string
  backgroundColor?: string
  as?: keyof JSX.IntrinsicElements
  withContainer?: boolean
  fullWidthOnMobile?: boolean
  padding?: Record<string, unknown>
  margin?: Record<string, unknown>
  onClick?: () => void
}

type ModuleHeaderProps = {
  title?: string
  subtitle?: string
}

const StyledSection = styled.section<{
  backgroundColor?: string
  padding?: object
  margin?: object
}>`
  ${({ backgroundColor, padding, margin }) =>
    s({
      bgcolor: backgroundColor || 'inherit',
      ...padding,
      ...margin,
    })}

  ${breakpointDown('md')} {
    width: 100%;
    text-align: left;
    h2,
    p {
      max-width: 700px;
    }
  }
`

const StyledBox = styled(Box)`
  ${breakpointDown('md')} {
    width: 100%;
    text-align: left;
    h2,
    p {
      max-width: 700px;
    }
  }
`

export const ModuleHeader: FC<ModuleHeaderProps> = ({ title, subtitle }) => {
  return (
    <Container limitWidth>
      <Flex justifyContent={{ md: 'center' }}>
        <StyledBox
          width={{ md: 3 / 4, lg: 2 / 3, xl: 1 / 2 }}
          pb={4}
          textAlign="center"
        >
          {title && (
            <Heading
              level="h2"
              typography={{
                xs: 'typeMobileDisplay03',
                md: 'typeDesktopDisplay04',
              }}
            >
              {title}
            </Heading>
          )}
          {subtitle && <p>{subtitle}</p>}
        </StyledBox>
      </Flex>
    </Container>
  )
}

export const ModuleSection = forwardRef<
  HTMLElement,
  PropsWithChildren<ModuleContainerProps>
>(
  (
    {
      backgroundColor,
      as,
      withContainer,
      fullWidthOnMobile,
      padding,
      margin,
      children,
      onClick,
      ...rest
    },
    ref,
  ) => (
    <ModuleErrorBoundary>
      <StyledSection
        ref={ref}
        backgroundColor={backgroundColor}
        data-testid={rest['data-testid']}
        padding={padding}
        margin={margin}
        onClick={onClick}
        as={as}
      >
        {withContainer ? (
          <Container
            limitWidth
            padding={fullWidthOnMobile ? { xs: 0, md: 8 } : { xs: 6, md: 8 }}
          >
            {children}
          </Container>
        ) : (
          children
        )}
      </StyledSection>
    </ModuleErrorBoundary>
  ),
)
