import { trackGAEvent } from '@moonpig/web-core-analytics'

export const trackAddMembershipToCart = () => {
  trackGAEvent({
    event: 'add_to_cart',
    content_data: undefined,
    ecommerce: {
      currency: 'gbp',
      items: [
        {
          index: 1,
          item_brand: 'moonpig',
          item_id: 'moonpig-plus',
          item_name: 'moonpig plus',
          price: '9.99',
          quantity: 1,
        },
      ],
      value: '9.99',
    },
    error_data: undefined,
    event_data: {
      action: 'add to basket',
      category: 'product action',
      label: 'membership | moonpig plus',
      non_interaction: true,
      value: 10,
    },
  })
}

export const trackStartShopping = () => {
  trackGAEvent({
    event: 'select_item',
    content_data: {
      content_type: 'membership | moonpig plus | start shopping',
    },
    ecommerce: undefined,
    error_data: undefined,
    event_data: {
      action: 'start shopping',
      category: 'customer action',
      label: 'membership | moonpig plus',
      non_interaction: true,
      value: undefined,
    },
  })
}
