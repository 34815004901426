import { createContext, useContext } from 'react'
import { Dispatcher, State } from './types'

export const InitialState: State = {
  variant: 'info',
  open: false,
  message: '',
}

export const stateContext = createContext<State>(InitialState)

export const dispatcherContext = createContext<Dispatcher | undefined>(
  undefined,
)

export const useAlertDispatcher = () => {
  const context = useContext(dispatcherContext)
  /* istanbul ignore next */
  if (!context) {
    throw new Error('AlertProvider is missing.')
  }
  return context
}
