import { LocaleTextHook } from '@moonpig/web-core-locale-text'
import { Occasion } from '@moonpig/web-explore-types-graphql'
import {
  GetUpcomingOccasions_reminders as Reminders,
  GetUpcomingOccasions_me_customer_recommendations_source as RecommendationsSource,
} from '../../queries/types-graphql'
import { RECOMMENDATIONS_FOR_CUSTOMER_TEXT } from './recommendationsForCustomer.locale'

enum RecommendationType {
  REMINDER = 'Reminder',
  INFERRED = 'Inferred',
  FALLBACK = 'Fallback - User Affinities',
}

type CreateHeadingTextProps = {
  recommendationType: string
  recommendationSource: RecommendationsSource
  recommendationReminder: Reminders | undefined
  localiseText: LocaleTextHook<typeof RECOMMENDATIONS_FOR_CUSTOMER_TEXT>
}

const capitaliseSentence = (sentence: string) => {
  const words = sentence.split(' ')

  return words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')
}

const nameAndOccasion = (occasion: string, name: string | null | undefined) =>
  `${name ? `${name}’s ` : ''}${occasion}`

const printOccasion = (
  occasion: Occasion,
  localiseText: LocaleTextHook<typeof RECOMMENDATIONS_FOR_CUSTOMER_TEXT>,
  name?: string | null,
) => {
  const localisedOccasion = localiseText('occasion', occasion)

  return localisedOccasion !== ''
    ? nameAndOccasion(localisedOccasion, name)
    : nameAndOccasion(localiseText('find.special_occasion'), name)
}

export const createHeadingText = ({
  recommendationType,
  recommendationSource,
  recommendationReminder,
  localiseText,
}: CreateHeadingTextProps) => {
  if (
    recommendationType === RecommendationType.REMINDER &&
    recommendationReminder
  ) {
    const occasion = printOccasion(
      recommendationReminder.occasion,
      localiseText,
      recommendationReminder.name,
    )
    return {
      title: `${localiseText('find.cards_for_occasion', { occasion })} `,
      subtitle: recommendationSource.orderId
        ? `${localiseText(
            'find.based_on_what_you_ordered_this_time_last_year',
          )}.`
        : localiseText('find.based_on_what_other_customers_loved'),
    }
  }

  if (recommendationType === RecommendationType.INFERRED) {
    return {
      title: capitaliseSentence(
        localiseText('find.based_on_what_you_ordered_this_time_last_year'),
      ),
      subtitle: '',
    }
  }

  if (recommendationType === RecommendationType.FALLBACK) {
    return {
      title: localiseText('find.recommended_for_you'),
      subtitle: localiseText('find.based_on_what_other_customers_loved'),
    }
  }
  return {
    title: localiseText('find.recommended_for_you'),
    subtitle: '',
  }
}
