import React, { FC } from 'react'
import { SecondaryButton } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

const StyledSecondaryButton = styled(SecondaryButton)`
  ${s({
    minHeight: 10,
    py: 4,
    px: 6,
    my: 4,
    bgcolor: 'colorBackground01',
    color: 'colorInteractionIcon',
    border: 2,
    borderColor: 'colorInteractionIcon',
    height: '64px',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
  })}

  &:not(:disabled) {
    &:hover,
    &:focus {
      ${s({
        bgcolor: 'colorBackground01',
        color: 'colorInteractionIcon',
        borderColor: 'colorInteractionIcon',
        boxShadow: 2,
      })}
    }

    &:active {
      ${s({
        boxShadow: 1,
      })}
    }
  }
`

const getLines = (str: string) => {
  const lettersPerLine = str.length <= 16 ? 10 : 16
  let firstLineDone = false
  const firstLine = str
    .split(' ')
    .reduce((acc, word) => {
      const newLine = `${acc} ${word}`
      const currentLength = newLine.length
      if (currentLength <= lettersPerLine && !firstLineDone) {
        return newLine
      }

      firstLineDone = true
      return acc
    }, '')
    .trim()
  const secondLine = str.replace(firstLine, '').trim()
  return [firstLine, secondLine]
}

export const formatTitle = (title: string) => {
  const [firstLine, secondLine] = getLines(title)
  return (
    <div>
      <div>{firstLine}</div>
      <div>{secondLine}</div>
    </div>
  )
}

type ButtonProps = {
  title: string
  linkHref: string
}

export const Button: FC<ButtonProps> = ({ title, linkHref }) => (
  <StyledSecondaryButton href={linkHref}>
    {formatTitle(title)}
  </StyledSecondaryButton>
)
