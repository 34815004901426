import React from 'react'
import { ContentModule, PageContent } from '../../types'
import { BlogHome } from '../../components/BlogHome'

export type BlogHomepageProps = {
  modules: ContentModule[]
}

export const BlogHomepage: PageContent<BlogHomepageProps> = ({ modules }) => (
  <BlogHome modules={modules} />
)

BlogHomepage.getInitialProps = async ({ modules }) => ({
  props: { modules },
  pageOptions: {},
})
