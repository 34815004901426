import { createViewItemListGAEventBundleMiniCards } from '@moonpig/web-shared-products'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { BundleProduct } from '../types'
import { createGAItem } from '../createGAItem'

const getBundlesTotalAmount = (gifts: BundleProduct[]) => {
  const reducer = (totalPrice: number, currentGift: BundleProduct) => {
    const giftAmount = currentGift.centAmount / 100

    return totalPrice + giftAmount
  }

  return gifts.reduce(reducer, 0)
}

type CreateEventArgs = {
  pageLocation: string
  carouselHeading: string
  carouselPosition: number | string
  totalNumberOfCarousels: number | string
  brand: 'greetz' | 'moonpig'
  gifts: BundleProduct[]
}
export const createBundlesViewItemListGA4Event = ({
  pageLocation,
  carouselHeading,
  carouselPosition,
  totalNumberOfCarousels,
  gifts,
  brand,
}: CreateEventArgs) => {
  const label =
    `${pageLocation} | ${carouselHeading} | carousel | ${carouselPosition}/${totalNumberOfCarousels}`.toLowerCase()
  return createViewItemListGAEventBundleMiniCards({
    label,
    totalValue: getBundlesTotalAmount(gifts),
    currency: gifts[0].currencyCode,
    products: gifts.map((gift: BundleProduct, index) => {
      const giftGAItem = createGAItem({
        product: gift,
        index: index + 1,
        itemListId: `bundle | ${gift.id.toLowerCase()}`,
        itemListName: label,
        itemBrand: brand,
      })
      return {
        ...giftGAItem,
        item_category2: getParentDepartment(gift.department).toLowerCase(),
        item_category3: 'in-stock',
        item_category4: 'non-group-card',
        price: Number(giftGAItem.price),
      }
    }),
  })
}
