import { PromoTileData, PromoTileCta } from '@moonpig/web-shared-products'
import { useStoreId } from '@moonpig/web-core-stores'
import { useRouter } from '@moonpig/web-core-routing'
import { ModulePromoTile } from '../../types'
import {
  useAddMembershipToBasketService,
  addMembershipToBasketActionToCta,
} from '../../modules/CtaAction'
import { useFindLocaleText } from '../../text-localisation'
import { useAlertDispatcher } from '../../contexts/alert/Context'

export const usePromoTile = (
  modulePromoTile?: ModulePromoTile | undefined,
): PromoTileData | undefined => {
  const addMembershipToBasketAction = useAddMembershipToBasketService()
  const store = useStoreId()
  const locale = useFindLocaleText()
  const alertDispatch = useAlertDispatcher()
  const router = useRouter()
  let formattedCta: PromoTileCta | undefined

  if (!modulePromoTile) {
    return undefined
  }

  switch (modulePromoTile.cta.action.__typename) {
    case 'LinkAction':
      formattedCta = {
        url: modulePromoTile.cta.action.url,
        title: modulePromoTile.cta.title,
        onClick: undefined,
      }
      break
    case 'AddMembershipToBasketAction':
      formattedCta = addMembershipToBasketActionToCta(
        {
          title: modulePromoTile.cta.title,
          action: modulePromoTile.cta.action,
        },
        addMembershipToBasketAction,
        store,
        locale,
        alertDispatch,
        router,
      )
      break
  }
  return {
    title: modulePromoTile.title,
    description: modulePromoTile.description,
    sponsored: true,
    image: {
      src: modulePromoTile.image.url,
      description: modulePromoTile.image.description,
    },
    cta: formattedCta,
  }
}
