import React, { FC, PropsWithChildren } from 'react'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { Image, Box } from '@moonpig/launchpad-components'
import { ModuleContainer } from '../../../components/ModuleContainer'
import { ILLUSTRATION_ECARD_URL, WRAPPER_MIN_HEIGHT } from './constants'

export const HeadingImage = styled(Image)<{ height: string }>`
  display: block;
  height: ${({ height }) => height};

  ${breakpointUp('md')} {
    display: none;
  }
`

const DesktopImage = styled(Image)`
  display: none;
  max-width: 100%;
  max-height: 270px;
  margin: auto;

  ${breakpointUp('md')} {
    display: block;
  }
`

const StyledWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: ${WRAPPER_MIN_HEIGHT}px;

  ${breakpointUp('md')} {
    flex-wrap: nowrap;
  }
`

export const EmailCaptureContainer: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <ModuleContainer>
      <StyledWrapper>
        <Box
          flexBasis={{ sm: '100%', md: '80%', lg: '60%' }}
          flexShrink={1}
          flexGrow={0}
        >
          {children}
        </Box>
        <Box flexBasis={{ md: '20%', lg: '40%' }}>
          <DesktopImage
            src={ILLUSTRATION_ECARD_URL}
            alt="Illustration Ecard"
            aria-hidden
            lazyLoad={false}
          />
        </Box>
      </StyledWrapper>
    </ModuleContainer>
  )
}
