import React, { FC, PropsWithChildren } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

const StyledPromoLabel = styled.div`
  ${s({ typography: 'typeBodyLabel', py: 3 })}
  text-align: right;
  line-height: 25px;
`

const StyledInner = styled.div`
  transform: translateX(-${({ theme }) => theme.spacing[4]}px);
`

const StyledBackground = styled.span`
  ${s({
    px: 4,
    py: 1,
    bgcolor: 'colorBackground05',
    boxShadow: 1,
  })}
  box-decoration-break: clone;
`

export const PromoLabel: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <StyledPromoLabel>
    <StyledInner>
      <StyledBackground>{children}</StyledBackground>
    </StyledInner>
  </StyledPromoLabel>
)
