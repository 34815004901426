import { Box } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { useTheme } from '@moonpig/launchpad-utils'
import { ThemeInterface } from '@moonpig/launchpad-theme'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { isModalLink } from '@moonpig/web-core-link'
import { useStoreId } from '@moonpig/web-core-stores'
import { ModulePlaceholderVariant } from '@moonpig/web-explore-types-graphql'
import { ModuleErrorBoundary } from '@moonpig/web-shared-products'
import { HomepageBannerVariantModule } from '../../modules/HomepageBannerVariant'
import { ProductListModule } from '../../modules/ProductList'
import { HomepageCTABannerVariantModule } from '../../modules/HomepageCTABannerVariant'
import { RemindersCTAStripModule } from '../../modules/RemindersCTAStrip'
import { ContentModule } from '../../types'
import { RecommendationsForCustomerModule } from '../../modules/RecommendationsForCustomer'
import { PlaceholderModule } from '../../modules/Placeholder'
import { createContentPipeline } from './pipeline'
import {
  MODULE_CARD,
  MODULE_CTA_STRIP,
  MODULE_DELIVERY_STRIP,
  MODULE_DYNAMIC_HERO,
  MODULE_EMAIL_CAPTURE,
  MODULE_HEADING,
  MODULE_HERO,
  MODULE_INFO,
  MODULE_MISSION_BUTTONS,
  MODULE_PLACARDS,
  MODULE_PLACEHOLDER,
  MODULE_RECOMMENDATIONS_FOR_CUSTOMER,
  MODULE_SEARCH_RESULTS,
  MODULE_SEO,
  MODULE_TABBED_PRODUCT_LISTS,
  MODULE_TILES,
  MODULE_USP_CAROUSEL,
} from './constants'
import { getPipelineSteps } from './getPipelineSteps'
import { ModuleSection } from '../ModuleContainer'
import { mapModule } from '../../modules/map-modules'
import { ModuleTracking } from '../../modules/types'
import { DynamicHeroResponse } from '../../services/dataInference'
import { createTracking } from '../../analytics/tracking'

type TrackingDataProps = {
  productCarouselIndexOnPage: number
  numberOfProductCarouselsOnPage: number
}

const LIST_OF_MODULES_WITH_PRODUCT_LIST = [
  MODULE_SEARCH_RESULTS,
  MODULE_TABBED_PRODUCT_LISTS,
]

const LIST_OF_PLACEHOLDER_MODULES_WITH_PRODUCT_CAROUSEL = [
  ModulePlaceholderVariant.Bundles,
]

const hasProductList = (module: ContentModule) => {
  return (
    LIST_OF_MODULES_WITH_PRODUCT_LIST.includes(module.type) ||
    (module.type === MODULE_PLACEHOLDER &&
      LIST_OF_PLACEHOLDER_MODULES_WITH_PRODUCT_CAROUSEL.includes(
        module.placeholderVariant,
      ))
  )
}

const setProductListsTrackingData = (modules: ContentModule[]) => {
  let productListIndexOnPage = 0
  const numberOfProductListsOnPage = modules.filter(hasProductList).length

  return modules.map(module => {
    if (hasProductList(module)) {
      productListIndexOnPage += 1

      return {
        ...module,
        tracking: {
          pageLocation: 'home',
          moduleProductListIndex: productListIndexOnPage,
          moduleProductListsLength: numberOfProductListsOnPage,
        },
      }
    }

    return module
  })
}

const renderModule = ({
  module,
  key,
  darkBackground,
  trackingDataProps,
}: {
  module: ContentModule
  key: number
  darkBackground: boolean
  trackingDataProps: TrackingDataProps
  theme: ThemeInterface
}) => {
  const trackingData = { moduleIndex: key, moduleType: module.type }
  const backgroundColor = darkBackground
    ? 'colorBackground06'
    : 'colorBackground01'
  const page = 'home'

  const defaultPadding = { py: { xs: 6, lg: 8 } }

  const context = {
    key,
    index: key,
    backgroundColor,
    padding: defaultPadding,
    layout: page,
    tracking: createTracking({ page }),
  }

  switch (module.type) {
    case MODULE_CTA_STRIP:
      return (
        <ModuleSection key={key} data-testid="module-cta-strip">
          {isModalLink(module.cta.url) ? (
            <RemindersCTAStripModule trackingData={trackingData} {...module} />
          ) : (
            <HomepageCTABannerVariantModule
              module={module}
              trackingData={trackingData}
            />
          )}
        </ModuleSection>
      )

    case MODULE_RECOMMENDATIONS_FOR_CUSTOMER:
      return (
        <RecommendationsForCustomerModule
          key={key}
          module={module}
          context={context}
        />
      )
    case MODULE_PLACEHOLDER:
      return (
        <ModuleErrorBoundary key={key} type={module.placeholderVariant}>
          <PlaceholderModule
            {...module}
            backgroundColor={backgroundColor}
            trackingDataProps={{
              pageLocation: page,
              moduleIndex: trackingDataProps.productCarouselIndexOnPage,
              modulesLength: trackingDataProps.numberOfProductCarouselsOnPage,
            }}
          />
        </ModuleErrorBoundary>
      )
    case MODULE_DELIVERY_STRIP:
      return (
        <ModuleSection key={key} data-testid="module-delivery-strip">
          <HomepageBannerVariantModule {...module} />
        </ModuleSection>
      )
    case MODULE_SEARCH_RESULTS:
      return (
        <ProductListModule
          key={key}
          {...module}
          alternativeButton
          trackingData={{
            pageLocation: 'home',
            moduleIndex: trackingDataProps.productCarouselIndexOnPage,
            modulesLength: trackingDataProps.numberOfProductCarouselsOnPage,
            moduleTitle: module.title,
          }}
          backgroundColor={backgroundColor}
          excludeRudeCards
        />
      )
    case MODULE_TABBED_PRODUCT_LISTS:
      return mapModule(
        module as Extract<'ModuleTabbedProductLists', ContentModule> &
          ModuleTracking,
        context,
      )
    case MODULE_USP_CAROUSEL:
    case MODULE_HEADING:
    case MODULE_MISSION_BUTTONS:
    case MODULE_TILES:
    case MODULE_INFO:
    case MODULE_SEO:
    case MODULE_EMAIL_CAPTURE:
    case MODULE_HERO:
    case MODULE_DYNAMIC_HERO:
    case MODULE_PLACARDS:
    case MODULE_CARD:
      return mapModule(module, context)

    default:
      return null
  }
}

const FIXED_BACKGROUND_MODULES = [
  MODULE_CTA_STRIP,
  MODULE_DELIVERY_STRIP,
  MODULE_HERO,
  MODULE_MISSION_BUTTONS,
  MODULE_USP_CAROUSEL,
]

const renderModules = ({
  modules,
  alternatingBackground,
  theme,
}: {
  modules: ContentModule[]
  alternatingBackground: boolean
  theme: ThemeInterface
}) => {
  let trackingDataProps = {
    numberOfProductCarouselsOnPage: 0,
    productCarouselIndexOnPage: 0,
  }
  let nextIndex = 0
  let headingDarkBackground: boolean | null = null

  const modulesWithProductListsTracking = setProductListsTrackingData(modules)

  return modulesWithProductListsTracking.map((module, index) => {
    let darkBackground = false

    if (!FIXED_BACKGROUND_MODULES.includes(module.type)) {
      // eslint-disable-next-line no-plusplus
      darkBackground = nextIndex++ % 2 === 0
    }

    if (module.type === MODULE_HEADING) {
      headingDarkBackground = darkBackground
    }

    if (module.type === MODULE_SEO && headingDarkBackground !== null) {
      darkBackground = headingDarkBackground
    }

    if (hasProductList(module)) {
      const { tracking } = module as ModuleTracking

      trackingDataProps = {
        productCarouselIndexOnPage: tracking.moduleProductListIndex,
        numberOfProductCarouselsOnPage: tracking.moduleProductListsLength,
      }
    }

    return (
      // eslint-disable-next-line react/no-array-index-key
      <ModuleErrorBoundary key={index} type={module.type}>
        {renderModule({
          module,
          key: index,
          darkBackground: alternatingBackground && darkBackground,
          theme,
          trackingDataProps,
        })}
      </ModuleErrorBoundary>
    )
  })
}

type Props = {
  modules: ContentModule[]
  dynamicHeroData: DynamicHeroResponse
}

const Home: FC<Props> = ({ modules, dynamicHeroData }) => {
  const { loggedIn } = useLoggedIn()
  const storeId = useStoreId()
  const theme = useTheme()

  const process = createContentPipeline(
    { store: storeId, dynamicHeroData, loggedIn },
    getPipelineSteps({
      loggedIn,
      region: storeId,
    }),
  )

  return (
    <Box bgcolor="white">
      {renderModules({
        modules: process(modules).filter(Boolean),
        alternatingBackground: true,
        theme,
      })}
    </Box>
  )
}

export { Home }
