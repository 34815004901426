import { IllustrationTruckEnvelope } from '@moonpig/launchpad-assets'
import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { Flex } from '@moonpig/launchpad-components'
import { breakpointDown, styled } from '@moonpig/launchpad-utils'
import { Link } from '@moonpig/web-core-link'
import { HomepageBannerVariant } from '../../components/HomepageBannerVariant/HomepageBannerVariant'
import { ModuleDeliveryStrip } from '../../types'

const StyledText = styled.div`
  ${s({
    fontSize: { xs: '16px', md: '18px' },
  })}
  font-weight: bold;
`

const StyledLink = styled(Link)`
  ${s({
    ml: 5,
    typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
    color: 'colorInteractionTextLink',
  })}

  &:hover {
    text-decoration: none;
  }
`

const StyledCTATitle = styled.div`
  &:hover {
    text-decoration: underline;
  }

  white-space: nowrap;

  ${breakpointDown('md')} {
    display: none;
  }
`

const StyledChevron = styled.span`
  ${s({
    pl: 3,
    mr: 6,
  })}

  text-decoration: none;

  &:after {
    content: '\\003e';
  }

  ${breakpointDown('md')} {
    ${s({ typography: 'typeDisplay02' })}
  }
`

const HomepageBannerVariantModule: FC<ModuleDeliveryStrip> = ({
  text,
  deliveryStripCta,
}) => {
  return (
    <HomepageBannerVariant icon={<IllustrationTruckEnvelope />}>
      <Flex alignItems="center">
        <StyledText>{text}</StyledText>
        {deliveryStripCta && (
          <StyledLink
            href={deliveryStripCta.url}
            title={deliveryStripCta.title}
          >
            <Flex>
              <StyledCTATitle>{deliveryStripCta.title}</StyledCTATitle>
              <StyledChevron aria-hidden />
            </Flex>
          </StyledLink>
        )}
      </Flex>
    </HomepageBannerVariant>
  )
}

export { HomepageBannerVariantModule }
