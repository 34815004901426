import { GraphQLError, DocumentNode } from 'graphql'
import {
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
} from '@apollo/client'
import { useMutation } from '@moonpig/web-core-graphql'
import { useLoginModal } from '@moonpig/web-core-auth'
import { usePageRequiresLogin } from '@moonpig/web-core-context'

/* istanbul ignore next */
const containsInsufficientGrantTypeError = (
  errors: ReadonlyArray<GraphQLError>,
): boolean => {
  return errors.some(
    error =>
      error.extensions?.code === 'INSUFFICIENT_TOKEN_GRANT_TYPE' ||
      error.extensions?.code === 'INSUFFICIENT_SCOPE',
  )
}

/* istanbul ignore next */
export const useLoggedInGraphQLMutation = <
  TData = unknown,
  TVariables = OperationVariables,
>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> => {
  const requiresLogin = usePageRequiresLogin()
  const loginModal = useLoginModal()

  const result = useMutation<TData, TVariables>(mutation, options)

  const data = result[1]
  if (
    requiresLogin &&
    containsInsufficientGrantTypeError(data.error?.graphQLErrors || [])
  ) {
    loginModal.setShow(true)
  }

  return result
}
