import { Breadcrumbs } from '@moonpig/web-core-nav'
import React, { FC } from 'react'
import { ModuleSection } from '../../components/ModuleContainer'
import { ModuleBreadcrumbsFragment } from './__generated__/fragment'
import { Module } from '../types'

export const BreadcrumbsModule: FC<Module<ModuleBreadcrumbsFragment>> = ({
  module: { breadcrumbs },
}) => {
  return breadcrumbs.length > 1 ? (
    <ModuleSection
      data-testid="module-breadcrumbs"
      withContainer
      padding={{ py: 3 }}
    >
      <Breadcrumbs items={breadcrumbs} />
    </ModuleSection>
  ) : null
}
