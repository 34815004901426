import {
  AddedToBasketEvent,
  ProductInfoProduct,
  useBasket,
  useHandleAddToBasket,
} from '@moonpig/web-shared-products'
import { useEffect } from 'react'
import { setUIHCookie } from '@moonpig/web-shared-utils'
import { useFindLocaleText } from '../text-localisation'

type UseBasketWrapper = () => (
  e: AddedToBasketEvent,
  keywords: string,
  filters: { key: string | null; group: string | null }[],
  onTackingInfo?: (args: { product: ProductInfoProduct }) => {
    label: string
    listName: string
    itemIndex: number
  },
) => Promise<void>

export const useBasketWrapper: UseBasketWrapper = () => {
  const localise = useFindLocaleText()
  const [_, { setLoading, setError }] = useBasket()

  const { handleAddToBasket, addingToBasket: loading } = useHandleAddToBasket({
    pageLocation: '',
    soldOutMessage: localise('find.sold_out'),
    itsNotYouMessage: localise('find.its_not_you'),
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  return async (e, keywords, filters, onTackingInfo) => {
    setUIHCookie({ product: e.product })
    try {
      await handleAddToBasket({
        quantity: e.quantity,
        product: e.product,
        variant: e.variant,
        filters: filters.map(f => ({
          facetKey: f.key || /* istanbul ignore next */ '',
          group: f.group || /* istanbul ignore next */ '',
        })),
        searchTerm: keywords,
        itemIndex: 0,
        productsLength: 0,
        buttonActionName: e.buttonActionName,
        selectedAddon: e.selectedAddon,
        requiresEditingValue: e.requiresEditingValue,
        componentName: 'carousel',
        trackPersonaliseEvent: e.trackPersonaliseEvent,
        onTackingInfo,
      })
    } catch (error) {
      setError(true)
    }
  }
}
