import React from 'react'
import {
  Box,
  Flex,
  Text,
  TertiaryButton,
  Link,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { spacingPx } from '@moonpig/launchpad-theme'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { OccasionIcon } from '@moonpig/web-shared-reminders'
import { useLanguage, useStoreId } from '@moonpig/web-core-stores'
import { LocaleTextHook } from '@moonpig/web-core-locale-text'
import { Occasion, Relationship } from '@moonpig/web-explore-types-graphql'
import { daysBetween } from '@moonpig/web-shared-utils'
import { useRouter } from '@moonpig/web-core-routing'
import { PEAK_EVENTS } from '../../modules/RecommendationsForCustomer/constants'
import { useFindLocaleText } from '../../text-localisation'
import { RECOMMENDATIONS_FOR_CUSTOMER_TEXT } from '../../modules/RecommendationsForCustomer/recommendationsForCustomer.locale'
import { LocationLocalisationKey } from '../types'

type UpcomingOccasionCardProps = {
  reminder: UpcomingOccasionReminder
  onShopClicked?: () => void
} & React.ComponentProps<typeof Box>

export type UpcomingOccasionReminder = {
  id: string
  name: string | null
  occasion: Occasion
  relationship: Relationship | null
  nextDate: Date
}

type OrdinalSuffixKey = 'find.st' | 'find.nd' | 'find.rd' | 'find.th'
type OccasionKey =
  | 'find.christmas'
  | 'find.fathers_day'
  | 'find.mothers_day'
  | 'find.valentines_day'
  | 'find.note'
  | 'find.birthday'
  | 'find.anniversary'
  | 'find.new_baby'
  | 'find.wedding'
  | 'find.special_occasion'

const ContainerBox = styled(Box)`
  ${s({
    borderRadius: 1,
    color: 'colorTextHeadline',
    bgcolor: 'colorBackground01',
  })}
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-shadow: 0 1px 4px rgba(0, 32, 77, 0.15);
  align-self: stretch;
  height: 100%;
  transition: all 200ms ease-in-out;

  ${breakpoint('md')} {
    &:hover {
      cursor: pointer;
      ${s({ boxShadow: 4 })}
      transform: translateY(-${spacingPx(3)});
    }
  }
`

const StyledBox = styled(Box)`
  text-overflow: ellipsis;
  overflow: hidden;
  /* stylelint-disable value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
`

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

const printDaysUntilMessage = (
  daysUntilEvent: number,
  localiseText: LocaleTextHook<typeof RECOMMENDATIONS_FOR_CUSTOMER_TEXT>,
) => {
  if (daysUntilEvent === 1) {
    return localiseText('find.tomorrow')
  }
  if (daysUntilEvent === 0) {
    return localiseText('find.today')
  }

  return localiseText('find.in_n_days', { days: daysUntilEvent })
}

const getOrdinalSuffixKey = (day: number): OrdinalSuffixKey => {
  const suffix: Array<OrdinalSuffixKey> = [
    'find.th',
    'find.st',
    'find.nd',
    'find.rd',
  ]
  const v = day % 100
  return suffix[(v - 20) % 10] || suffix[v] || suffix[0]
}

const capitaliseFirstCharacter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

const peakLocalisationLookup: {
  [key: string]: LocationLocalisationKey
} = {
  [Occasion.VALENTINES_DAY]: 'find.personalised_cards_valentines_day_all',
  [Occasion.MOTHERS_DAY]: 'find.personalised_cards_mothers_day_all',
  [Occasion.FATHERS_DAY]: 'find.personalised_cards_fathers_day_all',
  [Occasion.CHRISTMAS]: 'find.personalised_cards_cristmas_all',
}

export const UpcomingOccasionCard = ({
  reminder,
  onShopClicked,
  ...otherProps
}: UpcomingOccasionCardProps) => {
  const { occasion, nextDate, name, id } = reminder
  const storeId = useStoreId()
  const language = useLanguage()
  const localise = useFindLocaleText()
  const router = useRouter()

  const todaysDate = new Date(Date.now())
  const daysUntilEvent = daysBetween(todaysDate, nextDate)
  const dayOfWeek = capitaliseFirstCharacter(
    nextDate.toLocaleString(language, { weekday: 'long' }),
  )
  const monthOfYear = capitaliseFirstCharacter(
    nextDate.toLocaleString(language, { month: 'long' }),
  )

  const localisedFor = localise('find.for')
  const recipientName = name || localise('find.someone_special')

  const occasionName = (occasionKey: OccasionKey) => {
    return `${localise(occasionKey)} ${localisedFor} ${recipientName}`
  }

  const nameOccasion = (occasionKey: OccasionKey) => {
    return `${recipientName}’s ${localise(occasionKey)}`
  }

  const onShop = () => {
    onShopClicked?.()
    if (PEAK_EVENTS.includes(id as Occasion)) {
      const parts = localise(peakLocalisationLookup[id])
        .split('/')
        .filter(Boolean)

      router.push({ name: 'content', params: { region: storeId, parts } })
    } else {
      router.push({ name: 'reminder-landing', params: { region: storeId, id } })
    }
  }

  const printOccasion = () => {
    const occasionMap = new Map([
      [Occasion.BIRTHDAY, nameOccasion('find.birthday')],
      [Occasion.SPECIAL_OCCASION, nameOccasion('find.special_occasion')],
      [Occasion.CHRISTMAS, occasionName('find.christmas')],
      [
        Occasion.FATHERS_DAY,
        name
          ? occasionName('find.fathers_day')
          : localise('find.fathers_day_for_dads_everywhere'),
      ],
      [Occasion.MOTHERS_DAY, occasionName('find.mothers_day')],
      [Occasion.VALENTINES_DAY, occasionName('find.valentines_day')],
      [Occasion.ANNIVERSARY, nameOccasion('find.anniversary')],
      [Occasion.NEW_BABY, nameOccasion('find.new_baby')],
      [Occasion.WEDDING, nameOccasion('find.wedding')],
      [Occasion.JUST_A_NOTE, occasionName('find.note')],
    ])

    return occasionMap.get(occasion) || nameOccasion('find.special_occasion')
  }

  return (
    <StyledLink onClick={onShop}>
      <ContainerBox {...otherProps} type="button">
        <Box py={6} pl={4} data-testid="occasion-icon">
          <OccasionIcon height="48px" width="48px" occasion={occasion} />
        </Box>
        <Box flexGrow={1} py={6} px={4}>
          <Box mb={3}>
            <Text typography="typeBodyCaption">
              {dayOfWeek}{' '}
              {nextDate.getDate() +
                localise(getOrdinalSuffixKey(nextDate.getDate()))}{' '}
              {monthOfYear}
            </Text>
          </Box>
          <StyledBox mb={3}>
            <Text
              as="p"
              typography={{
                xs: 'typeMobileDisplay05',
                md: 'typeDesktopDisplay06',
              }}
              mb={0}
            >
              {printOccasion()}
            </Text>
          </StyledBox>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Text>{printDaysUntilMessage(daysUntilEvent, localise)}</Text>
            </Box>
            <Box mt="auto">
              <TertiaryButton minHeight={0} p={0} mr={4}>
                {localise('find.shop')}
              </TertiaryButton>
            </Box>
          </Flex>
        </Box>
      </ContainerBox>
    </StyledLink>
  )
}
