import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Box, Heading, Flex, Text } from '@moonpig/launchpad-components'
import { IllustrationCard, IllustrationGift } from '@moonpig/launchpad-assets'
import { useFindLocaleText } from '../../../text-localisation'

const ICON_SIZE = {
  xs: '3rem',
  md: '3.5rem',
}

const StyledContentRow = styled(Flex)`
  align-items: center;
`

const StyledIllustrationCard = styled(IllustrationCard)`
  ${s({
    width: ICON_SIZE,
    height: ICON_SIZE,
  })}
`
const StyledIllustrationGift = styled(IllustrationGift)`
  ${s({
    width: ICON_SIZE,
    height: ICON_SIZE,
  })}
`

export const BundleContents: FC<{ giftTitle: string }> = ({ giftTitle }) => {
  const localiseText = useFindLocaleText()
  return (
    <Box
      data-testid="mp-find-bundle-content"
      bgcolor="colorBackground06"
      textAlign="left"
      p={6}
    >
      <Heading
        level="h3"
        typography={{ xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' }}
      >
        {localiseText('find.bundle.modal.content.title')}
      </Heading>
      <Box>
        <StyledContentRow>
          <Box>
            <StyledIllustrationCard />
          </Box>
          <Box ml={{ xs: 6, md: 4 }}>
            <Text as="p" typography="typeBodyLabel" m={0}>
              {localiseText('find.bundle.modal.content.card')}
            </Text>
            <Text as="p">
              {localiseText('find.bundle.modal.content.card_subtext')}
            </Text>
          </Box>
        </StyledContentRow>
        <StyledContentRow>
          <Box>
            <StyledIllustrationGift />
          </Box>
          <Box flexDirection="column" ml={{ xs: 6, md: 4 }}>
            <Text as="p" typography="typeBodyLabel" m={0}>
              {localiseText('find.bundle.modal.content.gift')}
            </Text>
            <Text as="p" m={0}>
              {giftTitle}
            </Text>
          </Box>
        </StyledContentRow>
      </Box>
    </Box>
  )
}
