import { useStoreId } from '@moonpig/web-core-stores'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { useRemindersModalContext } from '@moonpig/web-core-context'
import { isModalLink } from '@moonpig/web-core-link'
import { useRouter } from '@moonpig/web-core-routing'
import { useFindLocaleText } from '../../text-localisation'
import { useAddMembershipToBasketService } from './AddMembershipToBasketAction'
import { LinkActionFragment } from './__generated__/fragment'
import { useAlertDispatcher } from '../../contexts/alert/Context'
import { createLinkWithTracking } from '../../utils/appDeepLinking'
import { ModuleHero, CTA } from './types'
import { addMembershipToBasketActionToCta } from './addMembershipToBasketActionCta'

type TrackingData = { moduleIndex: number; moduleType: string }

type MapActionsToCtaArgs = {
  ctas: ModuleHero['ctas'] | null
  trackingData: TrackingData
  title: string
  experiment?: string
}

export type FormattedCTAs =
  | {
      primary?: CTA
      secondary?: CTA
    }
  | undefined

const linkActionToCta = (
  cta: {
    title: string
    action: LinkActionFragment
  },
  trackingData: TrackingData,
  title: string,
  index: number,
  remindersContext: ReturnType<typeof useRemindersModalContext>,
  experiment: string,
): CTA | undefined => {
  const trackedHref =
    cta.action.url &&
    createLinkWithTracking(
      { ...trackingData, experimentVariantName: experiment, brand: 'moonpig' },
      title,
      { title: cta.title, url: cta.action.url },
      index,
    )

  /* instanbul ignore next */
  const hasModalLink = isModalLink(trackedHref ?? /* istanbul ignore next */ '')

  return {
    url: !hasModalLink && trackedHref ? trackedHref : undefined,
    title: cta.title,
    onClick: hasModalLink
      ? () => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          trackGAEvent((trackedHref as any)?.trackEvent)
          remindersContext.setShow(true)
        }
      : undefined,
  }
}

export const useCtaActions = () => {
  const addMembershipToBasket = useAddMembershipToBasketService()
  const store = useStoreId()
  const locale = useFindLocaleText()
  const remindersContext = useRemindersModalContext()
  const alertDispatch = useAlertDispatcher()
  const router = useRouter()

  const mapActions = ({
    ctas,
    trackingData,
    title,
    experiment,
  }: MapActionsToCtaArgs) => {
    const formattedCtas: FormattedCTAs = ctas?.reduce(
      (ctaObj, cta, currentIndex) => {
        let formattedCta: CTA | undefined

        switch (cta.action?.__typename) {
          case 'LinkAction':
            formattedCta = linkActionToCta(
              { title: cta.title, action: cta.action },
              trackingData,
              title,
              currentIndex,
              remindersContext,
              experiment || /* istanbul ignore next */ 'Not bucketed',
            )
            break
          case 'AddMembershipToBasketAction': {
            formattedCta = addMembershipToBasketActionToCta(
              { title: cta.title, action: cta.action },
              addMembershipToBasket,
              store,
              locale,
              alertDispatch,
              router,
            )
            break
          }
        }

        if (formattedCta) {
          return {
            ...ctaObj,
            [currentIndex === 0 ? 'primary' : 'secondary']: formattedCta,
          }
        }
        return { ...ctaObj }
      },
      {},
    )

    return formattedCtas
  }

  return {
    mapActions,
  }
}
