import React, { FC } from 'react'
import { Grid, Box } from '@moonpig/launchpad-components'
import { ModuleHeader, ModuleSection } from '../../components/ModuleContainer'
import { InfoBlock } from '../../components/InfoBlock'
import { Module } from '../types'
import { ModuleInfoFragment } from './__generated__/fragment'

type ModuleInfo_items_image = ModuleInfoFragment['items'][0]['image']

type Image = ModuleInfo_items_image

const mapImage = ({ url, alt, dimensions: { width, height } }: Image) => ({
  url,
  alt,
  width,
  height,
})

export const InfoModule: FC<Module<ModuleInfoFragment>> = ({
  module: { title: moduleTitle, items },
  context: { backgroundColor },
}) => (
  <ModuleSection
    data-testid="module-info"
    backgroundColor={backgroundColor}
    padding={{ py: { xs: 8, lg: 10 } }}
    withContainer
  >
    <ModuleHeader title={moduleTitle} />
    <Grid gap={8}>
      {items.map(({ title, image, text }, index) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          width={
            items.length === 4
              ? { xs: 1, md: 1 / 2, lg: 1 / 4 }
              : { xs: 1, md: 1 / 3 }
          }
          data-testid={`info-block-${index + 1}`}
        >
          <InfoBlock
            variant={index % 2 === 0 ? 'iconLeft' : 'iconRight'}
            title={title}
            image={mapImage(image)}
            body={text}
          />
        </Box>
      ))}
    </Grid>
  </ModuleSection>
)
