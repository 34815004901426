import { createGAItem } from '../createGAItem'
import { BundleProduct, TrackingData } from '../types'

export const createViewItemGaEvent = ({
  gift,
  trackingData,
}: {
  gift: BundleProduct
  trackingData: TrackingData
}) => {
  const { carousel, bundle } = trackingData
  const position = bundle.position + 1
  const listName =
    `home | ${carousel.heading} | carousel | ${carousel.position}/${carousel.length}`.toLowerCase()
  const label =
    `${listName} | ${position}/${bundle.length} | ${gift.id}`.toLowerCase()
  const totalPrice = gift.centAmount / 100

  return {
    event: 'view_item',
    ecommerce: {
      currency: gift.currencyCode.toLowerCase(),
      value: totalPrice.toFixed(2),
      items: [
        createGAItem({
          product: gift,
          index: position,
          itemListId: `bundle | ${gift.id.toLowerCase()}`,
          itemListName: label,
          itemBrand: 'moonpig',
        }),
      ],
    },
    event_data: {
      action: 'view item',
      category: 'product action',
      non_interaction: true,
      value: totalPrice,
      label,
    },
    error_data: undefined,
  }
}
