import { InfoStrip } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { IllustrationTruckEnvelope } from '@moonpig/launchpad-assets'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { ModuleDeliveryStrip } from '../../types'

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  ${s({ px: 6 })}
`

const DeliveryStripModule: FC<ModuleDeliveryStrip> = ({ text }) => (
  <InfoStrip icon={<IllustrationTruckEnvelope />}>
    <StyledTitle>{text}</StyledTitle>
  </InfoStrip>
)

export { DeliveryStripModule }
