import { ContentModule, ModuleDynamicHero, ModuleHero } from '../../../types'
import { PipelineStep } from '../pipeline'
import { MODULE_DYNAMIC_HERO, MODULE_HERO } from '../constants'

const remove = <T>(list: Array<T>, item: T) => {
  const index = list.indexOf(item)
  if (index > -1) {
    list.splice(index, 1)
  }
}

export const dynamicHeroStep: PipelineStep<ContentModule> =
  ({ dynamicHeroData }, next) =>
  modules => {
    const processedModules = [...next(modules)]

    const moduleHero = processedModules.find(
      ({ type }) => type === MODULE_HERO,
    ) as ModuleHero

    const dynamicModulesList = processedModules.filter(
      ({ type }) => type === MODULE_DYNAMIC_HERO,
    ) as ModuleDynamicHero[]

    dynamicModulesList.forEach(module => remove(processedModules, module))

    if (!dynamicHeroData?.promotions?.length) {
      return processedModules
    }

    if (dynamicModulesList.length) {
      const dynamicModuleMatched =
        dynamicHeroData.promotions.reduce<ModuleDynamicHero | null>(
          (acc, { name }) =>
            acc ||
            dynamicModulesList.find(
              ({ promotionId }) => promotionId === name,
            ) ||
            /* istanbul ignore next */ null,
          null,
        )

      if (dynamicModuleMatched) {
        const heroIndex = processedModules.indexOf(moduleHero)

        const promotionId = `${dynamicModuleMatched.promotionId?.replace(
          /[-_]/g,
          ' ',
        )}${
          dynamicHeroData.seeding ? ' seeding' : ' not seeding'
        }`.toLowerCase()

        processedModules[heroIndex] = {
          ...dynamicModuleMatched,
          promotionId,
        }
      }
    }

    return processedModules
  }
