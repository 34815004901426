import React, { FC, ComponentProps, PropsWithChildren } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Text, Image, Link, ImageProps } from '@moonpig/launchpad-components'

type HomepageBannerVariantProps = {
  /* Will Replace iconImageUrl */
  icon?: React.ReactElement
  /** Icon image, should be 28px x 28px */
  iconImageUrl?: ImageProps['src']
  /** Url for clickable variant */
  linkUrl?: string
  /** Height of icon used for performant lazy loading */
  iconHeight?: number | string
  /** Width of icon used for performant lazy loading */
  iconWidth?: number | string
  /** Data test-id for component */
  testId?: string
}

const StyledHomepageBannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${s({
    bgcolor: 'colorBackground05',
  })}
`

const StyledLink = styled(Link)<ComponentProps<typeof Link>>`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  min-height: 48px;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
`

const StyledContainer = styled.div`
  display: inline-flex;
  min-height: 48px;
`

const HomepageBanner: FC<
  PropsWithChildren<Partial<HomepageBannerVariantProps>>
> = ({ linkUrl, children }) =>
  linkUrl ? (
    <StyledLink className="mp-styled-link" href={linkUrl}>
      <StyledContainer>{children}</StyledContainer>
    </StyledLink>
  ) : (
    <StyledContainer>{children}</StyledContainer>
  )

const StyledIconImage = styled.div`
  flex-shrink: 0;
  ${s({
    ml: 6,
    my: 'auto',
    width: '48px',
    height: '48px',
  })}
`

const StyledText = styled(Text)`
  ${s({
    mx: 5,
    my: 'auto',
    py: 4,
    typography: 'typeBodyCaption',
    color: 'colorTextHeadline',
  })}

  &.mp-styled-link:hover & {
    text-decoration: underline;
  }
`

const StyledChevron = styled.span`
  ${s({ mr: 6, my: 'auto', color: 'colorTextHeadline' })}

  &:after {
    content: '\\003e';
  }
`

export const HomepageBannerVariant: FC<
  PropsWithChildren<HomepageBannerVariantProps>
> = ({
  children,
  icon,
  iconImageUrl,
  linkUrl,
  iconWidth,
  iconHeight,
  testId = 'hp-banner-variant',
  ...props
}) => (
  <StyledHomepageBannerWrapper data-testid={testId} {...props}>
    <HomepageBanner linkUrl={linkUrl}>
      {icon && (
        <StyledIconImage aria-hidden>
          {React.cloneElement(icon, {
            width: 48,
            height: 48,
            'data-testid': 'hp-banner-variant-icon',
          })}
        </StyledIconImage>
      )}
      {iconImageUrl && !icon && (
        <StyledIconImage aria-hidden>
          <Image
            src={iconImageUrl}
            width={iconWidth}
            height={iconHeight}
            aspectRatio={1}
            data-testid="hp-banner-variant-icon"
          />
        </StyledIconImage>
      )}
      <StyledText>{children}</StyledText>
      {linkUrl && <StyledChevron aria-hidden />}
    </HomepageBanner>
  </StyledHomepageBannerWrapper>
)
