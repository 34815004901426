import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Image, Link, ImageProps } from '@moonpig/launchpad-components'

type TileProps = {
  /** A the url to navigate to upon click */
  url: string
  /** The clickable title of the tile */
  title: string
  /** The properties to specify image dimensions, alt & url */
  image: {
    width: number
    height: number
    url: ImageProps['src']
    lazyLoad?: boolean
  }
  /** To overlay the Roundel or Promo Label */
  topRightComponent?: React.ReactNode
  onClick?: () => void
}

const BUTTON_SPACING = { xs: 4, sm: 5, md: 6 }

const StyledTile = styled(Link)`
  ${s({
    mx: 'auto',
    mb: {
      xs: -BUTTON_SPACING.xs,
      sm: -BUTTON_SPACING.sm,
      md: -BUTTON_SPACING.md,
    },
  })}
  position: relative;
  display: block;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const StyledTextContainer = styled.div`
  ${s({
    typography: 'typeBodyLabel',
    textAlign: 'center',
    py: BUTTON_SPACING,
  })}
`

const StyledText = styled.span`
  ${/* sc-selector */ StyledTile}:hover & {
    text-decoration: underline;
  }
`

const StyledChevron = styled.span`
  &:after {
    ${s({ pl: 3 })}
    content: '\\003e';
  }
`

const StyledTopRight = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  max-width: 96px;
`

export const Tile: FC<TileProps> = ({
  url,
  title,
  image,
  topRightComponent,
  onClick,
}) => (
  <StyledTile href={url} onClick={onClick}>
    <Image
      src={image.url}
      alt=""
      aspectRatio={image.width / image.height}
      loading={image.lazyLoad ? 'lazy' : 'eager'}
      width={image.width}
      height={image.height}
    />
    <StyledTextContainer>
      <StyledText>{title}</StyledText>
      <StyledChevron aria-hidden />
    </StyledTextContainer>
    {topRightComponent && <StyledTopRight>{topRightComponent}</StyledTopRight>}
  </StyledTile>
)
