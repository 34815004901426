import { IllustrationBasket } from '@moonpig/launchpad-assets'
import { Box } from '@moonpig/launchpad-components'
import { createPromoClickEvent } from '@moonpig/web-core-analytics'
import { createLinkHref } from '@moonpig/web-core-link'
import React, { FC } from 'react'

import { ModuleCTAStrip } from '../../types'
import { CtaStrip } from '../../components/CtaStrip'

type TrackingData = { moduleIndex: number; moduleType: string }

const createCrplFragment = () =>
  parseInt((Math.random() * 1000000000).toString(), 10)
    .toString()
    .padStart(9, '0')

const formatUrl = (link: string) => {
  const rand1 = createCrplFragment()
  const rand2 = createCrplFragment()
  return link.replace('{rand1}', rand1).replace('{rand2}', rand2)
}

const CTAStripModule: FC<
  ModuleCTAStrip & {
    trackingData: TrackingData
  }
> = ({ text, backgroundImage, cta, trackingData, banner }) => {
  const ctaUrl = cta.shouldFormat ? formatUrl(cta.url) : cta.url
  const linkHref = createLinkHref({
    url: ctaUrl,
    trackEvent: createPromoClickEvent({
      title: text,
      ctaTitle: cta.title,
      ...trackingData,
    }),
  })
  return (
    <Box pb={banner ? { xs: 1, lg: 2 } : { xs: 6, lg: 8 }}>
      <CtaStrip
        compact={banner}
        title={text}
        data-testid="cta-strip-module"
        backgroundImageUrl={backgroundImage ? backgroundImage.url : undefined}
        icon={<IllustrationBasket />}
        cta={{ title: cta.title, url: linkHref }}
      />
    </Box>
  )
}

export { CTAStripModule }
