import React, { FC, ComponentProps, cloneElement } from 'react'
import { styled, css, breakpointDown } from '@moonpig/launchpad-utils'
import {
  Container,
  Image,
  Link,
  ImageProps,
} from '@moonpig/launchpad-components'
import {
  system as s,
  sizingSpacing,
  SizingSpacingSystemProps,
} from '@moonpig/launchpad-system'

export type CtaStripProps = {
  /** Background image, should be 1500px x 200px */
  backgroundImageUrl?: string
  /* Callback function for the onClick event */
  onClick?: () => void
  /** remove the margin and inner box if compact */
  compact?: boolean
} & CtaStripClickableContentProps &
  SizingSpacingSystemProps

type CtaStripClickableContentProps = {
  /** Width of icon used for performant lazy loading */
  iconWidth?: number | string
  /** Height of icon used for performant lazy loading */
  iconHeight?: number | string
  /** Icon image, should be 96px x 96px */
  iconImageUrl?: ImageProps['src']
  /* Will Replace iconImageUrl */
  icon?: React.ReactElement
  /** Lazy load images */
  lazyLoad?: boolean
  /** Info text */
  title: string
  /** The Call to action */
  cta: {
    title: string
    url?: string
  }
}

type StyledCtaStripProps = {
  imgUrl?: string
  compact?: boolean
} & SizingSpacingSystemProps

type StyledLinkProps = {
  compact?: boolean
} & ComponentProps<typeof Link>

const StyledCta = styled.div`
  ${s({ pr: 6, color: 'colorInteractionButton' })}
  ${breakpointDown('md')} {
    ${s({ typography: 'typeDisplay02' })}
  }
`
const StyledCtaStrip = styled.div<StyledCtaStripProps>`
  ${({ imgUrl }) =>
    imgUrl &&
    `
    background-image: url(${imgUrl});
    background-position: 0% 50%;
    background-size: cover;
  `}
  ${sizingSpacing}
  ${({ compact }) => s({ bgcolor: 'colorBackground05', py: compact ? 0 : 6 })}
  text-align: center;
`

const StyledCtaText = styled.span`
  white-space: nowrap;
  ${breakpointDown('md')} {
    display: none;
  }
`

const StyledChevron = styled.span`
  &:after {
    ${s({ pl: 3 })}
    content: '\\003e';
  }
`

const commonCtaClickableContentStyles = css<StyledLinkProps>`
  display: inline-flex;
  align-items: center;
  ${({ compact }) =>
    s({
      py: 4,
      borderRadius: 1,
      boxShadow: compact || 0,
      typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
      color: 'colorTextHeadline',
      ...(!compact && {
        bgcolor: 'transparent',
      }),
    })}

  ${({ compact }) => !compact && `transition: box-shadow 80ms linear;`}

  &:hover {
    cursor: pointer;
    ${({ compact }) => !compact && s({ boxShadow: 4 })}

    .cta-text {
      text-decoration: underline;
      ${s({ color: 'colorInteractionButtonHover' })}
    }
  }
`

const StyledLink = styled(Link)`
  ${commonCtaClickableContentStyles}
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const StyledButton = styled.button`
  ${commonCtaClickableContentStyles}
`

const StyledIconImage = styled.div`
  flex-shrink: 0;
  ${s({
    ml: 4,
    mr: -4,
    width: '40px',
    height: '40px',
  })}
`

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  ${s({ px: 6 })}
`

const getClickableContent = ({
  iconWidth,
  iconHeight,
  iconImageUrl,
  icon,
  lazyLoad,
  title,
  cta,
}: CtaStripClickableContentProps) => (
  <>
    {(icon || iconImageUrl) && (
      <StyledIconImage aria-hidden>
        {icon &&
          cloneElement(icon, {
            width: 40,
            height: 40,
            'data-testid': 'find-cta-strip-icon',
          })}
        {iconImageUrl && !icon && (
          <Image
            width={iconWidth}
            height={iconHeight}
            aspectRatio={1}
            src={iconImageUrl}
            data-testid="find-cta-strip-icon"
            loading={lazyLoad ? 'lazy' : 'eager'}
          />
        )}
      </StyledIconImage>
    )}
    <StyledTitle>{title}</StyledTitle>
    <StyledCta>
      <StyledCtaText className="cta-text">{cta.title}</StyledCtaText>
      <StyledChevron aria-hidden />
    </StyledCta>
  </>
)

export const CtaStrip: FC<CtaStripProps> = ({
  title,
  backgroundImageUrl,
  iconImageUrl,
  icon,
  cta,
  onClick,
  lazyLoad = false,
  iconWidth,
  iconHeight,
  compact,
  ...props
}) => {
  return (
    <StyledCtaStrip
      imgUrl={backgroundImageUrl}
      compact={compact}
      {...props}
      data-testid="find-cta-strip"
    >
      <Container limitWidth>
        {cta.url && (
          <StyledLink href={cta.url} compact={compact}>
            {getClickableContent({
              iconWidth,
              iconHeight,
              iconImageUrl,
              icon,
              lazyLoad,
              title,
              cta,
            })}
          </StyledLink>
        )}
        {!cta.url && onClick && (
          <StyledButton onClick={onClick} aria-label={title}>
            {getClickableContent({
              iconWidth,
              iconHeight,
              iconImageUrl,
              icon,
              lazyLoad,
              title,
              cta,
            })}
          </StyledButton>
        )}
      </Container>
    </StyledCtaStrip>
  )
}
