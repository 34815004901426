import { Grid, Box, Container } from '@moonpig/launchpad-components'
import React, { FC, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { PromoLabel } from '../../components/PromoLabel'
import { Roundel } from '../../components/Roundel'
import { ModuleSection, ModuleHeader } from '../../components/ModuleContainer'
import { ModuleTilesFragment } from './__generated__/fragment'
import { Module } from '../types'
import { RoundTile } from '../../components/RoundTile'
import { Tile } from '../../components/Tile'

type ModuleTiles_items = ModuleTilesFragment['items'][0]
type ModuleTiles_items_image = ModuleTiles_items['image']
type ModuleTiles_items_label = ModuleTiles_items['label']

const mapTileImage = ({
  webpUrl,
  defaultUrl,
  dimensions: { width, height },
}: ModuleTiles_items_image) => {
  return {
    url: {
      webp: webpUrl,
      default: defaultUrl,
    },
    width,
    height,
  }
}

const createTopRightComponent = (label: ModuleTiles_items_label | null) => {
  if (!label) return null

  return label.__typename === 'LabelPromo' ? (
    <PromoLabel>{label.text}</PromoLabel>
  ) : (
    <Roundel prefix={label.prefix} offer={label.top} suffix={label.bottom} />
  )
}

export const TilesModule: FC<Module<ModuleTilesFragment>> = ({
  module: { title: moduleTitle, subtitle, items, variant },
  context: { backgroundColor, tracking },
}) => {
  const [inViewRef, inView] = useInView({
    threshold: 0,
    rootMargin: '-300px 0px -300px 0px',
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      tracking.viewPromotion({
        module: 'tiles',
        label: moduleTitle,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <ModuleSection
      backgroundColor={backgroundColor}
      data-testid="module-tiles"
      padding={{ py: { xs: 6, lg: 7 } }}
      ref={inViewRef}
    >
      <ModuleHeader title={moduleTitle} subtitle={subtitle} />
      <Container limitWidth>
        <Grid alignContent="center" gap={{ xs: 6, sm: 8, md: 10, lg: 12 }}>
          {items.map(({ url, title, image, label }) => {
            const handleOnClick = () => {
              tracking.selectPromotion({
                module: 'tiles',
                label: moduleTitle,
                action: title,
              })
            }

            return variant === 'Round' ? (
              <Box
                key={title}
                width={{ xs: 1 / 2, md: 1 / 4 }}
                alignContent="center"
              >
                <RoundTile
                  url={url}
                  title={title}
                  image={mapTileImage(image)}
                  topRightComponent={createTopRightComponent(label)}
                  onClick={handleOnClick}
                />
              </Box>
            ) : (
              <Box
                key={title}
                width={{ xs: 1 / 2, md: 1 / 4 }}
                alignContent="center"
                padding={0}
              >
                <Tile
                  url={url}
                  title={title}
                  image={mapTileImage(image)}
                  topRightComponent={createTopRightComponent(label)}
                  onClick={handleOnClick}
                />
              </Box>
            )
          })}
        </Grid>
      </Container>
    </ModuleSection>
  )
}
