import { gql } from '@moonpig/web-core-graphql'

export const ModuleUspCarouselFragment = gql`
  fragment ModuleUspCarousel on ModuleUspCarousel {
    items {
      title
      description
      icon {
        url: url(width: 64, quality: 80)
        description
        dimensions {
          height
          width
        }
      }
    }
  }
`
