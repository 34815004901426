import { Metrics, logger } from '@moonpig/web-core-monitoring'
import { DateTime } from 'luxon'
import { AuthenticatedFetch } from '@moonpig/web-core-fetch'
import { getDataInferenceUrl } from './getDataInferenceUrl'

type Promotion = {
  name: string
  probability: number
}

export type DynamicHeroResponse = {
  promotions: Promotion[]
  seeding?: boolean
  status?: number
}

const sortPromotions = (promotions: Promotion[]) => {
  return promotions.sort(
    (a: Promotion, b: Promotion) => b.probability - a.probability,
  )
}

const fetchDynamicHeroPromotions = async ({
  isMobile,
  fetch,
}: {
  isMobile: boolean
  fetch: AuthenticatedFetch
}) => {
  const dataInferenceUrl = getDataInferenceUrl()
  const date = DateTime.local().toISO()

  let response = null
  try {
    response = await fetch(
      `${dataInferenceUrl}/dynamic-hero-browse-rev-opt?timestamp=${encodeURIComponent(
        date,
      )}&limit=5`,
      {
        credentials: 'include',
        timeout: 500,
        headers: {
          'mnpg-client-name': 'web',
          'mnpg-is-mobile-browser': isMobile.toString(),
        },
      },
    )
  } catch (error) {
    logger.fixToday('Error thrown while querying Data Inference', {}, error)
    throw error
  }

  if (response.ok) return response

  logger.fixToday('Failed to query Data Inference', {
    response: await response.text(),
    code: response.status,
  })

  throw new Error('Failed to query Data Inference')
}

export const getDynamicHeroPromotionVariants = async (
  fetch: AuthenticatedFetch,
  {
    isMobile,
    metrics,
  }: {
    isMobile: boolean
    metrics: Metrics
  },
): Promise<DynamicHeroResponse> => {
  try {
    const response = await metrics.traceAsync(
      {
        traceName: 'data-inference-request',
        metricName: 'data-inference-request',
        metricDimensions: {
          action: 'getDynamicHeroPromotionVariants',
        },
      },
      () => fetchDynamicHeroPromotions({ isMobile, fetch }),
    )

    const responseJson = await response.json()
    return {
      promotions: sortPromotions(responseJson.promotions),
      seeding: responseJson.metadata?.seeding,
    }
  } catch {
    return { promotions: [] }
  }
}
