import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { ModuleHeroFragment } from '../__generated__/fragment'
import { FullWidthHero } from '../../../components/FullWidthHero'

type ModuleHero_image = ModuleHeroFragment['image']

type Props = {
  title: string
  subtitle: string
  image: ModuleHero_image
}

export const ContainedFullWidthHero: FC<Props> = ({
  title,
  subtitle,
  image,
}) => (
  <Box maxWidth="900px" margin="auto" py={10}>
    <FullWidthHero
      title={title}
      subtitle={subtitle}
      imageUrl={image.urlBlogDefault}
    />
  </Box>
)
