import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

type RoundelProps = {
  /** The text on the top */
  prefix?: string
  /** The offer text in the middle */
  offer: string
  /** The text on the bottom */
  suffix?: string
}

const SIZE_PX = '64px'

const StyledRoundel = styled.div`
  width: ${SIZE_PX};
  height: ${SIZE_PX};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 19px;
  ${s({
    bgcolor: 'colorInteractionButton',
    color: 'colorTextInverted',
    borderRadius: 7,
    boxShadow: 1,
  })}
`

const StyledPrefix = styled.div`
  ${s({ typography: 'typeBodyCaption' })}
  line-height: inherit;
`

const StyledOffer = styled.div`
  ${s({
    typography: { xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' },
  })}
  line-height: inherit;
`

const StyledSuffix = styled.div`
  ${s({ typography: 'typeBodyCaption' })}
  line-height: 1;
`

export const Roundel: FC<RoundelProps> = ({ prefix, offer, suffix }) => (
  <StyledRoundel>
    {prefix && <StyledPrefix>{prefix}</StyledPrefix>}
    <StyledOffer>{offer}</StyledOffer>
    {suffix && <StyledSuffix>{suffix}</StyledSuffix>}
  </StyledRoundel>
)
