import { gql } from '@moonpig/web-core-graphql'

export const ModulePlacardsFragment = gql`
  fragment ModulePlacards on ModulePlacards {
    title
    subtitle
    items {
      title
      cta {
        title
        url
      }
      image {
        urlPrimary: url(width: 882, quality: 80, format: webp)
        urlDefault: url(width: 882, quality: 80, format: jpg)
        description
        dimensions {
          width
          height
        }
      }
    }
  }
`
