import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { HalfWidthHero } from './HalfWidthHero'
import { Hero } from '../../../components/Hero'

const HeroWrapper = styled.div`
  ${({ theme: { siteWidth } }) =>
    s({ m: 'auto', maxWidth: siteWidth, pb: { xs: 6, lg: 8 } })}
`
type HeroCta = {
  title: string
  url?: string
  onClick?: () => void
}

export type HeroProps = {
  title: string
  subtitle?: string
  heroVariant: string
  offer?: boolean
  image: {
    urlLargePrimary: string
    urlHeroPrimary: string
    urlHeroDefault: string
    urlLargeDefault: string
    urlSmallPrimary: string
    urlSmallDefault: string
    urlBlogPrimary: string
    urlBlogDefault: string
    description: string
  }
  isLoading?: boolean
  ctas?: {
    primary?: HeroCta
    secondary?: HeroCta
  }
}

export const HeroWithPlacard: FC<HeroProps> = ({
  title,
  subtitle,
  heroVariant,
  offer,
  image,
  ctas,
  ...rest
}) => {
  return heroVariant === 'FullWidth' ? (
    <HalfWidthHero
      title={title}
      subtitle={subtitle}
      offer={offer}
      ctas={ctas}
      imageUrl={image.urlLargeDefault}
      {...rest}
      data-testid="hero-half-width"
    />
  ) : (
    <HeroWrapper {...rest} data-testid="hero-with-placard">
      <Hero
        title={title}
        subtitle={subtitle}
        offer={offer}
        ctas={ctas}
        image={{
          url: {
            webp: image.urlSmallPrimary,
            default: image.urlSmallDefault,
          },
          alt: image.description,
          lazyLoad: false,
        }}
      />
    </HeroWrapper>
  )
}
