import React from 'react'
import { Box } from '@moonpig/launchpad-components'
import { IllustrationTruckEnvelope } from '@moonpig/launchpad-assets'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { AllSystemProps } from '@moonpig/launchpad-system'
import { Banner } from '../Banner'
import { DispatchCutOffMessage } from '../DispatchCutOffMessage'
import {
  DispatchCutOffQuery,
  useDispatchCutOffQuery,
} from '../../queries/DispatchCutOff'

export const DispatchCutOffBanner: React.FC<
  {
    department: DepartmentsEnum
    dispatchCutOffData?: DispatchCutOffQuery
  } & AllSystemProps
> = ({ department, dispatchCutOffData, ...rest }) => {
  const [showDispatchCutOff, setShowDispatchCutOff] = React.useState(true)

  const dispatchCutOff = dispatchCutOffData
    ? dispatchCutOffData.contentDepartments[0]?.dispatchCutOff
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useDispatchCutOffQuery(department)

  if (!dispatchCutOff) return null

  return showDispatchCutOff ? (
    <Banner
      icon={
        <Box pr={4}>
          <IllustrationTruckEnvelope width={40} height={40} />
        </Box>
      }
      testId="dispatch-cut-off-banner"
      px={{ xs: 6, md: 8 }}
      {...rest}
    >
      <DispatchCutOffMessage
        dispatchCutOff={dispatchCutOff}
        hideDispatchCutOff={() => setShowDispatchCutOff(false)}
      />
    </Banner>
  ) : null
}
