import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import {
  SizingSpacingSystemProps,
  sizingSpacing,
  system as s,
} from '@moonpig/launchpad-system'
import { IconSystemPlayOutline } from '@moonpig/launchpad-assets'
import { useFindLocaleText } from '../../../text-localisation'

const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${sizingSpacing}
  ${s({ color: 'colorBlack00' })}

  &:active {
    ${s({ color: 'colorInteractionButton' })}
  }

  &:hover,
  &:focus {
    ${s({ color: 'colorInteractionButtonHover' })}
  }
`

type PlayIconButtonProps = SizingSpacingSystemProps & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const PlayIconButton: FC<PlayIconButtonProps> = ({
  onClick,
  ...props
}) => {
  const localiseText = useFindLocaleText()

  return (
    <StyledIconButton
      onClick={onClick}
      aria-label={localiseText('find.play-video-label')}
      {...props}
    >
      <IconSystemPlayOutline width="86px" height="72px" aria-hidden />
    </StyledIconButton>
  )
}
