import { gql } from '@moonpig/web-core-graphql'

export const ModuleBreadcrumbsFragment = gql`
  fragment ModuleBreadcrumbs on ModuleBreadcrumbs {
    __typename
    breadcrumbs {
      label: title
      url
    }
  }
`
