import React, { FC, useEffect } from 'react'
import { Box, Grid } from '@moonpig/launchpad-components'
import { useInView } from 'react-intersection-observer'
import { ModulePlacardsFragment } from './__generated__/fragment'
import { ModuleHeader, ModuleSection } from '../../components/ModuleContainer'
import { Module } from '../types'
import { Placard } from '../../components/Placard'

export const PlacardsModule: FC<Module<ModulePlacardsFragment>> = ({
  module: { title: moduleTitle, subtitle, items },
  context: { backgroundColor, tracking },
}) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      items.forEach(({ title }) => {
        tracking.viewPromotion({ module: 'placards', label: title })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <ModuleSection
      data-testid="module-placards"
      backgroundColor={backgroundColor}
      padding={{ py: { xs: 8, lg: 10 } }}
      withContainer
      ref={inViewRef}
    >
      <ModuleHeader title={moduleTitle} subtitle={subtitle} />
      <Grid justifyContent="center" gap>
        {items.map(({ title, cta, image }) => {
          return (
            <Box key={title} width={{ xs: 1, md: 1 / 2 }}>
              <Placard
                title={title}
                cta={cta}
                imageUrl={{
                  default: image.urlDefault,
                  webp: image.urlPrimary,
                }}
                lazyLoad
                onClick={() => {
                  tracking.selectPromotion({
                    module: 'placards',
                    label: title,
                    action: cta.title,
                  })
                }}
              />
            </Box>
          )
        })}
      </Grid>
    </ModuleSection>
  )
}
