import React, { FC } from 'react'
import { ModuleVideo } from '../../types'
import { ModuleContainer } from '../../components/ModuleContainer'
import { EmbeddedVideo } from '../../components/Landing/EmbeddedVideo'

const VideoModule: FC<ModuleVideo> = ({ id, title, titleHidden, subtitle }) => (
  <ModuleContainer title={titleHidden ? '' : title} subtitle={subtitle}>
    <EmbeddedVideo id={id} title={title} />
  </ModuleContainer>
)

export { VideoModule }
