import { Heading, ScreenReaderOnly } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { ModuleSection } from '../../components/ModuleContainer'
import type { Module } from '../types'
import type { ModuleHeadingFragment } from './__generated__/fragment'

export const HeadingModule: FC<Module<ModuleHeadingFragment>> = ({
  module: { heading, hidden },
  context: { backgroundColor },
}) => {
  const header = (
    <Heading level="h2" textAlign="center" mb={0} as="h1">
      {heading}
    </Heading>
  )
  return (
    <ModuleSection
      withContainer
      padding={hidden ? { py: 0 } : { py: { xs: 6, lg: 7 } }}
      backgroundColor={backgroundColor}
      data-testid="module-heading"
    >
      {hidden ? <ScreenReaderOnly>{header}</ScreenReaderOnly> : header}
    </ModuleSection>
  )
}
