import React, { SVGProps } from 'react'

export const IconShopaholic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 38 41"
    {...props}
  >
    <path
      fill="#000"
      d="M18 0c-4.615 0-8.44 3.514-8.941 8H7a4.491 4.491 0 0 0-4.477 4.033l-2.5 24a4.515 4.515 0 0 0 1.133 3.48A4.508 4.508 0 0 0 4.5 41h29c1.273 0 2.49-.542 3.344-1.488a4.512 4.512 0 0 0 1.133-3.479l-2.5-24A4.492 4.492 0 0 0 31 8h-2.047c.031.33.047.664.047 1v6a1 1 0 0 1-2 0V9c0-4.953-4.047-9-9-9Zm0 3a5.97 5.97 0 0 1 5.912 5h-9.78c-.083.32-.132.654-.132 1v6a1 1 0 0 1-2 0V9c0-3.331 2.669-6 6-6Zm-1.955 18.004c.978-.031 1.862.35 2.549 1.02l.406.396.406-.397c.685-.669 1.581-1.045 2.55-1.02a3.554 3.554 0 0 1 2.523 1.083 3.566 3.566 0 0 1 1.021 2.549 3.566 3.566 0 0 1-1.082 2.523l-4.725 4.565a.995.995 0 0 1-1.386 0l-4.721-4.559a3.568 3.568 0 0 1-1.086-2.527 3.57 3.57 0 0 1 1.021-2.551 3.56 3.56 0 0 1 2.524-1.082Z"
    />
  </svg>
)
