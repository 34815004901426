import { getDepartmentTitle } from '@moonpig/web-core-utils'
import { ContentModule, ModuleSearchResults } from '../types'
import {
  CmsContent,
  SearchFilters,
  SearchParameters,
} from '../pages/GalleryPage/types'
import { Department } from '../components/types'

export function getContent(modules: ContentModule[]): CmsContent {
  const content: CmsContent = {} as CmsContent

  modules.forEach(module => {
    switch (module.type) {
      case 'ModuleOfferStrip':
        content.offer = module.text
        break
      case 'ModuleHeading':
        content.title = module.heading
        break
      case 'ModuleDescription':
        content.description = module.description
        break
      case 'ModuleBreadcrumbs':
        content.breadcrumbsItems = module.breadcrumbs
        break
      case 'ModulePromoTile':
        content.promoTile = module
        break
      default:
        break
    }
  })

  return content
}

export const getSearchContent = (
  modules: ContentModule[],
  searchTerm: string,
  department: Department[],
): CmsContent => {
  const pageContent = getContent(modules)
  const departmentTitle = getDepartmentTitle(department[0])

  pageContent.title = `${searchTerm.replace(/\b\w/g, firstChar =>
    firstChar.toUpperCase(),
  )} ${departmentTitle}`

  pageContent.description = ''

  return pageContent
}

export const getSearchParameters = (
  modules: ContentModule[],
): SearchParameters => {
  const searchParameters: ModuleSearchResults = modules.find(
    (module: ContentModule) => module.type === 'ModuleSearchResults',
  ) as ModuleSearchResults

  const searchTerm = searchParameters.keywords

  const filters: {
    facets?: {
      facetKey: string
      group: string
    }[]
  } = {
    facets: searchParameters.facetDetails.map(facet => {
      return {
        facetKey: facet.key || /* istanbul ignore next */ '',
        group: `preset-${facet.group as string}`,
        userApplied: false,
      }
    }),
  }

  const searchFilters: SearchFilters = {
    facets: [] as {
      facetKey: string
      group: string
      userApplied: boolean
    }[],
    ...filters,
    promotionId: searchParameters.promotionId,
  }

  const preAppliedFacets: string[] = searchFilters.facets.map(
    (facet: { facetKey: string }) => facet.facetKey,
  )

  const sponsoredProducts = searchParameters.sponsoredProducts.map(product => {
    return {
      internallyPromoted: product.internallyPromoted,
      productKey: product.productKey,
    }
  })

  return {
    searchFilters,
    departments: searchParameters.departments as Department[],
    searchTerm,
    preAppliedFacets,
    sponsoredProducts,
  }
}

function isGiftGallery(departments: Department[]): boolean {
  return (
    departments.includes('ALL_GIFTS' as Department) ||
    departments.includes('ALCOHOL_GIFTS' as Department) ||
    departments.includes('FOOD_DRINK' as Department) ||
    departments.includes('FOOD_GIFTS' as Department) ||
    departments.includes('BALLOONS' as Department) ||
    departments.includes('SOFT_TOYS' as Department) ||
    departments.includes('GIFTS_FOR_HOME' as Department) ||
    departments.includes('GIFT_BOXES' as Department) ||
    departments.includes('GIFT_EXPERIENCES' as Department) ||
    departments.includes('JEWELLERY_AND_ACCESSORIES' as Department) ||
    departments.includes('LETTERBOX_GIFTS' as Department) ||
    departments.includes('NON_CUSTOMISABLE_GIFTS' as Department) ||
    departments.includes('PARTY' as Department) ||
    departments.includes('TOYS_AND_GAMES' as Department) ||
    departments.includes('MUGS' as Department) ||
    departments.includes('TSHIRTS' as Department) ||
    departments.includes('BEAUTY' as Department)
  )
}

function isFlowersGallery(departments: Department[]): boolean {
  return (
    departments.includes('ALL_FLOWERS_AND_PLANTS' as Department) ||
    departments.includes('FLOWERS' as Department) ||
    departments.includes('PLANTS' as Department)
  )
}

export function getSearchContext(
  modules: ContentModule[],
): { department: { ids: number[]; slug: string; title: string } } | undefined {
  let context:
    | { department: { ids: number[]; slug: string; title: string } }
    | undefined

  const searchParameters: ModuleSearchResults = modules.find(
    (module: ContentModule) => module.type === 'ModuleSearchResults',
  ) as ModuleSearchResults

  const departments = (searchParameters &&
    searchParameters.departments) as Department[]

  if (departments && !context) {
    if (isGiftGallery(departments)) {
      context = {
        department: {
          ids: [13, 17, 19, 23, 24, 25, 28, 29, 30, 31, 32, 33],
          slug: 'all-gifts',
          title: 'gifts',
        },
      }
    } else if (isFlowersGallery(departments)) {
      context = {
        department: {
          ids: [10],
          slug: 'flowers-and-plants',
          title: 'flowers',
        },
      }
    } else {
      context = {
        department: {
          ids: [1],
          slug: 'greeting-cards',
          title: 'cards',
        },
      }
    }
  }

  return context
}
