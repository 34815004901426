import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Box, Text, Image } from '@moonpig/launchpad-components'
import { ModuleBlogPostAuthor } from '../../types'

const StyledImage = styled(Image)`
  ${s({ borderRadius: 8 })}
  width: 100%;
  overflow: hidden;
`

export const BlogPostAuthorModule: FC<ModuleBlogPostAuthor> = ({
  name,
  jobTitle,
  image,
}) => {
  return (
    <Box textAlign="center">
      <Box maxWidth="70px" mx="auto">
        <StyledImage src={image.url} alt={image.description} />
      </Box>
      <Text typography="typeDisplay06">{name}</Text>
      <Text as="p" typography="typeBodyCaption" color="colorBlack40">
        {jobTitle}
      </Text>
    </Box>
  )
}
