export const MODULE_DELIVERY_STRIP = 'ModuleDeliveryStrip'
export const MODULE_HERO = 'ModuleHero'
export const MODULE_DYNAMIC_HERO = 'ModuleDynamicHero'
export const MODULE_CTA_STRIP = 'ModuleCTAStrip'
export const MODULE_USP_CAROUSEL = 'ModuleUspCarousel'
export const MODULE_MISSION_BUTTONS = 'ModuleMissionButtons'
export const MODULE_RECOMMENDATIONS_FOR_CUSTOMER =
  'ModuleRecommendationsForCustomer'
export const MODULE_PLACEHOLDER = 'ModulePlaceholder'
export const MODULE_SEARCH_RESULTS = 'ModuleSearchResults'
export const MODULE_TABBED_PRODUCT_LISTS = 'ModuleTabbedProductLists'
export const MODULE_RECENTLY_VIEWED = 'ModuleRecentlyViewed'
export const MODULE_PLACARDS = 'ModulePlacards'
export const MODULE_TILES = 'ModuleTiles'
export const MODULE_INFO = 'ModuleInfo'
export const MODULE_HEADING = 'ModuleHeading'
export const MODULE_SEO = 'ModuleSEO'
export const MODULE_EMAIL_CAPTURE = 'ModuleEmailCapture'
export const MODULE_CARD = 'ModuleCard'
