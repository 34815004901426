import { isLocalStorageAvailable } from '@moonpig/web-core-utils'

export const trySetLocalStorage = (key: string, value: string) => {
  try {
    /* istanbul ignore else */
    if (isLocalStorageAvailable()) {
      localStorage.setItem(key, value)
    }
  } catch {
    /* empty */
  }
}

export const tryGetLocalStorage = (key: string) => {
  try {
    return isLocalStorageAvailable()
      ? localStorage.getItem(key)
      : /* istanbul ignore next */ null
  } catch {
    /* istanbul ignore next */
    return null
  }
}
