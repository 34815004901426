/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { ThemeInterface } from '@moonpig/launchpad-theme'
import { ContentModule } from '../../types'
import { BlogPostDateModule } from '../../modules/BlogPostDate'
import { RichTextModule } from '../../modules/RichText'
import { BlogPostAuthorModule } from '../../modules/BlogPostAuthor'
import { BlogPostCategoryModule } from '../../modules/BlogPostCategory'
import { mapModule } from '../../modules/map-modules'
import { createTracking } from '../../analytics/tracking'

type TrackingData = { moduleIndex: number; moduleType: string }

type BlogPostProps = {
  modules: ContentModule[]
}

const StyledBlogContainer = styled(Box)<{ theme: ThemeInterface }>`
  ${({ theme: { richTextWidth, spacing } }) =>
    s({
      maxWidth: richTextWidth + spacing[6] * 2,
      mx: 'auto',
      px: 6,
    })}
`

const renderModule = ({
  module,
  key,
  trackingData,
}: {
  module: ContentModule
  key: number
  trackingData: TrackingData
}) => {
  const context = {
    index: trackingData.moduleIndex,
    layout: 'blog',
    tracking: createTracking({ page: 'blog' }),
  }

  switch (module.type) {
    case 'ModuleBlogPostDate':
      return (
        <StyledBlogContainer key={key} pb={6}>
          <BlogPostDateModule {...module} />
        </StyledBlogContainer>
      )
    case 'ModuleRichText':
      return <RichTextModule key={key} module={module} context={context} />

    case 'ModuleBlogPostAuthor':
      return (
        <StyledBlogContainer key={key} p={8}>
          <BlogPostAuthorModule {...module} />
        </StyledBlogContainer>
      )
    case 'ModuleBlogPostCategory':
      return (
        <StyledBlogContainer key={key} p={3}>
          <BlogPostCategoryModule {...module} />
        </StyledBlogContainer>
      )

    case 'ModuleBreadcrumbs':
    case 'ModuleHero':
      return mapModule(module, context)
    default:
      return null
  }
}

export const BlogPost: FC<BlogPostProps> = ({ modules }) => (
  <Box bgcolor="white" pb={6}>
    {modules.map((module, key) =>
      renderModule({
        module,
        key,
        trackingData: {
          moduleIndex: key,
          moduleType: module.type,
        },
      }),
    )}
  </Box>
)
