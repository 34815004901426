import { styled } from '@moonpig/launchpad-utils'
import { Box } from '@moonpig/launchpad-components'

export const AnimatedBox = styled(Box)`
  animation: shop-for-reminder-pop 750ms;
  animation-fill-mode: backwards;

  @keyframes shop-for-reminder-pop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
