import { useContext, useMemo } from 'react'
import { useSessionId } from '@moonpig/web-core-utils'
import { EppoContext } from '../EppoContext/EppoContext'

type UseEppoExperimentParams = {
  fallback: string
  subjectKey?: string
}

export const useEppoExperiment = (
  experimentKey: string,
  { fallback }: UseEppoExperimentParams,
) => {
  const eppoClient = useContext(EppoContext)
  const { sessionId } = useSessionId()

  const assignedVariation = useMemo(() => {
    if (!eppoClient) return fallback

    return eppoClient.getStringAssignment(
      experimentKey,
      sessionId,
      {},
      fallback,
    )
  }, [eppoClient, experimentKey, fallback, sessionId])

  return assignedVariation
}
