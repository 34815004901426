import React, { useEffect } from 'react'
import { gql, useLazyQuery } from '@moonpig/web-core-graphql'
import { DateTime } from 'luxon'
import { Store } from '@moonpig/web-core-types'
import { useStore } from '@moonpig/web-core-stores'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { useExperiment } from '@moonpig/web-core-experiments'
import {
  UpcomingReminderOccasionQuery,
  UpcomingReminderOccasionQueryVariables,
} from './__generated__/ShopForReminderModule'
import { ShopForReminderContent } from './ShopForReminderContent'
import { PRODUCT_FIELDS_FRAGMENT } from '../../../../queries/productFields'

export const NextReminderGQL = gql`
  query UpcomingReminderOccasion($endDate: String!) {
    nextUnfulfilledReminder(endDate: $endDate) {
      id
      nextDate
      name
      occasion
      recommendations {
        products {
          ...ProductFields
        }
      }
    }
  }

  ${PRODUCT_FIELDS_FRAGMENT}
`

const getEndDate = (store: Store) => {
  const currentDateTime = DateTime.local({
    zone: store.locale.timeZone,
    locale: store.locale.language,
  })
  const endDateTime = currentDateTime.plus({
    days: 30,
  })

  const endDate = endDateTime.toISODate()

  return endDate
}

export const ShopForReminderModule = () => {
  const { store } = useStore()
  const { loggedIn } = useLoggedIn()
  // To do: Remove experiment
  const inExperiment =
    useExperiment('loyalty-upcoming-occasions-v2', 'Control') === 'Variant'
  const [query, { data, previousData, loading, error }] = useLazyQuery<
    UpcomingReminderOccasionQuery,
    UpcomingReminderOccasionQueryVariables
  >(NextReminderGQL)

  useEffect(() => {
    if (loggedIn && inExperiment) {
      query({
        variables: {
          endDate: getEndDate(store),
        },
      })
    }
  }, [query, loggedIn, store, inExperiment])

  return (
    <ShopForReminderContent
      loading={loading}
      error={!!error}
      reminder={
        data?.nextUnfulfilledReminder || previousData?.nextUnfulfilledReminder
      }
    />
  )
}
