import React from 'react'
import { ContentModule, PageContent } from '../../types'
import { BlogCategory } from '../../components/BlogCategory'

export type BlogCategoryPageProps = {
  modules: ContentModule[]
}

export const BlogCategoryPage: PageContent<BlogCategoryPageProps> = ({
  modules,
}) => <BlogCategory modules={modules} />

BlogCategoryPage.getInitialProps = async ({ modules }) => ({
  props: { modules },
  pageOptions: {},
})
