import React, { FC } from 'react'
import { Image, Text, Box, Link, Heading } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { BlogTile } from '../../types'

const StyledLink = styled(Link)`
  ${s({ color: 'colorTextBody' })}
`

export const BlogPostTile: FC<BlogTile & { href: string }> = ({
  title,
  subtitle,
  image,
  href,
}) => {
  return (
    <StyledLink href={href}>
      <Box width="100%">
        <Image src={image.url} width="100%" />
        <Heading level="h5" px={4} m={0}>
          {title}
        </Heading>
        <Text px={4} as="p">
          {subtitle}
        </Text>
      </Box>
    </StyledLink>
  )
}
