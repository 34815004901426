import React, { FC } from 'react'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Image, ImageProps, Link } from '@moonpig/launchpad-components'

type PlacardProps = {
  /** Hero heading */
  title: string
  imageUrl: ImageProps['src']
  lazyLoad?: boolean
  /** Cta url and text */
  cta: {
    url: string
    title: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  }
  onClick?: () => void
}

const IMAGE_WIDTH_DESKTOP = '75%'
const CONTENT_WIDTH_DESKTOP = '25%'

const StyledPlacard = styled(Link)`
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;

    .cta-text {
      text-decoration: underline;
    }
  }

  ${breakpoint('xl')} {
    flex-direction: row-reverse;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme: { spacing } }) =>
    s({
      position: { xs: 'relative', xl: 'absolute' },
      boxShadow: 1,
      bgcolor: 'colorBackground01',
      p: 6,
      width: {
        xs: 4 / 5,
        xl: `calc(${CONTENT_WIDTH_DESKTOP} + ${spacing[13]}px)`,
      },
      mt: { xs: -10, xl: 0 },
      height: { xs: '100%', xl: 'auto' },
      left: 0,
      top: { xl: spacing[10] },
      bottom: { xl: spacing[10] },
    })}
`

const StyledImage = styled.div`
  position: relative;
  z-index: 0;
  ${breakpoint('xl')} {
    width: ${IMAGE_WIDTH_DESKTOP};
  }
`

const StyledText = styled.div`
  ${s({
    typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
    pb: 5,
  })}

  ${breakpoint('xl')} {
    ${s({
      typography: { xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' },
    })}
  }
`

const StyledCta = styled.div`
  ${s({ typography: 'typeButtonLabel', color: 'colorInteractionIcon' })}
`

const StyledChevron = styled.span`
  &:after {
    ${s({ pl: 3 })}
    content: '\\003e';
  }
`

export const Placard: FC<PlacardProps> = ({
  cta,
  title,
  imageUrl,
  lazyLoad,
  onClick,
}) => (
  <StyledPlacard href={cta.url} onClick={onClick}>
    <StyledImage>
      <Image
        src={imageUrl}
        alt=""
        aspectRatio={16 / 9}
        loading={lazyLoad ? 'lazy' : 'eager'}
      />
    </StyledImage>
    <StyledContent>
      <StyledText>{title}</StyledText>
      <StyledCta>
        <span className="cta-text">{cta.title}</span>
        <StyledChevron aria-hidden />
      </StyledCta>
    </StyledContent>
  </StyledPlacard>
)
