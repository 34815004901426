import { gql, useQuery } from '@moonpig/web-core-graphql'
import { useMemo } from 'react'
import { DepartmentsEnum, Platform } from '@moonpig/web-core-types-graphql'
import { usePlatform } from '@moonpig/web-core-context'
import { getCardVariantIdsEnabled } from '@moonpig/web-shared-products'
import {
  GetProductListProductsQuery,
  GetProductListProductsQueryVariables,
} from './__generated__/useProductListQuery'
import { transformFiltersToGroupedFilters } from '../../services/helpers/groupFilters'

type Facet = {
  group: string | null
  key: string | null
  userApplied?: boolean
}

type MapToFilters = (facets: Facet[]) => {
  group: string
  key: string
  userApplied?: boolean
}[]

export type ProductListQueryInput = {
  keywords: string
  departments: DepartmentsEnum[]
  facets: Facet[]
  excludeRudeCards?: boolean
  promotionId?: string
  canQuery: boolean
}

export type ProductListProducts =
  GetProductListProductsQuery['productSearch']['products']

type UseProductListQuery = (input: ProductListQueryInput) => ProductListProducts

export const GetProductListProductsGQL = gql`
  query GetProductListProducts(
    $searchTerm: String!
    $department: [DepartmentsEnum!]
    $filters: CriteriaInput!
    $customerId: String
    $experimentValues: String
    $nbaAlgorithm: String
    $platform: Platform
  ) {
    productSearch(
      limit: 20
      searchTerm: $searchTerm
      department: $department
      filters: $filters
      experimentValues: $experimentValues
      nbaAlgorithm: $nbaAlgorithm
      customerId: $customerId
      platform: $platform
    ) {
      products {
        title
        description
        slug
        dependencies
        clickRankDocumentCount
        category {
          slug
          name
          id
          department
        }
        customisable
        isLandscape
        rating {
          count
          score
        }
        id
        masterVariant {
          key
          title
          images {
            url
          }
          price {
            centAmount
            currencyCode
            fractionDigits
          }
          fullPrice {
            centAmount
            currencyCode
            fractionDigits
          }
          discountedPercentage
          masterImage {
            url
          }
          inStock
          minimumQuantity
          sku
          bundles {
            description
            price {
              currencyCode
              centAmount
              fractionDigits
            }
            discountPercentage
            size
          }
          capabilities {
            video
          }
        }
        variants {
          key
          title
          dimensions {
            description
          }
          subtitle
          price {
            currencyCode
            centAmount
            fractionDigits
          }
          fullPrice {
            currencyCode
            centAmount
            fractionDigits
          }
          discountedPercentage
          inStock
          minimumQuantity
          sku
          bundles {
            description
            price {
              currencyCode
              centAmount
              fractionDigits
            }
            discountPercentage
            size
          }
          capabilities {
            video
          }
        }
        hasAugmentedReality
        primaryProductPill {
          displayLabel
          displayVariant
        }
        productPills {
          displayLabel
          displayVariant
        }
        isSponsored
      }
    }
  }
`

const mapToFilters: MapToFilters = facets =>
  facets.map(f => ({
    group: f.group || '',
    key: f.key || '',
    userApplied: f.userApplied,
  }))

export const useProductListQuery: UseProductListQuery = ({
  keywords,
  departments,
  facets,
  excludeRudeCards,
  promotionId,
  canQuery,
}) => {
  const platform = usePlatform()

  const excludeRude =
    excludeRudeCards &&
    !departments.includes(DepartmentsEnum.ALL_GIFTS) &&
    !departments.includes(DepartmentsEnum.ALL_FLOWERS_AND_PLANTS) &&
    departments.includes(DepartmentsEnum.ALL_CARDS)

  const variants =
    departments.includes(DepartmentsEnum.ALL_CARDS) ||
    departments.includes(DepartmentsEnum.GREETING_CARDS)
      ? getCardVariantIdsEnabled()
      : []

  const { data, previousData } = useQuery<
    GetProductListProductsQuery,
    GetProductListProductsQueryVariables
  >(GetProductListProductsGQL, {
    skip: !canQuery,
    variables: {
      searchTerm: keywords,
      department: departments,
      filters: {
        groupedFacets: transformFiltersToGroupedFilters(mapToFilters(facets)),
        ...(variants && { variants }),
        ...(promotionId && { promotionId }),
        ...(excludeRude && { excludeRude }),
      },
      experimentValues: 'search-use-new-ia-index=true',
      platform: platform.isMobile ? Platform.MOBILE_WEB : Platform.WEB,
      customerId: undefined,
      nbaAlgorithm: undefined,
    },
  })

  const productList = useMemo(() => {
    if (data) return data.productSearch.products
    /* istanbul ignore next */ if (previousData)
      return previousData.productSearch.products
    return []
  }, [data, previousData])

  return productList
}
