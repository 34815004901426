import { useMutation, gql } from '@moonpig/web-core-graphql'
import { useRouter } from '@moonpig/web-core-routing'
import { logger } from '@moonpig/web-core-monitoring'
import { useStoreId } from '@moonpig/web-core-stores'
import { ApolloQueryResult } from '@apollo/client'
import { trackBasketEvent } from './tracking'
import { BundleProduct, TrackingData } from './types'
import { useSetUIHCookie } from '../../utils'

const FATHERS_DAY_OCCASION_FACET = {
  group: 'preset-occasion',
  facetKey: 'fathers-day',
}

export const AddGiftToBasketMutation = gql`
  mutation AddBundledGiftToBasket($input: AddToBasketInput!) {
    addToBasket(input: $input) {
      ... on Basket {
        __typename
        id
        totalItems
      }
      ... on ConcurrentModificationError {
        __typename
        message
      }
      ... on ProductNotFoundError {
        __typename
        message
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`

export const useAddBundleToBasket = (
  gift: BundleProduct,
  trackingData: TrackingData,
) => {
  const [addGiftToBasket] = useMutation(AddGiftToBasketMutation)
  const { setUIHCookie } = useSetUIHCookie()
  const storeId = useStoreId()
  const router = useRouter()
  const giftId = gift.id
  const quantity = 1
  return {
    addBundleToBasket: async () => {
      try {
        const { data, errors } = (await addGiftToBasket({
          variables: { input: { sku: gift.sku, quantity } },
        })) as ApolloQueryResult<any> // eslint-disable-line @typescript-eslint/no-explicit-any

        if (errors) {
          logger.fixToday('Error returned from AddGiftToBasketMutation', {
            giftId,
            errors: JSON.stringify(errors),
          })
          return false
        }

        if (data?.addToBasket.__typename !== 'Basket') {
          logger.fixToday('Failed to add gift to basket', {
            giftId,
            data: JSON.stringify(data),
          })
          return false
        }

        // track add to basket event
        const { id, totalItems } = data.addToBasket
        trackBasketEvent({
          basketData: {
            basketId: id,
            basketSize: totalItems - quantity,
          },
          product: gift,
          trackingData,
        })

        setUIHCookie({
          product: {
            id: gift.id,
            category: { id: gift.categoryId },
          },
          facets: [FATHERS_DAY_OCCASION_FACET],
        })

        router.push({
          name: 'add-a-card',
          params: { region: storeId, version: 1 },
        })
        return true
      } catch {
        logger.fixToday('addGiftToBasket operation failed', {
          giftId,
        })

        return false
      }
    },
  }
}
