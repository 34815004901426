import { useQuery } from '@moonpig/web-core-graphql'
import { BundleProduct } from './types'
import { BUNDLE_GIFT_SKUS } from './bundleGiftSkus'
import { GetGiftsQuery } from './GetGiftsQuery'
import { createFormattedProduct } from './createFormattedProduct'
import {
  GetGiftsQuery as GetGiftsQueryResponse,
  GetGiftsQueryVariables,
} from './__generated__/GetGiftsQuery'

export const useBundleGifts = (): BundleProduct[] => {
  const skus = BUNDLE_GIFT_SKUS.map(({ sku }) => sku)
  const { data } = useQuery<GetGiftsQueryResponse, GetGiftsQueryVariables>(
    GetGiftsQuery,
    {
      variables: { skus },
    },
  )

  if (!data) {
    return []
  }

  const gifts = data.productLookup.products

  const inStockGifts = gifts.filter(gift => gift.masterVariant.inStock)

  return inStockGifts.slice(0, 20).map(gift => createFormattedProduct(gift))
}
