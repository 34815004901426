import {
  createPromoClickEvent,
  createPromoExperimentClickEvent,
  createPromoExperimentDeeplinkClickEvent,
} from '@moonpig/web-core-analytics'
import { createLinkHref } from '@moonpig/web-core-link'

type TrackingData = {
  moduleIndex: number
  moduleType: string
  experimentVariantName: string
  brand: string
}

const createCrplFragment = () =>
  parseInt((Math.random() * 1000000000).toString(), 10)
    .toString()
    .padStart(9, '0')

const generateCrossPlatformTrackingId = () => {
  const rand1 = createCrplFragment()
  const rand2 = createCrplFragment()

  return `${rand1}.${rand2}`
}

const replaceCrplId = (link: string, crplId: string) => {
  return link.replace('{rand1}.{rand2}', crplId)
}

const generateTrackEvent = ({
  title,
  moduleType,
  moduleIndex,
  elementIndex,
  ctaUrl,
  ctaTitle,
  experimentVariantName,
  crossPlatformTrackingId,
}: {
  title: string
  moduleType: string
  moduleIndex: number
  elementIndex: number
  ctaUrl: string
  ctaTitle: string
  experimentVariantName: string
  crossPlatformTrackingId: string
}) => {
  const gaData = {
    title,
    moduleType,
    moduleIndex,
    elementIndex,
  }

  const isDeepLink = ctaUrl.includes('crpl=')

  if (isDeepLink) {
    return experimentVariantName === 'Original' ||
      /* istanbul ignore next */ experimentVariantName === 'Not bucketed'
      ? createPromoClickEvent({
          ...gaData,
          ctaTitle,
          crossPlatformTrackingId,
        })
      : /* istanbul ignore next */ createPromoExperimentDeeplinkClickEvent({
          ...gaData,
          crossPlatformTrackingId,
          experimentVariantName,
          brand: 'moonpig',
        })
  }

  return experimentVariantName === 'Original' ||
    /* istanbul ignore next */ experimentVariantName === 'Not bucketed'
    ? createPromoClickEvent({
        ...gaData,
        ctaTitle,
      })
    : /* istanbul ignore next */ createPromoExperimentClickEvent({
        ...gaData,
        experimentVariantName,
        brand: 'moonpig',
      })
}

export const createLinkWithTracking = (
  trackingData: TrackingData,
  title: string,
  cta: {
    title: string
    url?: string
  },
  index: number,
) => {
  /* istanbul ignore else */
  if (cta.url) {
    const crossPlatformTrackingId = generateCrossPlatformTrackingId()
    const link = replaceCrplId(cta.url, crossPlatformTrackingId)
    const { moduleType, moduleIndex, experimentVariantName } = trackingData

    return createLinkHref({
      url: link,
      trackEvent: generateTrackEvent({
        title,
        moduleType,
        moduleIndex,
        elementIndex: index,
        ctaUrl: cta.url,
        ctaTitle: cta.title,
        experimentVariantName,
        crossPlatformTrackingId,
      }),
    })
  }
  /* istanbul ignore next */
  return undefined
}
