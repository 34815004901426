import { ContentModule } from '../../../types'
import { MODULE_RECOMMENDATIONS_FOR_CUSTOMER } from '../constants'
import { PipelineStep } from '../pipeline'

export const removeRecommendationsForCustomerStep: PipelineStep<
  ContentModule
> = (_, next) => modules => {
  const processedModules = next(modules)

  return processedModules.filter(
    ({ type }) => type !== MODULE_RECOMMENDATIONS_FOR_CUSTOMER,
  )
}
