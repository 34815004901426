import React, { FC } from 'react'
import { IllustrationBasket } from '@moonpig/launchpad-assets'
import {
  Heading,
  PrimaryButton,
  Box,
  Text,
} from '@moonpig/launchpad-components'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Region } from '@moonpig/web-core-types'
import { useFindLocaleText } from '../../text-localisation'

const StyledButtonWrapper = styled(Box)`
  ${s({
    mx: 6,
  })}
  width: calc(100% - 32px);
  ${breakpointUp('md')} {
    width: auto;
    min-width: 250px;
  }
`

type SoldOutGalleryMessageProps = {
  region: Region
  department: string[]
}

type OutofStockCopy = {
  message: string
  link: string
  buttonCopy: string
}

export const SoldOutGalleryMessage: FC<SoldOutGalleryMessageProps> = ({
  department,
  region,
}) => {
  const localiseText = useFindLocaleText()

  const getOutOfStockCopy = (): OutofStockCopy => {
    const parentDepartment = getParentDepartment(department[0])

    if (parentDepartment === 'ALL_FLOWERS_AND_PLANTS') {
      return {
        message: localiseText(
          'find.looks_like_weve_sold_out_of_these_products_but_you_can_still_send_heartfelt_flowers',
        ),
        link: localiseText('find.flowers_and_plants_link', { region }),
        buttonCopy: localiseText('find.view_flowers'),
      }
    }
    if (parentDepartment === 'ALL_GIFTS') {
      return {
        message: localiseText(
          'find.looks_like_weve_sold_out_of_these_products_but_you_can_still_send_a_heartfelt_gift',
        ),
        link:
          region === 'au'
            ? `/${region}/personalised-mugs/`
            : localiseText('find.gifts_link', { region }),
        buttonCopy: localiseText('find.view_gifts'),
      }
    }
    return {
      message: localiseText(
        'find.looks_like_weve_sold_out_of_these_products_but_you_can_still_send_a_heartfelt_card',
      ),
      link:
        region === 'us'
          ? `/${region}/personalized-cards/`
          : localiseText('find.cards_link', { region }),
      buttonCopy: localiseText('find.view_cards'),
    }
  }

  const { message, link, buttonCopy } = getOutOfStockCopy()
  return (
    <Box
      display="flex"
      flexDirection="column"
      textAlign="center"
      alignItems="center"
      my={8}
    >
      <IllustrationBasket
        aria-label={localiseText('find.basket')}
        width={180}
        height={180}
      />
      <Heading
        level="h4"
        mb={6}
        mx={12}
        typography={{ xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' }}
      >
        {localiseText('find.oops_these_items_are_out_of_stock')}
      </Heading>
      <Text mb={10} mx={12}>
        {message}
      </Text>
      <StyledButtonWrapper>
        <PrimaryButton width="100%" href={link}>
          {buttonCopy}
        </PrimaryButton>
      </StyledButtonWrapper>
    </Box>
  )
}
