import { gql } from '@moonpig/web-core-graphql'
import { CallToActionFragment } from '../CtaAction/fragment'

export const ModuleCardFragment = gql`
  ${CallToActionFragment}

  fragment ModuleCard on ModuleCard {
    image {
      url
      description
      dimensions {
        width
        height
      }
    }
    video {
      url
      description
      contentType
    }
    title
    subtitle
    text
    pills
    ctas {
      ...CallToAction
    }
  }
`
