import React from 'react'
import { useLuxLabel, useLuxMeasure } from '@moonpig/web-core-utils'
import { Region } from '@moonpig/web-core-types'
import {
  ProductModalProvider,
  RecentlyViewedProvider,
  FavouritesProvider,
  PageTypeEnum,
} from '@moonpig/web-shared-products'
import { useFlags } from '@moonpig/web-explore-flags'
import { Landing } from '../../components/Landing'
import { PageContent, ContentModule } from '../../types'
import { useLuxAddExperimentData } from '../../utils'

type LandingPageProps = {
  modules: ContentModule[]
  region: Region
}

export const LandingPage: PageContent<LandingPageProps> = ({ modules }) => {
  const flags = useFlags()

  const clientSideRoutingEnabled = false
  const luxAbTestCustomData = [
    {
      name: 'clientSideRouting',
      value: clientSideRoutingEnabled.toString(),
    },
  ]
  useLuxLabel('Landing Page')
  useLuxMeasure(clientSideRoutingEnabled)
  useLuxAddExperimentData(luxAbTestCustomData)

  return (
    <RecentlyViewedProvider>
      <FavouritesProvider
        location="landing"
        enableToastNotification={flags['enable-fav-toast-notification']}
      >
        <ProductModalProvider
          context={{
            location: 'landing',
            pageType: PageTypeEnum.LANDING,
            flags,
          }}
        >
          <Landing modules={modules} />
        </ProductModalProvider>
      </FavouritesProvider>
    </RecentlyViewedProvider>
  )
}

LandingPage.getInitialProps = async ({ modules, region }) => ({
  props: {
    modules,
    region,
  },
  pageOptions: {
    tracking: {
      type: 'landing page',
    },
  },
})
