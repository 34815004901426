import React, { FC } from 'react'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { ModuleEmailCaptureFragment } from './__generated__/fragment'
import { Banner } from './Banner'
import { Modal } from './Modal'
import { Module } from '../types'

export const EmailCaptureModule: FC<Module<ModuleEmailCaptureFragment>> = ({
  module,
}) => {
  const { loggedIn } = useLoggedIn()
  return loggedIn ? null : (
    <>
      {['Banner', 'Both'].includes(module.display) ? (
        <Banner {...module} />
      ) : null}
      {['Modal', 'Both'].includes(module.display) ? (
        <Modal {...module} />
      ) : null}
    </>
  )
}
