import { formatPrice } from '@moonpig/web-core-utils'

const STANDARD_CARD_PRICE_CENT_AMOUNT = 399

type FormatBundlePriceArgs = {
  giftCentAmount: number
  currencyCode: string
  locale?: string
}

export const formatBundlePrice = ({
  giftCentAmount,
  currencyCode,
  locale,
}: FormatBundlePriceArgs) => {
  const fullPriceCentAmount = giftCentAmount + STANDARD_CARD_PRICE_CENT_AMOUNT
  const fullPrice = formatPrice(
    fullPriceCentAmount,
    2,
    currencyCode,
    undefined,
    locale,
  )

  const discountedPrice = formatPrice(
    giftCentAmount,
    2,
    currencyCode,
    undefined,
    locale,
  )

  return { fullPrice, discountedPrice }
}
