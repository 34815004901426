import { SelectItemEvent } from '@moonpig/web-core-analytics'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import { useRouter } from '@moonpig/web-core-routing'
import { ModuleMissionButtonsFragment } from './__generated__/fragment'

type ModuleMissionButtons_missionButtons =
  ModuleMissionButtonsFragment['missionButtons'][0]
type ModuleMissionButtons_meta = ModuleMissionButtonsFragment['meta']

type Button = ModuleMissionButtons_missionButtons
type Meta = ModuleMissionButtons_meta

const createExperimentLabel = (meta?: Meta) => {
  const experiment = meta?.experiment
  return experiment?.key && experiment?.value
    ? `${experiment.key} | ${experiment.value}`.toLowerCase()
    : undefined
}

export const useTracking = (buttons: Button[], meta: Meta) => {
  const { params } = useRouter().getCurrentRoute<'content'>()

  const pageLocation = params.parts.length === 0 ? 'home' : 'landing'

  const label = `${pageLocation} | mission buttons | carousel | 1/1`

  const mapItem = (button: Button, index: number) => {
    return {
      index: (index + 1).toString(),
      item_brand: BRAND_LABEL.toLowerCase(),
      item_list_name: label,
      item_list_id: createExperimentLabel(meta),
      item_name: button.title.toLowerCase(),
    }
  }

  const selectItem = (index: number): SelectItemEvent => {
    const itemIndex = `${index + 1}`
    const event = 'select_item'
    const button = buttons[index]

    return {
      event,
      ecommerce: {
        items: [mapItem(button, index)],
      },

      // For GA3 backward compatibility
      event_data: {
        category: 'promotions',
        action: event,
        label: `mission buttons | ${button.title.toLowerCase()} | carousel | 1/1 | ${itemIndex}/${
          buttons.length
        }`,
        interaction: true,
        value: itemIndex,
      },
    }
  }

  const viewList = () => {
    return {
      event: 'view_item_list',
      ecommerce: {
        items: buttons.map(mapItem),
      },
      // For GA3 backward compatibility
      event_data: {
        action: 'view item list',
        category: 'product action',
        label,
        non_interaction: true,
      },
    }
  }

  return { viewList, selectItem }
}
