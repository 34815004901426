/* eslint-disable no-nested-ternary */
import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import {
  Box,
  Container,
  Link,
  Image,
  Text,
  Grid,
  PrimaryButton,
  SecondaryButton,
  SkeletonBox,
} from '@moonpig/launchpad-components'
import { spacingPx } from '@moonpig/launchpad-theme'
import { useIsMobile } from '../../../utils'

const BREAKPOINT_MD = 'md'
const BREAKPOINT_LG = 'lg'

type HeroCta = {
  title: string
  url?: string
  onClick?: () => void
}

const StyledContainer = styled(Container)`
  position: relative;
  color: inherit;

  ${s({
    p: 5,
  })}
`

const StyledHero = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  color: inherit;
  ${s({
    boxShadow: 2,
    borderRadius: 2,
  })}

  ${breakpointUp(BREAKPOINT_MD)} {
    flex-direction: row;
  }
`

const StyledOverlayLink = styled(Link)`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
`

const StyledSkeletonBox = styled(SkeletonBox)`
  position: absolute;
  z-index: 3;
  inset: ${spacingPx(5)};

  ${s({
    boxShadow: 2,
    borderRadius: 2,
  })}
`

const StyledImageContent = styled(Box)`
  flex: 1;
`

const StyledImage = styled(Image)`
  object-fit: cover;
  height: 168px;
  width: 100%;
  display: block;

  ${breakpointUp(BREAKPOINT_MD)} {
    height: 100%;
  }
`

const StyledBoxContent = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${s({
    bgcolor: 'colorBackground01',
  })}

  ${breakpointUp(BREAKPOINT_MD)} {
    ${s({
      bgcolor: 'colorBackgroundHero',
    })}
  }
`

const StyledContent = styled.div`
  text-align: center;
  ${s({
    p: 6,
  })}

  ${breakpointUp(BREAKPOINT_MD)} {
    text-align: left;
    width: 90%;
    ${s({
      p: 8,
    })}
  }

  ${breakpointUp(BREAKPOINT_LG)} {
    text-align: left;
    width: 80%;
  }
`

const StyledContentHeading = styled.h2`
  ${s({
    typography: 'typeDisplay02',
    marginBottom: 4,
    color: 'colorTextHeadline',
  })}

  ${breakpointUp(BREAKPOINT_MD)} {
    ${s({
      typography: { xs: 'typeMobileDisplay01', md: 'typeDesktopDisplay02' },
    })}
  }

  ${breakpointUp(BREAKPOINT_LG)} {
    ${s({
      typography: 'typeDisplay01',
    })}
  }
`

export type Props = {
  title: string
  subtitle?: string
  image: {
    urlHeroPrimary: string
    urlHeroDefault: string
  }
  isLoading?: boolean
  ctas?: {
    primary?: HeroCta
    secondary?: HeroCta
  }
}

const PrimaryCta: FC<{
  title: string
  url?: string
  onClick?: () => void
}> = ({ title, url, onClick }) => (
  <Box width={{ xs: '100%', lg: '85%', xl: '70%' }} zIndex={2}>
    <PrimaryButton
      href={url}
      onClick={onClick}
      role={onClick ? 'button' : 'link'}
      width="100%"
    >
      {title}
    </PrimaryButton>
  </Box>
)

const SecondaryCta: FC<{
  title: string
  url?: string
  onClick?: () => void
}> = ({ title, url, onClick }) => (
  <Box width={{ xs: '100%', lg: '85%', xl: '70%' }} zIndex={2}>
    <SecondaryButton
      href={url}
      onClick={onClick}
      role={onClick ? 'button' : 'link'}
      width="100%"
    >
      {title}
    </SecondaryButton>
  </Box>
)

export const TextAndImageContainedHero: FC<Props> = ({
  title,
  subtitle,
  ctas,
  image,
  isLoading = false,
  ...rest
}) => {
  const isMobile = useIsMobile()

  const { primary, secondary } = ctas || /* istanbul ignore next */ {
    primary: undefined,
    secondary: undefined,
  }

  return (
    <StyledContainer
      limitWidth
      {...rest}
      data-testid="hero-with-text-and-image-contained"
    >
      {
        /* istanbul ignore next */ isLoading && (
          <StyledSkeletonBox
            colours={
              isMobile
                ? /* istanbul ignore next */ {
                    background: 'colorBackground02',
                    effect: 'colorBorder03',
                  }
                : {
                    // no replacement
                    background: 'colorBackground05',
                    effect: 'colorPrimary01',
                  }
            }
          />
        )
      }
      <StyledOverlayLink
        href={!primary?.onClick ? primary?.url : undefined}
        onClick={primary?.onClick}
        role={primary?.onClick ? 'button' : 'link'}
        aria-hidden
      />
      <StyledHero aria-busy={isLoading} data-testid="web-browse-homepage-hero">
        <StyledBoxContent>
          <StyledContent>
            <StyledContentHeading>{title}</StyledContentHeading>
            <Text as="p" color="colorTextHeadline">
              {subtitle}
            </Text>
            <Grid
              rowGap={secondary ? 4 : 5}
              justifyContent={{ xs: 'center', lg: 'left' }}
            >
              {primary && (
                <PrimaryCta
                  title={primary.title}
                  url={primary.url}
                  onClick={primary.onClick}
                />
              )}
              {secondary && (
                <SecondaryCta
                  title={secondary.title}
                  url={secondary.url}
                  onClick={secondary.onClick}
                />
              )}
            </Grid>
          </StyledContent>
        </StyledBoxContent>
        <StyledImageContent>
          <StyledImage
            src={{
              webp: image.urlHeroPrimary,
              default: image.urlHeroDefault,
            }}
            lazyLoad={false}
            aria-hidden
            alt={title}
          />
        </StyledImageContent>
      </StyledHero>
    </StyledContainer>
  )
}
