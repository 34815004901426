import React, { FC } from 'react'
import { Container, Heading, Grid, Box } from '@moonpig/launchpad-components'
import { createLinkHref } from '@moonpig/web-core-link'
import { createPromoClickEvent } from '@moonpig/web-core-analytics'
import { ModuleBlogPostsListing, BlogTile } from '../../types'
import { BlogPostTile } from '../../components/BlogPostTile'

type TrackingData = { moduleIndex: number; moduleType: string }

export const BlogPostsListingModule: FC<
  ModuleBlogPostsListing & { trackingData: TrackingData }
> = ({ title, category, items, trackingData }) => {
  const urlCategory = category
    ?.toLowerCase()
    .replace(/ /g, '-')
    .replace('&', 'and')

  const urlRegex = new RegExp(`/uk/blog/${urlCategory}/`, 'i')

  const filterItems = (item: BlogTile) => {
    return item.url.match(urlRegex)
  }

  const filteredItems = category ? items?.filter(filterItems) : items

  return (
    <Container limitWidth>
      <Heading level="h2" textAlign="center" mb={10} as="h1">
        {category || title}
      </Heading>
      <Grid gap pb={8}>
        {filteredItems &&
          filteredItems.map((item, index) => {
            const linkHref = createLinkHref({
              url: item.url,
              trackEvent: createPromoClickEvent({
                title: item.title,
                ctaTitle: item.subtitle,
                moduleType: trackingData.moduleType,
                moduleIndex: trackingData.moduleIndex,
                elementIndex: index,
              }),
            })
            return (
              <Box
                width={{ xs: 1, sm: 1 / 2, md: 1 / 3, lg: 1 / 4 }}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Box width="100%" height="100%" alignContent="center">
                  <BlogPostTile href={linkHref} {...item} />
                </Box>
              </Box>
            )
          })}
      </Grid>
    </Container>
  )
}
