import { BundleProduct } from '../types'

export const generateCloseModalEvent = (gift: BundleProduct) => {
  return {
    event: 'select_content',
    content_data: {
      content_type: 'home | bundle pdp | close modal | modal',
      item_id: `bundle | ${gift.id.toLowerCase()} | ${gift.variantTitle.toLowerCase()}`,
    },
    event_data: {
      action: 'close modal',
      category: 'home | bundle pdp',
      label: `bundle | ${gift.id.toLowerCase()} | ${gift.variantTitle.toLowerCase()}`,
      non_interaction: true,
      value: undefined,
    },
  }
}
