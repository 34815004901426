/* eslint-disable no-shadow */
import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { DateTime } from 'luxon'
import { useStore } from '@moonpig/web-core-stores'
import { RecommendationsCarousel } from '../../components/RecommendationsCarousel'
import { UpcomingOccasionsCarousel } from '../../components/UpcomingOccasionsCarousel/UpcomingOccasionsCarousel'
import {
  GetUpcomingOccasions_reminders as Reminder,
  GetUpcomingOccasions_me_customer_recommendations_source as RecommendationsSource,
} from '../../queries/types-graphql'
import { createHeadingText } from './createHeadingText'
import { PEAK_EVENTS, OCCASIONS_DAYS_TO_DISPLAY } from './constants'
import { useFindLocaleText } from '../../text-localisation'
import { useRecommendationsForCustomerQuery } from './query'
import { ModuleSection } from '../../components/ModuleContainer'
import { Module } from '../types'
import { ModuleRecommendationsForCustomerFragment } from './__generated__/fragment'

const removeEmpty = <T,>(items: (T | null | undefined)[]): T[] =>
  items.filter(Boolean) as T[]

export const RecommendationsForCustomerModule: FC<
  Module<ModuleRecommendationsForCustomerFragment>
> = ({ module, context }) => {
  const localise = useFindLocaleText()
  const { store } = useStore()

  const currentDateTime = DateTime.local({
    zone: store.locale.timeZone,
    locale: store.locale.language,
  })
  const endDateTime = currentDateTime.plus({
    days: OCCASIONS_DAYS_TO_DISPLAY,
  })

  const { reminders, nationalOccasions, recommendations } =
    useRecommendationsForCustomerQuery({
      algorithm: module.algorithm,
      startDate: currentDateTime,
      endDate: endDateTime,
    })

  const upcomingNationalOccasions = nationalOccasions
    .filter(nationalOccasion => {
      const nationalOccasionDate = DateTime.fromISO(nationalOccasion.nextDate)

      return (
        nationalOccasionDate > currentDateTime &&
        nationalOccasionDate < endDateTime &&
        PEAK_EVENTS.includes(nationalOccasion.occasion)
      )
    })
    .map(
      ({ occasion, nextDate }) =>
        ({
          id: occasion,
          nextDate,
          occasion,
          relationship: null,
        } as Reminder),
    )

  const remindersWithOccasions = [
    ...reminders,
    ...upcomingNationalOccasions,
  ].sort((previousReminder, currentReminder) =>
    previousReminder.nextDate.localeCompare(currentReminder.nextDate),
  )

  const products = removeEmpty(recommendations?.products || [])

  const recommendationType = recommendations?.type || ''

  const recommendationSource =
    recommendations?.source ||
    ({
      reminderId: '',
      orderId: '',
      orderItemId: '',
    } as RecommendationsSource)

  const recommendationReminder = reminders.find(
    reminder => reminder.id === recommendationSource.reminderId,
  )

  const { title: dynamicTitle, subtitle: dynamicSubtitle } = createHeadingText({
    recommendationType,
    recommendationSource,
    recommendationReminder,
    localiseText: localise,
  })

  const getRecommendationCarouselTrackingLabel = () => {
    const reminderOccasion = recommendationReminder?.occasion.toLowerCase()
    const inferred =
      recommendationType === 'Inferred' ? 'ordered_last_year' : ''
    const nationalOccasion = upcomingNationalOccasions.length
      ? upcomingNationalOccasions[0].occasion.toLowerCase()
      : ''
    const defaultOccasion = localise('find.birthday').toLocaleLowerCase()

    return reminderOccasion || inferred || nationalOccasion || defaultOccasion
  }

  const hasRemindersWithOccasions = remindersWithOccasions.length > 0
  const hasProductRecommendations = products.length > 0

  const recommendationsForCustomerDataAvailable =
    hasRemindersWithOccasions || hasProductRecommendations

  return (
    <>
      {recommendationsForCustomerDataAvailable && (
        <ModuleSection
          data-testid="module-recommendations-for-customer"
          padding={{ py: { xs: 6, lg: 8 } }}
          backgroundColor="colorBackground03"
        >
          {hasRemindersWithOccasions && (
            <Box py={{ xs: 6, lg: 8 }}>
              <UpcomingOccasionsCarousel reminders={remindersWithOccasions} />
            </Box>
          )}
          {hasProductRecommendations && (
            <Box py={{ xs: 6, lg: 8 }}>
              <RecommendationsCarousel
                trackingDataProps={{ pageLocation: context.tracking.getPage() }}
                title={dynamicTitle}
                subtitle={dynamicSubtitle}
                products={products}
                trackingLabel={getRecommendationCarouselTrackingLabel()}
              />
            </Box>
          )}
        </ModuleSection>
      )}
    </>
  )
}
