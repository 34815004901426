import React, { FC } from 'react'
import { theme } from '@moonpig/launchpad-theme'
import {
  Flex,
  Box,
  Text,
  Pill,
  Heading,
  Image,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { EmbeddedVideo, PrimaryCta, SecondaryCta } from './components'

type CardProps = {
  text: string
  title: string
  subtitle: string
  image: {
    url: string
    description: string
  }
  video?: {
    url: string
    description: string
  } | null
  pills?: string[] | null
  ctas?: {
    primary?: {
      title: string
      url?: string
      onClick?: () => void
    }
    secondary?: {
      title: string
      url?: string
      onClick?: () => void
    }
  }
}

const StyledImage = styled(Image)`
  display: block;
`

export const Card: FC<CardProps> = ({
  text,
  title,
  subtitle,
  image,
  video,
  pills,
  ctas,
}) => {
  const { primary, secondary } = ctas || /* istanbul ignore next */ {
    primary: undefined,
    secondary: undefined,
  }

  return (
    <Flex
      flexWrap="wrap"
      borderRadius={2}
      overflow="hidden"
      bgcolor={'colorBlack00'}
      boxShadow={2}
    >
      <Box
        width={{
          xs: '100%',
          md: '50%',
        }}
        pr={{ md: 5 }}
        overflow="hidden"
      >
        {video && video.url ? (
          <EmbeddedVideo
            title={video.description}
            poster={image.url}
            url={video.url}
          />
        ) : (
          <StyledImage width="100%" src={image.url} alt={image.description} />
        )}
      </Box>
      <Box
        width={{
          xs: '100%',
          md: '50%',
        }}
        p={5}
      >
        {pills && (
          <Flex flexWrap="wrap" gap={theme.spacing[4]} my={4}>
            {pills.map(pill => (
              <Pill key={pill} label={pill} variant="marketing" />
            ))}
          </Flex>
        )}
        <Heading
          level="h2"
          typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' }}
          mb={0}
        >
          {title}
        </Heading>
        <Heading level="h3" typography="typeBodyLabel">
          {subtitle}
        </Heading>
        <Text>{text}</Text>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          gap={16}
          mt={6}
          mb={4}
        >
          {primary && <PrimaryCta {...primary} />}
          {secondary && <SecondaryCta {...secondary} />}
        </Flex>
      </Box>
    </Flex>
  )
}
