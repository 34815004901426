import { gql } from '@moonpig/web-core-graphql'

export const ModuleTabbedProductListsFragment = gql`
  fragment ModuleTabbedProductLists on ModuleTabbedProductLists {
    __typename
    title
    productLists {
      __typename
      url
      title
      subtitle
      departments
      keywords
      facetDetails {
        group
        key
      }
      promotionId
      sponsoredProducts {
        internallyPromoted
        productKey
      }
    }
  }
`
