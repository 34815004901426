import React, { FC } from 'react'
import { Text } from '@moonpig/launchpad-components'
import { ModuleBlogPostCategory } from '../../types'

export const BlogPostCategoryModule: FC<ModuleBlogPostCategory> = ({
  category,
}) => (
  <Text color="colorPrimary03" pl={5}>
    {category}
  </Text>
)
