import React, { FC } from 'react'
import { Flex, Pill } from '@moonpig/launchpad-components'
import { BundlePillId } from '../types'
import { BUNDLE_PILLS_CONFIG } from './BundlePillsConfig'
import { useFindLocaleText } from '../../../text-localisation'

const RenderPill = (pillId: BundlePillId) => {
  const localiseText = useFindLocaleText()
  const { variant, Icon } = BUNDLE_PILLS_CONFIG[pillId]
  const label = localiseText(`find.bundle.item_pill_${pillId}`)
  return (
    <Pill
      key={pillId}
      icon={Icon && <Icon />}
      label={label}
      variant={variant}
    />
  )
}

export const BundlePills: FC<{
  pills: BundlePillId[]
}> = ({ pills, ...rest }) => (
  <Flex
    data-testid="mp-find-bundle-pills"
    flexWrap="wrap"
    gap={10}
    justifyContent={{ xs: 'center', md: 'left' }}
    mb={5}
    {...rest}
  >
    {pills.map(pillId => RenderPill(pillId))}
  </Flex>
)
