import React, { FC } from 'react'
import { Link, Image } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { formatTitle } from './Button'

type ButtonProps = {
  title: string
  linkHref: string
  imageUrl:
    | string
    | {
        default: string
        webp: string
      }
}

const StyledImage = styled(Image)`
  border-radius: 100%;

  ${s({
    width: {
      xs: '80px',
      md: '100px',
    },
    border: 3,
    borderColor: 'colorBlack00',
    boxShadow: { xs: 2, md: 0 },
  })}
`

const StyledImageWrapper = styled.div`
  position: relative;
  line-height: 0;
  transform: translateY(0);
  transition: 200ms cubic-bezier(0.56, 0.1, 0.34, 0.91) transform;

  ${s({
    mb: 4,
  })}

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: white;
    display: block;
    position: absolute;
    border-radius: 100%;
    inset: 0;
    opacity: 0;
    z-index: -1;
    transition: 200ms cubic-bezier(0.56, 0.1, 0.34, 0.91) opacity;

    ${s({
      boxShadow: { xs: 0, md: 2 },
      bgcolor: 'colorBlack00',
    })}
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 135px;
  text-align: center;

  ${s({
    my: 3,
    pt: 5,
    pb: 2,
    px: 4,
    borderRadius: 2,
    typography: 'typeBodyLabel',
    color: 'colorTextHeadline',
  })}

  &:hover {
    text-decoration: none;

    ${s({
      color: 'colorInteractionTextLink',
    })}

    ${StyledImageWrapper} {
      transform: translateY(-5px);

      &::before {
        opacity: 1;
      }
    }
  }
`

export const ImageButton: FC<ButtonProps> = ({ title, linkHref, imageUrl }) => (
  <StyledLink href={linkHref}>
    <StyledImageWrapper>
      <StyledImage src={imageUrl} alt={title} aria-hidden />
    </StyledImageWrapper>
    {formatTitle(title)}
  </StyledLink>
)
