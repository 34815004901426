import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { Box, Image, Container } from '@moonpig/launchpad-components'
import { ContentModule } from '../../types'
import { BlogCategoriesButtonsModule } from '../../modules/BlogCategoriesButtons'
import { BlogPostsListingModule } from '../../modules/BlogPostsListing'
import { mapModule } from '../../modules/map-modules'
import { createTracking } from '../../analytics/tracking'

type TrackingData = { moduleIndex: number; moduleType: string }

type BlogHomeProps = {
  modules: ContentModule[]
}

const StyledImage = styled(Image)`
  width: 100%;
  overflow: hidden;
`

const renderModule = ({
  module,
  key,
  trackingData,
}: {
  module: ContentModule
  key: number
  trackingData: TrackingData
}) => {
  switch (module.type) {
    case 'ModuleBlogHomepageImage':
      if (module.image?.url) {
        return (
          <Container key={key} limitWidth>
            <Box
              textAlign="center"
              maxWidth="600px"
              mx="auto"
              pb={{ xs: 4, md: 6 }}
            >
              <StyledImage
                src={module.image?.url}
                alt={module.image?.description}
              />
            </Box>
          </Container>
        )
      }
      return null
    case 'ModuleBlogPostsCategories':
      return (
        <BlogCategoriesButtonsModule
          key={key}
          trackingData={trackingData}
          {...module}
        />
      )
    case 'ModuleBlogPostsListing':
      return (
        <BlogPostsListingModule
          key={key}
          trackingData={trackingData}
          {...module}
        />
      )
    case 'ModuleBreadcrumbs':
    case 'ModuleHeading':
      return mapModule(module, {
        index: key,
        tracking: createTracking({ page: 'blog home' }),
      })
    default:
      return null
  }
}

export const BlogHome: FC<BlogHomeProps> = ({ modules }) => (
  <Box>
    {modules.map((module, index) =>
      renderModule({
        module,
        key: index,
        trackingData: {
          moduleIndex: index,
          moduleType: module.type,
        },
      }),
    )}
  </Box>
)
