import React, { FC, useEffect, useState } from 'react'
import { createPromoClickEvent } from '@moonpig/web-core-analytics'
import { createLinkHref } from '@moonpig/web-core-link'
import { ModuleCTAStrip } from '../../types'
import { CtaStrip } from '../../components/CtaStrip'

type TrackingData = { moduleIndex: number; moduleType: string }

export const HomepageCTABannerVariantModule: FC<{
  module: ModuleCTAStrip
  trackingData: TrackingData
}> = ({ module: { text, backgroundImage, cta, banner }, trackingData }) => {
  const [linkHref, setLinkHref] = useState('')

  useEffect(() => {
    setLinkHref(
      createLinkHref({
        url: cta.url,
        trackEvent: createPromoClickEvent({
          title: text,
          ctaTitle: cta.title,
          ...trackingData,
        }),
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cta.url])

  return (
    <CtaStrip
      compact={banner}
      title={text}
      backgroundImageUrl={backgroundImage ? backgroundImage.url : undefined}
      iconImageUrl=""
      cta={{ title: cta.title, url: linkHref }}
    />
  )
}
