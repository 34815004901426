import styled from 'styled-components'
import { BundlePillsConfig } from '../types'
import { IconBolt } from './pill-icons/IconBolt'
import { IconMoonpig } from './pill-icons/IconMoonpig'
import { IconShopaholic } from './pill-icons/IconShopaholic'

const ICON_WIDTH = '1rem'
const ICON_HEIGHT = '1rem'

export const BUNDLE_PILLS_CONFIG: BundlePillsConfig = {
  favourite: {
    variant: 'marketing',
    Icon: styled(IconShopaholic)`
      width: ${ICON_WIDTH};
      height: ${ICON_HEIGHT};
    `,
  },
  'selling-fast': {
    variant: 'marketing',
    Icon: styled(IconBolt)`
      width: ${ICON_WIDTH};
      height: ${ICON_HEIGHT};
    `,
  },
  exclusive: {
    variant: 'marketing',
    Icon: styled(IconMoonpig)`
      width: ${ICON_WIDTH};
      height: ${ICON_HEIGHT};
    `,
  },
  new: {
    variant: 'marketing',
  },
}
