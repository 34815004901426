import React from 'react'
import { ContentModule, PageContent } from '../../types'
import { Info } from '../../components/Info'

export type InfoPageProps = {
  modules: ContentModule[]
}

export const InfoPage: PageContent<InfoPageProps> = ({ modules }) => (
  <Info modules={modules} />
)

InfoPage.getInitialProps = async ({ modules }) => ({
  props: { modules },
  pageOptions: {},
})
