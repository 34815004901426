import React, { FC } from 'react'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  Heading,
  Text,
  PrimaryButton,
  Container,
} from '@moonpig/launchpad-components'

type CtaProps = {
  url?: string
  title: string
} & StyledContentProps

type FullWidthHeroProps = {
  /** Hero heading */
  title: string
  /** Hero subtitle */
  subtitle?: string
  /** Hero background image */
  imageUrl: string
  /** Cta url and text */
  cta?: CtaProps
  /** Determines whether styling is offer or default */
  offer?: boolean
}

type StyledContentProps = {
  offer?: boolean
}

const StyledHeroImage = styled.div<{ imageUrl: string }>`
  padding-bottom: 50%;
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-size: cover;
  background-position: center center;

  ${breakpoint('md')} {
    padding-bottom: 0;
    height: 320px;
  }
`

const StyledHeroContentWrapper = styled.div`
  ${s({ mt: { xs: -6, lg: 0 } })}
  ${breakpoint('lg')} {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
`

const StyledHeroContent = styled.div<StyledContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${s({
    px: 6,
    maxWidth: { lg: '440px', xl: '480px' },
    minHeight: { lg: '188px' },
    py: 8,
    bgcolor: 'colorBackground06',
    boxShadow: 1,
  })}
  text-align: center;
`

const StyledHero = styled.div`
  position: relative;
`

const Cta: FC<CtaProps> = ({ url, title }) => (
  <PrimaryButton href={url}>{title}</PrimaryButton>
)

export const FullWidthHero: FC<FullWidthHeroProps> = ({
  title,
  subtitle,
  imageUrl,
  cta,
  offer,
}) => (
  <StyledHero data-testid="hero-full-width">
    <StyledHeroImage
      data-testid="find-full-bleed-hero-image"
      imageUrl={imageUrl}
    />
    <StyledHeroContentWrapper>
      <Container limitWidth>
        <StyledHeroContent>
          <Heading
            level="h2"
            mb={subtitle || cta ? 6 : 0}
            color={offer ? 'colorInteractionTextLink' : 'colorTextHeadline'}
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {title}
          </Heading>
          {subtitle && (
            <Text
              as="p"
              mb={cta ? 6 : 0}
              color="colorTextAlternate"
              typography={
                offer
                  ? /* istanbul ignore next */ 'typeDisplay05'
                  : 'typeBodyText'
              }
              data-testid="find-full-bleed-hero-subtitle"
            >
              {subtitle}
            </Text>
          )}
          {cta && <Cta {...cta} offer={offer} />}
        </StyledHeroContent>
      </Container>
    </StyledHeroContentWrapper>
  </StyledHero>
)
