import { gql } from '@moonpig/web-core-graphql'

export const LinkActionFragment = gql`
  fragment LinkAction on LinkAction {
    __typename
    url
  }
`

export const AddMembershipToBasketActionFragment = gql`
  fragment AddMembershipToBasketAction on AddMembershipToBasketAction {
    __typename
    membershipType
  }
`

export const CallToActionFragment = gql`
  ${LinkActionFragment}
  ${AddMembershipToBasketActionFragment}

  fragment CallToAction on CTA {
    title
    action {
      __typename
      ...LinkAction
      ...AddMembershipToBasketAction
    }
  }
`
