import { ProductPartsFragment } from './__generated__/GetGiftsQuery'
import { BUNDLE_GIFT_SKUS } from './bundleGiftSkus'
import { BundlePillId } from './types'

const getPillsByProductId = (id: string): BundlePillId[] => {
  const skuConfig = BUNDLE_GIFT_SKUS.find(
    ({ sku }) => sku.toLowerCase() === id.toLowerCase(),
  )

  return skuConfig ? skuConfig.pills : []
}

export const createFormattedProduct = (product: ProductPartsFragment) => ({
  id: product.id,
  title: product.title,
  isLandscape: product.isLandscape,
  sku: product.masterVariant.sku || undefined,
  department: product.category.department,
  categoryId: product.category.id,
  categoryName: product.category.name,
  centAmount: product.masterVariant.price.centAmount,
  currencyCode: product.masterVariant.price.currencyCode,
  price: product.masterVariant.price.amount,
  variantKey: product.masterVariant.sizeId.toString(),
  variantTitle: product.masterVariant.title,
  imageUrls: product.masterVariant.images.map(({ url }) => url),
  description: product.description,
  pills: getPillsByProductId(product.id),
})
