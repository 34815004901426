import { gql } from '@moonpig/web-core-graphql'
import { PRODUCT_FIELDS_FRAGMENT } from './productFields'

export const GetUpcomingOccasionsGQL = gql`
  ${PRODUCT_FIELDS_FRAGMENT}

  query GetUpcomingOccasions(
    $remindersRequest: GetRemindersInput!
    $recommendationsRequest: CustomerRecommendationsRequest!
  ) {
    reminders(input: $remindersRequest) {
      id
      name
      nextDate
      occasion
      relationship
    }
    nationalOccasions: nationalOccasionDates {
      occasion
      nextDate
    }
    me {
      customer {
        id
        recommendations(request: $recommendationsRequest) {
          type
          source {
            reminderId
            orderId
            orderItemId
          }
          products {
            ...ProductFields
          }
        }
      }
    }
  }
`
