import { Occasion } from '@moonpig/web-explore-types-graphql'

export const PEAK_EVENTS: Occasion[] = [
  Occasion.VALENTINES_DAY,
  Occasion.MOTHERS_DAY,
  Occasion.FATHERS_DAY,
  Occasion.CHRISTMAS,
]

export const OCCASIONS_DAYS_TO_DISPLAY = 30
