import { Region } from '@moonpig/web-core-types'
import { DynamicHeroResponse } from '../../services/dataInference'

type PipelineOptions = {
  store: Region
  dynamicHeroData?: DynamicHeroResponse
  loggedIn: boolean | null
}

type PipelineStepNext<T> = (modules: T[]) => T[]

export type PipelineStep<T> = (
  options: PipelineOptions,
  next: PipelineStepNext<T>,
) => PipelineStepNext<T>

export const createContentPipeline = <T>(
  options: PipelineOptions,
  steps: PipelineStep<T>[],
): PipelineStepNext<T> => {
  let next: PipelineStepNext<T> = m => m

  steps.forEach(step => {
    next = step(options, next)
  })

  return next
}
