import { useRemindersModalContext } from '@moonpig/web-core-context'
import { isModalLink } from '@moonpig/web-core-link'
import { enrichDeepLink, generateTrackingId, isDeepLink } from './deepLink'

type MapLinkActionToCTAInput = {
  title: string
  url: string
}

type MapLinkActionToCTAResult = {
  title: string
  url?: string
  onClick?: () => void
}

type UseLinkActionInput = {
  onClick?: (
    e:
      | { type: 'reminder'; title: string }
      | { type: 'link'; title: string }
      | { type: 'deep-link'; title: string; trackingId: string },
  ) => void
}

export type UseLinkAction = (input: UseLinkActionInput) => {
  mapLinkActionToCTA: (
    input: MapLinkActionToCTAInput,
  ) => MapLinkActionToCTAResult
}

export const useLinkAction: UseLinkAction = ({ onClick }) => {
  const reminders = useRemindersModalContext()

  const mapLinkActionToCTA = ({ title, url }: MapLinkActionToCTAInput) => {
    if (isModalLink(url)) {
      return {
        title,
        onClick: () => {
          onClick?.({ type: 'reminder', title })
          reminders.setShow(true)
        },
      }
    }

    if (isDeepLink(url)) {
      const trackingId = generateTrackingId()
      return {
        title,
        url: enrichDeepLink(url, trackingId),
        onClick: () => {
          onClick?.({ type: 'deep-link', title, trackingId })
        },
      }
    }

    return {
      title,
      url,
      onClick: () => {
        onClick?.({ type: 'link', title })
      },
    }
  }

  return {
    mapLinkActionToCTA,
  }
}
