import React, { FC, useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import {
  AddedToBasketEvent,
  createViewItemListGAEvent,
  useFavouritesState,
  useHandleFavourite,
} from '@moonpig/web-shared-products'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { ProductCarousel } from '../../components/ProductCarousel'
import { TabCarousel } from '../../components/TabCarousel'
import { ModuleHeader, ModuleSection } from '../../components/ModuleContainer'
import { useFindLocaleText } from '../../text-localisation'
import { ModuleTabbedProductListsFragment } from './__generated__/fragment'
import { Module, ModuleTracking } from '../types'
import {
  ProductListProducts,
  useProductListQuery,
} from '../../queries/ProductList'
import { selectContentGAEvent } from '../../analytics/commonGAEvents'
import { useBasketWrapper } from '../../utils/useBasketWrapper'

type Props = {
  excludeRudeCards?: boolean
} & ModuleTabbedProductListsFragment &
  ModuleTracking

type MapToTrackingInfo = (
  module: ModuleTabbedProductListsFragment,
  tab: ModuleTabbedProductListsFragment['productLists'][0],
  productList: ProductListProducts,
  tracking: ModuleTracking['tracking'],
  product?: { id: string },
) => { listName: string; label: string; itemIndex: number }

const mapToTrackingInfo: MapToTrackingInfo = (
  module,
  tab,
  productList,
  tracking,
  product,
) => {
  const index = productList.findIndex(p => p.id === product?.id)
  const length = productList.length

  const listNameParts = [
    tracking.pageLocation,
    module.title,
    `tabbed carousel`,
    `${tracking.moduleProductListIndex}/${tracking.moduleProductListsLength}`,
    `${tab.title} tab`,
  ]

  const labelParts = product?.id
    ? [...listNameParts, `${index + 1}/${length}`, product.id]
    : listNameParts

  return {
    listName: listNameParts.join(' | ').toLowerCase(),
    label: labelParts.join(' | ').toLowerCase(),
    itemIndex: index,
  }
}

export const TabbedProductListsModule: FC<Module<Props>> = ({
  module,
  context: { backgroundColor },
}) => {
  const localise = useFindLocaleText()
  const buttonText = localise('find.view_category')
  const ariaLabel = localise('find.tab_carousel_title', {
    moduleTitle: module.title,
  })

  const [selectedTab, setSelectedTab] = useState(module.productLists[0])

  const [canQueryRef, canQuery] = useInView({
    threshold: 0,
    rootMargin: '100px',
    triggerOnce: true,
  })

  const [canTrackRef, canTrack] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  const productList = useProductListQuery({
    canQuery,
    departments: selectedTab.departments as DepartmentsEnum[],
    facets: selectedTab.facetDetails,
    keywords: selectedTab.keywords,
    promotionId: selectedTab.promotionId || undefined,
    excludeRudeCards: module.excludeRudeCards,
  })

  const addToBasket = useBasketWrapper()

  const { favourites } = useFavouritesState()
  const handleFavourite = useHandleFavourite({
    tracking: {
      totalCount: productList.length,
      pageLocation: module.tracking.pageLocation,
    },
    removeWithConfirmation: false,
  })

  const handleTabSelectionChanged = useCallback(
    (newSelection: ModuleTabbedProductListsFragment['productLists'][0]) => {
      setSelectedTab(newSelection)
      trackGAEvent(
        selectContentGAEvent({
          type: newSelection.title,
          context: 'tab',
          action: 'select content',
          category: module.tracking.pageLocation,
          pageLocation: module.tracking.pageLocation,
          moduleTitle: module.title,
          moduleIndex: module.tracking.moduleProductListIndex,
          modulesLength: module.tracking.moduleProductListsLength,
          tabbedCarousel: true,
        }),
      )
    },
    [module],
  )

  const handleAddToBasket = useCallback<
    (e: AddedToBasketEvent) => Promise<void>
  >(
    async e => {
      await addToBasket(
        e,
        selectedTab.keywords,
        selectedTab.facetDetails,
        ({ product }) =>
          mapToTrackingInfo(
            module,
            selectedTab,
            productList,
            module.tracking,
            product,
          ),
      )
    },
    [addToBasket, productList, module, selectedTab],
  )

  useEffect(() => {
    if (canTrack && productList.length) {
      const { listName } = mapToTrackingInfo(
        module,
        selectedTab,
        productList,
        module.tracking,
      )
      trackGAEvent(
        createViewItemListGAEvent({
          products: productList,
          label: listName,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canTrack, productList])

  return (
    <ModuleSection
      ref={node => {
        canQueryRef(node)
        canTrackRef(node)
      }}
      data-testid="module-tabbed-product-lists"
      withContainer
      fullWidthOnMobile
      padding={{ py: { xs: 6, lg: 8 } }}
      backgroundColor={backgroundColor}
    >
      <ModuleHeader title={module.title} />
      <TabCarousel
        tabs={module.productLists}
        selectedTab={selectedTab}
        onTabSelectionChanged={handleTabSelectionChanged}
        ariaLabel={ariaLabel}
      />
      {productList.length ? (
        <ProductCarousel
          backgroundColor={backgroundColor}
          title={selectedTab.title}
          url={selectedTab.url}
          productList={productList}
          favourites={favourites}
          buttonText={buttonText}
          onAddToBasket={handleAddToBasket}
          onFavourite={handleFavourite}
          onButtonClick={() => {
            trackGAEvent(
              selectContentGAEvent({
                type: selectedTab.title.toLowerCase(),
                context: 'tabbed carousel',
                action: 'select content',
                category: buttonText.toLowerCase(),
              }),
            )
          }}
          onTrackingInfo={({ product }) =>
            mapToTrackingInfo(
              module,
              selectedTab,
              productList,
              module.tracking,
              product,
            )
          }
        />
      ) : null}
    </ModuleSection>
  )
}
