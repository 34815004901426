import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { ContentModule } from '../../types'
import { BlogPostsListingModule } from '../../modules/BlogPostsListing'
import { mapModule } from '../../modules/map-modules'
import { createTracking } from '../../analytics/tracking'

type BlogCategoryProps = {
  modules: ContentModule[]
}

const renderModule = ({
  module,
  category,
  index,
}: {
  module: ContentModule
  category: string
  index: number
}) => {
  const context = {
    index,
    tracking: createTracking({ page: 'blog category' }),
  }

  switch (module.type) {
    case 'ModuleBlogPostsListing':
      return (
        <BlogPostsListingModule
          key={index}
          trackingData={{
            moduleIndex: index,
            moduleType: module.type,
          }}
          category={category}
          {...module}
        />
      )
    case 'ModuleBreadcrumbs':
    case 'ModuleHeading':
      return mapModule(module, context)
    default:
      return null
  }
}

export const BlogCategory: FC<BlogCategoryProps> = ({ modules }) => {
  let category = ''

  modules.map(module => {
    if (module.type === 'ModuleHeading') {
      category = module.heading
    }
    return null
  })

  return (
    <Box>
      {modules.map((module, index) =>
        renderModule({ module, category, index }),
      )}
    </Box>
  )
}
