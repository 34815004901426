import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import {
  SizingSpacingSystemProps,
  sizingSpacing,
  system as s,
} from '@moonpig/launchpad-system'
import { IconSystemSocialYoutube } from '@moonpig/launchpad-assets'
import { useFindLocaleText } from '../../../text-localisation'

const StyledYoutubeIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${sizingSpacing}
  ${s({ color: 'colorTextBody' })}

  &:active {
    ${s({ color: 'colorInteractionButton' })}
  }

  &:hover,
  &:focus {
    ${s({ color: 'colorInteractionButtonHover' })}
  }
`

type YoutubeIconButtonProps = SizingSpacingSystemProps & {
  /** Click handler - passes event object as an argument */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const YoutubeIconButton: FC<YoutubeIconButtonProps> = ({
  onClick,
  ...props
}) => {
  const localiseText = useFindLocaleText()
  return (
    <StyledYoutubeIconButton
      onClick={onClick}
      aria-label={localiseText('find.play_video')}
      {...props}
    >
      <IconSystemSocialYoutube width="86px" height="72px" aria-hidden />
    </StyledYoutubeIconButton>
  )
}
