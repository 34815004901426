import { gql, useLazyQuery } from '@moonpig/web-core-graphql'
import { useLoggedInGraphQLMutation } from '@moonpig/web-shared-utils'
import {
  MembershipMarketingGetMembershipStateQuery,
  MembershipMarketingAddMembershipToBasketMutation,
  MembershipMarketingAddMembershipToBasketMutationVariables,
} from './__generated__/useAddMembershipToBasketService'

const BasketFragmentGQL = gql`
  fragment MembershipMarketingBasketFragment on Basket {
    id
    totalItems
    membership {
      selected {
        membershipType
      }
      recommended {
        type
      }
    }
  }
`

const AddMembershipToBasketGQL = gql`
  ${BasketFragmentGQL}
  mutation MembershipMarketingAddMembershipToBasket(
    $input: AddMembershipToBasketInput!
  ) {
    addMembershipToBasket(input: $input) {
      __typename
      ... on Basket {
        ...MembershipMarketingBasketFragment
      }
    }
  }
`

const GetMembershipStateGQL = gql`
  ${BasketFragmentGQL}
  query MembershipMarketingGetMembershipState {
    me {
      basket {
        ...MembershipMarketingBasketFragment
      }
      customer {
        id
        membership {
          membershipType
        }
      }
    }
  }
`

export const useAddMembershipToBasketService = () => {
  const [getMembershipState, getMembershipReturn] =
    useLazyQuery<MembershipMarketingGetMembershipStateQuery>(
      GetMembershipStateGQL,
      { errorPolicy: 'all' },
    )

  const [addMembershipToBasket, addMembershipToBasketReturn] =
    useLoggedInGraphQLMutation<
      MembershipMarketingAddMembershipToBasketMutation,
      MembershipMarketingAddMembershipToBasketMutationVariables
    >(AddMembershipToBasketGQL)

  return {
    getMembership: {
      getMembershipState,
      ...getMembershipReturn,
    },
    addMembershipToBasket: {
      addMembershipToBasket,
      ...addMembershipToBasketReturn,
    },
  }
}
