import { trackGAEvent } from '@moonpig/web-core-analytics'
import { createAddToBasketGAEvent } from '@moonpig/web-shared-products'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import type { BundleProduct, TrackingData } from './types'

type BasketEventProps = {
  product: BundleProduct
  basketData: {
    basketId: string
    basketSize: number
  }
  trackingData: TrackingData
}

export const trackBasketEvent = ({
  basketData,
  product,
  trackingData,
}: BasketEventProps) => {
  const { pageLocation, bundle, carousel } = trackingData
  const { basketId, basketSize } = basketData
  const basket = {
    id: basketId,
    size: basketSize,
    action: 'add to cart',
  }
  const currency = product.currencyCode
  const action = 'add to basket'
  const brand = BRAND_LABEL.toLowerCase()
  const itemIndex = bundle.position + 1
  const itemLabel = `${pageLocation} | ${carousel.heading} | carousel | ${
    carousel.position
  }/${carousel.length} | ${itemIndex}/${
    bundle.length
  } | ${product.id.toLowerCase()}`
  const label = `${itemLabel} | ${action}`.toLowerCase()
  const totalValue = product.centAmount / 100

  const item = {
    index: 1,
    item_brand: brand,
    item_name: product.title,
    item_category: product.categoryName,
    item_category2: getParentDepartment(product.department),
    item_category3: 'in stock',
    item_category4: 'non group card',
    item_category5: undefined,
    item_list_id: `bundle | ${product.id.toLowerCase()}`,
    item_id: product.id.toLowerCase(),
    item_list_name: itemLabel,
    item_variant: product.variantTitle.toLowerCase(),
    quantity: 1,
    price: totalValue,
  }

  trackGAEvent(
    createAddToBasketGAEvent({
      basket,
      currency,
      products: [item],
      label,
      totalValue,
      productIndex: itemIndex,
    }),
  )
}
