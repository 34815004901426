import React, { FC } from 'react'
import {
  Container,
  SecondaryButton,
  Grid,
  Box,
} from '@moonpig/launchpad-components'
import { createLinkHref } from '@moonpig/web-core-link'
import { createPromoClickEvent } from '@moonpig/web-core-analytics'
import { ModuleBlogPostsCategories } from '../../types'

type TrackingData = { moduleIndex: number; moduleType: string }

const LAYOUT: {
  [key: number]: { xs: number; lg: number; xl: number } | undefined
} = {
  '4': { xs: 1, lg: 1 / 2, xl: 1 / 4 },
  '6': { xs: 1 / 2, lg: 1 / 3, xl: 1 / 6 },
}

export const BlogCategoriesButtonsModule: FC<
  ModuleBlogPostsCategories & { trackingData: TrackingData }
> = ({ items, trackingData }) => {
  return (
    <Container limitWidth>
      <Grid pb={8}>
        {items &&
          items.map((item, index) => {
            if (item.category && item.url) {
              const linkHref = createLinkHref({
                url: item.url,
                trackEvent: createPromoClickEvent({
                  title: item.category,
                  moduleType: trackingData.moduleType,
                  moduleIndex: trackingData.moduleIndex,
                  elementIndex: index,
                }),
              })
              return (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  width={(Array.isArray(items) && LAYOUT[items.length]) || 1}
                >
                  <SecondaryButton
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    width="100%"
                    height="100%"
                    href={linkHref}
                  >
                    {item.category}
                  </SecondaryButton>
                </Box>
              )
            }
            return null
          })}
      </Grid>
    </Container>
  )
}
