import { useStoreId } from '@moonpig/web-core-stores'
import { useRouter } from '@moonpig/web-core-routing'
import { CTA } from './types'
import {
  trackAddMembershipToCart,
  trackStartShopping,
} from './AddMembershipToBasketAction/analytics'
import { useAddMembershipToBasketService } from './AddMembershipToBasketAction/useAddMembershipToBasketService'
import { useFindLocaleText } from '../../text-localisation'
import { useAlertDispatcher } from '../../contexts/alert/Context'
import { AddMembershipToBasketActionFragment } from './__generated__/fragment'

export const addMembershipToBasketActionToCta = (
  cta: {
    title: string
    action: AddMembershipToBasketActionFragment
  },
  {
    addMembershipToBasket,
    getMembership,
  }: ReturnType<typeof useAddMembershipToBasketService>,
  store: ReturnType<typeof useStoreId>,
  locale: ReturnType<typeof useFindLocaleText>,
  alertDispatch: ReturnType<typeof useAlertDispatcher>,
  router: ReturnType<typeof useRouter>,
): CTA | undefined => {
  if (!getMembership.loading && !getMembership.data && !getMembership.called) {
    getMembership.getMembershipState()
    return undefined
  }

  if (getMembership.loading) {
    return undefined
  }

  if (
    getMembership.data?.me.basket?.membership.selected ||
    getMembership.data?.me.customer?.membership
  ) {
    return {
      title: locale('find.start_shopping'),
      onClick: () => {
        trackStartShopping()
        router.push({
          name: 'search',
          params: { region: store },
        })
      },
    }
  }

  return {
    title: cta.title,
    onClick: () => {
      trackAddMembershipToCart()
      addMembershipToBasket
        .addMembershipToBasket({
          variables: { input: { membershipType: cta.action.membershipType } },
        })
        .then(() => {
          alertDispatch.openAlert({
            variant: 'success',
            message: locale('find.moonpig_plus.added_to_basket'),
          })
          getMembership.refetch()
        })
    },

    url: undefined,
  }
}
