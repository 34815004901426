import React, { FC } from 'react'
import { styled, breakpoint, breakpointDown } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

import {
  Image,
  ImageProps,
  Heading,
  Text,
  PrimaryButton,
  Box,
  Link,
  Grid,
  SecondaryButton,
} from '@moonpig/launchpad-components'

type HeroProps = {
  /** Hero heading */
  title: string
  subtitle?: string
  image: {
    url: ImageProps['src']
    alt: string
    lazyLoad?: boolean
    width?: number
    height?: number
  }
  /** Cta url and text */
  ctas?: {
    primary?: {
      url?: string
      title: string
      onClick?: () => void
    }
    secondary?: {
      url?: string
      title: string
      onClick?: () => void
    }
  }
  /** Determines whether styling is offer or default */
  offer?: boolean
}

type StyledContentProps = {
  offer?: boolean
}

const BREAKPOINT_1 = 'md'
const BREAKPOINT_2 = 'lg'
const HERO_HEIGHT_TABLET_DEKSTOP = 278
const IMAGE_WIDTH = 1350
const IMAGE_HEIGHT = 394
const ASPECT_RATIO_MOBILE = 1 / 2
const ASPECT_RATIO_TABLET_DESKTOP = IMAGE_WIDTH / IMAGE_HEIGHT
const IMAGE_CONTAINER_WIDTH_DESKTOP =
  HERO_HEIGHT_TABLET_DEKSTOP * ASPECT_RATIO_TABLET_DESKTOP
const CONTENT_OVERLAY_DESKTOP = 180

const StyledHero = styled.div`
  position: relative;
  color: inherit;

  ${breakpoint(BREAKPOINT_2)} {
    height: ${HERO_HEIGHT_TABLET_DEKSTOP}px;
    display: flex;
    flex-direction: row-reverse;
  }
`

const StyledOverlayLink = styled(Link)`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
`

const StyledImageWrapper = styled.div`
  position: relative;
  overflow: hidden;

  ${breakpointDown(BREAKPOINT_1)} {
    padding-bottom: ${ASPECT_RATIO_MOBILE * 100}%;
  }

  ${breakpoint(BREAKPOINT_1)} {
    height: 320px;
  }

  ${breakpoint(BREAKPOINT_2)} {
    width: 75%;
    height: ${HERO_HEIGHT_TABLET_DEKSTOP}px;
  }
`

const StyledImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(12%);

  ${breakpointDown(BREAKPOINT_2)} {
    bottom: 0;
    left: 0;
  }

  ${breakpoint(BREAKPOINT_1)} {
    transform: translateX(calc(-30vw + 270px));
  }

  ${breakpoint(BREAKPOINT_2)} {
    transform: translateX(calc(-40vw + 470px));
  }

  @media (min-width: 1100px) {
    transform: translate(0, 0);
  }

  ${s({
    width: {
      [BREAKPOINT_2]: `${IMAGE_CONTAINER_WIDTH_DESKTOP}px`,
    },
    height: {
      [BREAKPOINT_2]: `${HERO_HEIGHT_TABLET_DEKSTOP}px`,
    },
  })}

  img {
    height: 100%;

    ${breakpointDown(BREAKPOINT_2)} {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`

const StyledContent = styled.div<StyledContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  ${({ theme }) =>
    s({
      position: { xs: 'relative', [BREAKPOINT_2]: 'absolute' },
      left: 0,
      top: { [BREAKPOINT_2]: `${theme.spacing[6]}px` },
      bottom: { [BREAKPOINT_2]: `${theme.spacing[6]}px` },
      width: { [BREAKPOINT_2]: `calc(25% + ${CONTENT_OVERLAY_DESKTOP}px)` },
      boxShadow: 1,
      bgcolor: 'colorBackground06',
      p: 6,
      mx: { xs: 6, [BREAKPOINT_2]: 0 },
      mt: { xs: -6, [BREAKPOINT_2]: 0 },
    })}
`

const PrimaryCta: FC<{
  title: string
  url?: string
  onClick?: () => void
}> = ({ title, url, onClick }) => (
  <PrimaryButton
    href={url}
    onClick={onClick}
    role={onClick ? 'button' : 'link'}
  >
    {title}
  </PrimaryButton>
)

const SecondaryCta: FC<{
  title: string
  url?: string
  onClick?: () => void
}> = ({ title, url, onClick }) => (
  <SecondaryButton
    href={url}
    onClick={onClick}
    role={onClick ? 'button' : 'link'}
  >
    {title}
  </SecondaryButton>
)

export const Hero: FC<HeroProps> = ({
  title,
  subtitle,
  image,
  ctas,
  offer,
}) => (
  <StyledHero>
    {ctas && (
      <StyledOverlayLink
        href={!ctas?.primary?.onClick ? ctas?.primary?.url : undefined}
        onClick={ctas?.primary?.onClick}
        role={ctas?.primary?.onClick ? 'button' : 'link'}
        aria-hidden
      />
    )}
    <StyledImageWrapper>
      <StyledImage>
        <Image
          aria-hidden
          src={image.url}
          alt={image.alt}
          loading={image.lazyLoad ? 'lazy' : 'eager'}
          width={image.width}
          height={image.height}
        />
      </StyledImage>
    </StyledImageWrapper>
    <StyledContent offer={offer}>
      <Heading
        level="h2"
        textAlign="center"
        mb={subtitle || ctas ? 6 : 0}
        color={offer ? 'colorInteractionTextLink' : 'colorTextHeadline'}
        typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay04' }}
      >
        {title}
      </Heading>
      {subtitle && (
        <Text
          as="p"
          typography={offer ? 'typeDisplay05' : 'typeBodyText'}
          color="colorTextAlternate"
          mb={ctas ? 6 : 0}
        >
          {subtitle}
        </Text>
      )}
      <Grid colGap={8} rowGap={6} justifyContent="center">
        {ctas?.primary && (
          <Box zIndex={2}>
            <PrimaryCta
              title={ctas.primary.title}
              url={ctas.primary.url}
              onClick={ctas.primary.onClick}
            />
          </Box>
        )}
        {ctas?.secondary && (
          <Box zIndex={2}>
            <SecondaryCta
              title={ctas.secondary.title}
              url={ctas.secondary.url}
              onClick={ctas.secondary.onClick}
            />
          </Box>
        )}
      </Grid>
    </StyledContent>
  </StyledHero>
)
