import React, { FC, useEffect, useState } from 'react'
import { Image, Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { useFindLocaleText } from '../../../text-localisation'
import { PlayIconButton } from './PlayIconButton'

const GA_DOMAIN = 'browse'

type EmbeddedVideoProps = {
  url: string
  title: string
  poster: string
  playNow?: boolean
}

const StyledWrapper = styled(Box)`
  position: relative;
  padding-bottom: 60.25%;
  height: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledVideo = styled.video`
  display: block;
  max-width: 100%;
  object-fit: cover;
  outline: 0;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const StyledThumbnailBox = styled(Box)`
  button {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
  }
  img {
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
`

export const EmbeddedVideo: FC<EmbeddedVideoProps> = ({
  url,
  title,
  poster,
  playNow = false,
}) => {
  const [playVideo, setPlayVideo] = useState(false)
  const [isStarted, setIsStarted] = useState(false)
  const localiseText = useFindLocaleText()

  useEffect(() => {
    setPlayVideo(playNow)
  }, [playNow])

  const playHandler = (e: React.SyntheticEvent) => {
    if (!isStarted) {
      const video = e.target as HTMLVideoElement
      trackGAEvent({
        event: 'video_start',
        video_data: {
          video_duration: video.duration,
          video_milestone: '0%',
          video_provider: 'html5 video player',
          video_status: 'video start',
          video_title: url.substring(url.lastIndexOf('/') + 1),
          video_url: url,
        },
        event_data: {
          category: GA_DOMAIN,
          action: 'video start | 0%',
          label: url.substring(url.lastIndexOf('/') + 1),
          non_interaction: true,
        },
      })
      setIsStarted(true)
    }
  }

  const endedHandler = (e: React.SyntheticEvent) => {
    const video = e.target as HTMLVideoElement
    trackGAEvent({
      event: 'video_complete',
      video_data: {
        video_duration: video.duration,
        video_milestone: '100%',
        video_provider: 'html5 video player',
        video_status: 'video complete',
        video_title: url.substring(url.lastIndexOf('/') + 1),
        video_url: url,
      },
      event_data: {
        category: GA_DOMAIN,
        action: 'video complete',
        label: url.substring(url.lastIndexOf('/') + 1),
        non_interaction: true,
      },
    })
  }

  return (
    <Box maxWidth="800px" margin="0 auto">
      <StyledWrapper>
        {playVideo ? (
          <StyledVideo
            muted
            webkit-playsinline
            preload="metadata"
            autoPlay={playVideo}
            controls
            title={title}
            aria-label={localiseText('find.video-label')}
            onEnded={endedHandler}
            onPlay={playHandler}
          >
            <source src={url} />
          </StyledVideo>
        ) : (
          <>
            <StyledThumbnailBox position="absolute" width="100%" height="100%">
              <Box bgcolor={'rgba(0, 0, 0, 0.3)'}>
                <Image width="100%" height="100%" src={poster} alt={title} />
              </Box>
              <PlayIconButton
                width="100%"
                height="100%"
                onClick={() => {
                  setPlayVideo(true)
                }}
              />
            </StyledThumbnailBox>
          </>
        )}
      </StyledWrapper>
    </Box>
  )
}
