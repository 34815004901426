import React, { FC, useState } from 'react'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { styled } from '@moonpig/launchpad-utils'
import {
  Text,
  Box,
  TertiaryButton,
  Modal,
  Heading,
  PrimaryButton,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { useLanguage, useStoreId } from '@moonpig/web-core-stores'
import { BundleImage } from '../BundleImage/BundleImage'
import { BundleProduct, TrackingData } from '../types'
import { BundleDetails } from '../BundleDetails/BundleDetails'
import { generateCloseModalEvent } from './generateCloseModalEvent'
import { BundlePills } from '../BundlePills/BundlePills'
import { useFindLocaleText } from '../../../text-localisation'
import { formatBundlePrice } from '../formatBundlePrice/formatBundlePrice'
import { trackSelectEvent } from './trackSelectItemGAEvent'

const StyledBundlePills = styled(BundlePills)`
  justify-content: center;
`

const StyledTileContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 17.875rem;
  height: 100%;
  overflow: hidden;
  ${s({
    p: 6,
    pt: 8,
    bgcolor: 'colorBackground01',
    boxShadow: 3,
    borderRadius: 2,
  })}
`

const StyledLinkWrapper = styled.a`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const StyledTertiaryButton = styled(TertiaryButton)`
  position: relative;
  ${s({
    typography: 'typeButtonLabel',
  })}
  &:hover,
  &:focus {
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
`

const StyledFullPrice = styled(Text)`
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  ${s({
    color: 'colorBlack70',
    mr: 4,
  })}
`

export const BundleTile: FC<{
  gift: BundleProduct
  trackingData: TrackingData
  showDiscountedPrice?: boolean
}> = ({ gift, trackingData, showDiscountedPrice = true }) => {
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const localiseText = useFindLocaleText()
  const title = localiseText('find.bundle.item_title', gift.title)
  const { fullPrice, discountedPrice } = formatBundlePrice({
    giftCentAmount: gift.centAmount,
    currencyCode: gift.currencyCode,
    locale: useLanguage(),
  })
  const trackEventProps = {
    gift,
    trackingData,
    labelData: trackingData,
    region: useStoreId(),
  }

  return (
    <>
      <StyledTileContainer>
        <Box textAlign="center" width={1}>
          <BundleImage
            giftImageSrc={gift.imageUrls[0]}
            giftTitle={gift.title}
          />
          <Heading
            level="h3"
            mt={6}
            typography={{
              xs: 'typeMobileDisplay04',
              md: 'typeDesktopDisplay05',
            }}
          >
            {title}
          </Heading>
        </Box>
        <Box textAlign="center" width={1}>
          <Box mb={6}>
            {showDiscountedPrice ? (
              <>
                <StyledFullPrice typography="typeBodyLead">
                  {fullPrice}
                </StyledFullPrice>
                <Text
                  typography={{
                    xs: 'typeMobileDisplay03',
                    md: 'typeDesktopDisplay05',
                  }}
                >
                  {discountedPrice}
                </Text>
              </>
            ) : (
              <>
                <Text>From </Text>
                <Text typography="typeBodyLabel">{fullPrice}</Text>
              </>
            )}
          </Box>
          <StyledBundlePills pills={gift.pills} />
          <StyledLinkWrapper
            title={localiseText(
              'find.bundle.item_view_details_additional_info',
              gift.title,
            )}
            href="#"
            onClick={e => {
              e.preventDefault()
              setModalOpen(true)
              trackSelectEvent(trackEventProps)
            }}
          />
          <StyledTertiaryButton
            onClick={() => {
              setModalOpen(true)
              trackSelectEvent(trackEventProps)
            }}
          >
            {localiseText('find.bundle.item_view_details')}
          </StyledTertiaryButton>
        </Box>
      </StyledTileContainer>
      <Modal
        label={gift.title}
        isOpen={modalOpen}
        onDismiss={() => {
          setModalOpen(false)
          trackGAEvent(generateCloseModalEvent(gift))
        }}
        hasCloseButton
        closeButtonLabel={`Close ${gift.title}`}
      >
        <BundleDetails
          gift={gift}
          title={title}
          trackingData={trackingData}
          formattedFullPrice={fullPrice}
          formattedDiscountPrice={showDiscountedPrice ? discountedPrice : ''}
          onAddToBasketError={() => {
            setModalOpen(false)
            setErrorModalOpen(true)
          }}
        />
      </Modal>
      <Modal
        label="Something went wrong, please close the modal and try again"
        isOpen={errorModalOpen}
        onDismiss={() => {
          window.location.reload()
        }}
      >
        <Box textAlign="center">
          <Heading
            level="h2"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            It’s Not You, it’s Us!
          </Heading>

          <Text as="p">Oops, something went wrong…please try again.</Text>
          <PrimaryButton
            onClick={() => {
              window.location.reload()
            }}
          >
            Let’s Go
          </PrimaryButton>
        </Box>
      </Modal>
    </>
  )
}
