export enum EventType {
  SelectContent = 'select_content',
  Impression = 'impression',
  Error = 'error',
}

type GAEventProps = {
  type: string
  context: string
  variationId?: string
  action: string
  category: string
  nonInteraction?: boolean
  itemId?: string
  errorCode?: number
  errorReason?: string
  eventLabel?: string
  contentType?: string
  pageLocation?: string
  moduleTitle?: string
  moduleIndex?: number
  modulesLength?: number
  tabbedCarousel?: boolean
}

export type SelectContentGAEventProps = GAEventProps

export type ImpressionGAEventProps = GAEventProps

type WithErrorRequired<T, K extends keyof T> = T & Required<Pick<T, K>>

export type ErrorGAEventProps = WithErrorRequired<
  GAEventProps,
  'errorCode' | 'errorReason'
>

const GAEvent = ({
  event,
  type,
  context,
  variationId = '',
  action,
  category,
  nonInteraction = true,
  itemId = '',
  errorReason = '',
  errorCode,
  eventLabel,
  contentType,
}: GAEventProps & { event: EventType }) => {
  return {
    event,
    content_data: {
      content_type:
        contentType || `${category} | ${action} | ${context} | ${type}`,
      item_id: itemId || variationId,
    },
    event_data: {
      action,
      category,
      label:
        eventLabel ||
        `${context} | ${type}${
          variationId && itemId === ''
            ? ` | ${variationId}`
            : /* istanbul ignore next */ ''
        }`,
      non_interaction: nonInteraction,
      value: undefined,
    },
    discount_data: undefined,
    ecommerce: undefined,
    error_data: errorReason
      ? {
          id: errorCode || /* istanbul ignore next */ 510,
          message: errorReason,
        }
      : undefined,
  }
}

export const selectContentGAEvent = (props: SelectContentGAEventProps) => {
  let eventLabel
  let contentType
  const {
    pageLocation,
    moduleTitle,
    moduleIndex,
    modulesLength,
    tabbedCarousel,
  } = props

  if (pageLocation && moduleTitle && moduleIndex && modulesLength) {
    const carouselType = `${
      tabbedCarousel ? 'tabbed ' : /* istanbul ignore next */ ''
    }carousel`
    eventLabel =
      `${moduleTitle} | ${carouselType} | ${moduleIndex}/${modulesLength} | ${props.type} ${props.context}`.toLowerCase()
    contentType = `${pageLocation} | ${eventLabel}`.toLowerCase()
  }
  return GAEvent({
    ...props,
    eventLabel,
    contentType,
    event: EventType.SelectContent,
  })
}

export const impressionGAEvent = (props: ImpressionGAEventProps) => {
  return GAEvent({ ...props, event: EventType.Impression })
}

export const errorGAEvent = (props: ErrorGAEventProps) => {
  return GAEvent({ ...props, event: EventType.Error })
}
