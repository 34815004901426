import { DateTime } from 'luxon'
import { useQuery } from '@moonpig/web-core-graphql'
import {
  GetUpcomingOccasionsQuery,
  GetUpcomingOccasionsQueryVariables,
} from '../../queries/__generated__/GetUpcomingOccasions'
import { GetUpcomingOccasionsGQL } from '../../queries/getUpcomingOccasions'

const REMINDERS_TO_TAKE = 5

type Input = {
  algorithm: string
  startDate: DateTime
  endDate: DateTime
}

export const useRecommendationsForCustomerQuery = ({
  algorithm,
  startDate,
  endDate,
}: Input) => {
  const { data } = useQuery<
    GetUpcomingOccasionsQuery,
    GetUpcomingOccasionsQueryVariables
  >(GetUpcomingOccasionsGQL, {
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      remindersRequest: {
        startDate: startDate.toISODate(),
        endDate: endDate.toISODate(),
        take: REMINDERS_TO_TAKE,
      },
      recommendationsRequest: {
        legacyId: '',
        algorithm: algorithm || 'control',
      },
    },
  })

  const { reminders, nationalOccasions, me } = data || {
    reminders: [],
    nationalOccasions: [],
    me: { customer: null },
  }

  return {
    reminders,
    nationalOccasions,
    recommendations: me.customer?.recommendations,
  }
}
