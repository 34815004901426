import React, { FC } from 'react'
import {
  Grid,
  Box,
  Image,
  Heading,
  PrimaryButton,
  Flex,
  Text,
} from '@moonpig/launchpad-components'
import { createPromoClickEvent } from '@moonpig/web-core-analytics'
import { createLinkHref } from '@moonpig/web-core-link'
import { ColumnWidthVariant } from '@moonpig/web-explore-types-graphql'
import { ModuleContainer } from '../../components/ModuleContainer'
import { ModuleTwoColumnPlacard } from '../../types'

type TrackingData = { moduleIndex: number; moduleType: string }

const mapColumnWidth = (columnWidthVariant: ColumnWidthVariant) => {
  switch (columnWidthVariant) {
    case 'LeftWider':
      return {
        leftColumnWidth: { sm: 1, md: 1 / 2, lg: 3 / 4 },
        rightColumnWidth: { sm: 1, md: 1 / 2, lg: 1 / 4 },
      }
    case 'RightWider':
      return {
        leftColumnWidth: { sm: 1, md: 1 / 2, lg: 1 / 4 },
        rightColumnWidth: { sm: 1, md: 1 / 2, lg: 3 / 4 },
      }
    default:
      return {
        leftColumnWidth: { xs: 1, lg: 1 / 2 },
        rightColumnWidth: { xs: 1, lg: 1 / 2 },
      }
  }
}

export const TwoColumnPlacardModule: FC<
  ModuleTwoColumnPlacard & {
    trackingData: TrackingData
  }
> = ({
  title,
  unsafeBody,
  body,
  image,
  columnWidthVariant,
  imagePlacement,
  twoColumnPlacardCta,
  trackingData,
}) => {
  const { leftColumnWidth, rightColumnWidth } =
    mapColumnWidth(columnWidthVariant)

  const { urlDefault, urlPrimary, description } = image

  const linkHref =
    twoColumnPlacardCta &&
    createLinkHref({
      url: twoColumnPlacardCta.url,
      trackEvent: createPromoClickEvent({
        title: twoColumnPlacardCta.title,
        ...trackingData,
      }),
    })

  return (
    <ModuleContainer>
      {imagePlacement === 'ImageLeft' ? (
        <Grid gap>
          <Box width={leftColumnWidth}>
            <Image
              src={{ default: urlDefault, webp: urlPrimary }}
              alt={description}
              width="100%"
              lazyLoad
            />
          </Box>
          <Box width={rightColumnWidth}>
            <Heading
              level="h3"
              typography={{
                xs: 'typeMobileDisplay04',
                md: 'typeDesktopDisplay05',
              }}
            >
              {title}
            </Heading>
            {body && <Text>{body}</Text>}
            {!body && unsafeBody && (
              /* eslint-disable no-console, react/no-danger */
              <div dangerouslySetInnerHTML={{ __html: unsafeBody }} />
            )}
          </Box>
        </Grid>
      ) : (
        <Grid gap>
          <Box width={leftColumnWidth}>
            <Heading
              level="h3"
              typography={{
                xs: 'typeMobileDisplay04',
                md: 'typeDesktopDisplay05',
              }}
            >
              {title}
            </Heading>
            {body && <Text>{body}</Text>}
            {!body && unsafeBody && (
              /* eslint-disable no-console, react/no-danger */
              <div dangerouslySetInnerHTML={{ __html: unsafeBody }} />
            )}
          </Box>
          <Box width={rightColumnWidth}>
            <Image
              src={{ default: urlDefault, webp: urlPrimary }}
              alt={description}
              width="100%"
              lazyLoad
            />
          </Box>
        </Grid>
      )}
      {linkHref && (
        <Flex mt={6}>
          <PrimaryButton href={linkHref} mx="auto">
            {twoColumnPlacardCta.title}
          </PrimaryButton>
        </Flex>
      )}
    </ModuleContainer>
  )
}
