import { CallToActionFragment } from './__generated__/fragment'
import { useAddMembershipToBasketAction } from './AddMembershipToBasketAction'
import { useLinkAction } from './LinkAction'

export type UseMapCallToActionInput = {
  ctas: CallToActionFragment[] | null
  onClick?: (
    e:
      | { type: 'reminder'; title: string }
      | { type: 'link'; title: string }
      | { type: 'deep-link'; title: string; trackingId: string },
  ) => void
}

export type UseMapCallToActionResult = {
  primary?: {
    title: string
    url?: string
    onClick?: () => void
  }
  secondary?: {
    title: string
    url?: string
    onClick?: () => void
  }
}

export type UseMapCallToAction = (
  input: UseMapCallToActionInput,
) => UseMapCallToActionResult

export const useMapCallToActions: UseMapCallToAction = ({ ctas, onClick }) => {
  const { mapLinkActionToCTA } = useLinkAction({ onClick })
  const { mapAddMembershipToBasketActionToCTA } =
    useAddMembershipToBasketAction({})

  const mapCTA = (cta: CallToActionFragment | null | undefined) => {
    switch (cta?.action.__typename) {
      case 'LinkAction':
        return mapLinkActionToCTA({
          title: cta.title,
          url: cta.action.url,
        })
      case 'AddMembershipToBasketAction':
        return mapAddMembershipToBasketActionToCTA({
          title: cta.title,
          membership: cta.action.membershipType,
        })
      default:
        return undefined
    }
  }

  if (!ctas) {
    return {}
  }

  return {
    primary: mapCTA(ctas[0]),
    secondary: mapCTA(ctas[1]),
  }
}
