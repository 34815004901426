import { Box, Container, Flex, Heading } from '@moonpig/launchpad-components'
import React, { FC, PropsWithChildren } from 'react'

type ModuleContainerProps = {
  title?: string
  subtitle?: string
  centerAlignText?: boolean
  leftAlignOnMobile?: boolean
}

const ModuleContainer: FC<PropsWithChildren<ModuleContainerProps>> = ({
  title,
  subtitle,
  centerAlignText,
  leftAlignOnMobile,
  children,
}) => {
  const defaultClass = leftAlignOnMobile ? 'homepage-ab-test-left-mobile' : ''

  return (
    <Container limitWidth>
      {(title || subtitle) && (
        <Flex justifyContent="center">
          <Box
            width={{ md: 3 / 4, lg: 2 / 3, xl: 1 / 2 }}
            pb={4}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className={
              centerAlignText ? defaultClass : 'homepage-ab-test-center'
            }
            textAlign="center"
          >
            {title && (
              <Heading
                level="h2"
                typography={{
                  xs: 'typeMobileDisplay03',
                  md: 'typeDesktopDisplay04',
                }}
              >
                {title}
              </Heading>
            )}
            {subtitle && <p>{subtitle}</p>}
          </Box>
        </Flex>
      )}
      {children}
    </Container>
  )
}

export { ModuleContainer }
