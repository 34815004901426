import React from 'react'
import { ModuleErrorBoundary } from '@moonpig/web-shared-products'
import { BreadcrumbsModule, ModuleBreadcrumbsGenerated } from './Breadcrumbs'
import { HeadingModule, ModuleHeadingGenerated } from './Heading'
import { TilesModule, ModuleTilesGenerated } from './Tiles'
import { InfoModule, ModuleInfoGenerated } from './Info'
import { SEOModule, ModuleSEOGenerated } from './SEO'
import { EmailCaptureModule, ModuleEmailCaptureGenerated } from './EmailCapture'
import {
  HeroModule,
  ModuleDynamicHeroGenerated,
  ModuleHeroGenerated,
} from './Hero'
import {
  MissionButtonsModule,
  ModuleMissionButtonsGenerated,
} from './MissionButtons'
import { PlacardsModule, ModulePlacardsGenerated } from './Placards'
import { USPCarouselModule, ModuleUspCarouselGenerated } from './USPCarousel'
import { CardModule, ModuleCardGenerated } from './Card'
import { TabbedProductListsModule } from './TabbedProductLists/TabbedProductListsModule'
import { ModuleContext, ModuleTracking } from './types'
import { ModuleTabbedProductListsGenerated } from './TabbedProductLists'

export type ModuleTabbedProductListsWithTracking =
  ModuleTabbedProductListsGenerated & ModuleTracking

type Modules =
  | ModuleBreadcrumbsGenerated
  | ModuleHeadingGenerated
  | ModuleDynamicHeroGenerated
  | ModuleHeroGenerated
  | ModuleMissionButtonsGenerated
  | ModuleTilesGenerated
  | ModuleInfoGenerated
  | ModuleSEOGenerated
  | ModuleEmailCaptureGenerated
  | ModulePlacardsGenerated
  | ModuleUspCarouselGenerated
  | ModuleCardGenerated
  | ModuleTabbedProductListsWithTracking

const map = (module: Modules, context: ModuleContext) => {
  switch (module.__typename) {
    case 'ModuleHeading':
      return <HeadingModule module={module} context={context} />

    case 'ModuleBreadcrumbs':
      return <BreadcrumbsModule module={module} context={context} />

    case 'ModuleTiles':
      return <TilesModule module={module} context={context} />

    case 'ModuleInfo':
      return <InfoModule module={module} context={context} />

    case 'ModuleSEO':
      return <SEOModule module={module} context={context} />

    case 'ModuleEmailCapture':
      return <EmailCaptureModule module={module} context={context} />

    case 'ModuleDynamicHero':
    case 'ModuleHero':
      return <HeroModule module={module} context={context} />

    case 'ModuleMissionButtons':
      return <MissionButtonsModule module={module} context={context} />

    case 'ModulePlacards':
      return <PlacardsModule module={module} context={context} />

    case 'ModuleUspCarousel':
      return <USPCarouselModule module={module} context={context} />

    case 'ModuleCard':
      return <CardModule module={module} context={context} />

    case 'ModuleTabbedProductLists':
      return <TabbedProductListsModule module={module} context={context} />

    /* istanbul ignore next */
    default:
      return null
  }
}

export const mapModule = (module: Modules, context: ModuleContext) => {
  const component = map(module, context)

  /* istanbul ignore else */
  if (component) {
    return (
      <ModuleErrorBoundary key={context.index} type={module.__typename}>
        {component}
      </ModuleErrorBoundary>
    )
  }

  /* istanbul ignore next */
  return null
}
