import { gql } from '@moonpig/web-core-graphql'

export const GetDraftDesignsGQL = gql`
  query GetDraftDesigns($filter: DesignsFilterInput) {
    designs(filter: $filter) {
      ... on Designs {
        items {
          ... on Design {
            id
            productKey
            createdAt
            updatedAt
            state
            images {
              __typename
              ... on SceneImages {
                images {
                  title
                  variants {
                    small {
                      url
                      width
                      height
                    }
                  }
                }
              }
              ... on RendererServiceError {
                message
              }
            }
          }
        }
      }
    }
  }
`

export const GetDraftDesignProductLookupGQL = gql`
  query GetDraftDesignProductLookup($productIds: [String!]!) {
    productLookup(productIds: $productIds) {
      products {
        __typename
        id
        title
        isFavourited

        category {
          name
          department
        }

        masterVariant {
          key
          title
          sku
          price {
            centAmount
            currencyCode
            amount
          }
          inStock
          sizeId
          images {
            url
          }
        }
      }
    }
  }
`
