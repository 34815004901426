import React, { FC } from 'react'
import {
  Box,
  PrimaryButton,
  SecondaryButton,
} from '@moonpig/launchpad-components'
import { CTA_MIN_WIDTH } from './constants'

export const PrimaryCta: FC<{
  title: string
  url?: string
  onClick?: () => void
}> = ({ title, url, onClick }) => (
  <Box
    minWidth={{
      xs: '100%',
      md: CTA_MIN_WIDTH,
    }}
    zIndex={2}
  >
    <PrimaryButton
      href={url}
      onClick={onClick}
      role={url ? 'link' : 'button'}
      width="100%"
    >
      {title}
    </PrimaryButton>
  </Box>
)

export const SecondaryCta: FC<{
  title: string
  url?: string
  onClick?: () => void
}> = ({ title, url, onClick }) => (
  <Box
    minWidth={{
      xs: '100%',
      md: CTA_MIN_WIDTH,
    }}
    zIndex={2}
  >
    <SecondaryButton
      href={url}
      onClick={onClick}
      role={url ? 'link' : 'button'}
      width="100%"
    >
      {title}
    </SecondaryButton>
  </Box>
)
