import {
  Box,
  Grid,
  Heading,
  Text,
  RichTextContainer,
} from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { Markup } from '@moonpig/web-shared-components'
import { ModuleSection } from '../../components/ModuleContainer'
import { Module } from '../types'
import { ModuleSEOFragment } from './__generated__/fragment'

const StyledRichTextContainer = styled(RichTextContainer)`
  padding: 0;
`

export const SEOModule: FC<Module<ModuleSEOFragment>> = ({
  module: { items },
  context: { backgroundColor },
}) => (
  <ModuleSection
    data-testid="module-seo"
    padding={{ py: { xs: 6, lg: 8 } }}
    withContainer
    backgroundColor={backgroundColor}
  >
    <Grid gap>
      {items.map(({ title, text, richText }, index) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          width={{ xs: 1, md: 1 / 2 }}
        >
          <Heading
            level="h3"
            mb={{ xs: 4, md: 5 }}
            typography={{
              xs: 'typeMobileDisplay04',
              md: 'typeDesktopDisplay05',
            }}
          >
            {title}
          </Heading>
          {richText ? (
            <StyledRichTextContainer>
              <Markup htmlString={richText} />
            </StyledRichTextContainer>
          ) : (
            <Text as="p" mb="0">
              {text}
            </Text>
          )}
        </Box>
      ))}
    </Grid>
  </ModuleSection>
)
