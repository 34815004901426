import { ContentModule } from '../../../types'
import { MODULE_TILES } from '../constants'
import { PipelineStep } from '../pipeline'

export const removeExperimentModulesStep: PipelineStep<ContentModule> =
  (_, next) => modules => {
    const processedModules = next(modules)
    const modulesToRemove = [MODULE_TILES]

    return processedModules.filter(m => !modulesToRemove.includes(m.type))
  }
