import { getParentDepartment } from '@moonpig/web-core-utils'
import { BundleProduct, GAItem } from './types'

export const createGAItem = ({
  product,
  index,
  itemListId,
  itemListName,
  itemBrand,
}: {
  product: BundleProduct
  index: number
  itemListId?: string
  itemListName: string
  itemBrand: 'moonpig' | 'greetz'
}): GAItem => {
  return {
    item_brand: itemBrand,
    item_name: product.title.toLowerCase(),
    item_id: product.id.toLowerCase(),
    item_variant: product.variantTitle.toLowerCase(),
    item_category: product.categoryName.toLowerCase(),
    item_category2: getParentDepartment(product.department)
      .toLowerCase()
      .replace(/_/g, ' '),
    item_category3: 'in stock',
    item_category4: 'non group card',
    item_category5: undefined,
    index,
    item_list_name: itemListName,
    item_list_id: itemListId,
    price: product.price,
    quantity: 1,
  }
}
