import {
  createProductItemGAEvent,
  ProductItemEventEnum,
} from '@moonpig/web-shared-products'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { BundleProduct, TrackingData } from '../types'

export const trackSelectEvent = ({
  gift,
  labelData,
}: {
  gift: BundleProduct
  labelData: TrackingData
}) => {
  const { carousel, bundle } = labelData
  const listName = `home | ${carousel.heading.toLowerCase()} | carousel | ${
    carousel.position
  }/${carousel.length}`
  const label = `${listName} | ${bundle.position + 1}/${
    bundle.length
  } | ${gift.id.toLowerCase()}`
  const variant = gift.variantTitle
  const product = {
    id: gift.id,
    title: gift.title,
    category: {
      name: gift.categoryName,
      department: gift.department,
    },
    masterVariant: {
      title: gift.title,
      price: {
        centAmount: gift.centAmount,
        fractionDigits: 100,
        currencyCode: gift.currencyCode,
      },
      inStock: true,
    },
  }

  trackGAEvent(
    createProductItemGAEvent({
      eventType: ProductItemEventEnum.SELECT_ITEM,
      product,
      index: bundle.position,
      label,
      listName,
      variant,
    }),
  )
}
