import { useMutation } from '@moonpig/web-core-graphql'
import { Dispatch } from 'react'
import { RegisterEmailGQL } from '../../../queries/registerEmail'
import { RegisterEmailMutation } from '../../../queries/__generated__/RegisterEmail'
import {
  successImpression,
  track,
  selectContent,
  genericError,
} from '../tracking/events'

export const INVALID_EMAIL_MESSAGE = 'Please enter a valid email address.'
export const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please try again.'

const EmailRegistrationSucceeded = () => ({
  type: 'EMAIL_REGISTERED_SUCCEEDED' as const,
})

const EmailRegistrationFailed = (message: string) => ({
  type: 'EMAIL_REGISTERED_FAILED' as const,
  payload: {
    message,
  },
})

export type EmailRegistrationSucceeded = ReturnType<
  typeof EmailRegistrationSucceeded
>
export type EmailRegistrationFailed = ReturnType<typeof EmailRegistrationFailed>

export const useActions = (
  dispatch: Dispatch<EmailRegistrationSucceeded | EmailRegistrationFailed>,
  isModal: boolean,
) => {
  const [registerEmailMutation] =
    useMutation<RegisterEmailMutation>(RegisterEmailGQL)

  const registerEmail = async (email: string): Promise<void> => {
    track(selectContent({ isModal }))

    try {
      const { data } = await registerEmailMutation({
        variables: {
          input: {
            emailAddress: email,
          },
        },
      })

      /* istanbul ignore else */
      if (data) {
        switch (data.registerEmail.__typename) {
          case 'RegisterEmailSuccess':
            track(successImpression({ isModal }))
            dispatch(EmailRegistrationSucceeded())
            return
          case 'InvalidEmailError':
            track(genericError({ isModal, errorReason: INVALID_EMAIL_MESSAGE }))
            dispatch(EmailRegistrationFailed(INVALID_EMAIL_MESSAGE))
        }
      }
    } catch (err) {
      track(genericError({ isModal, errorReason: err.message }))
      dispatch(EmailRegistrationFailed(GENERIC_ERROR_MESSAGE))
    }
  }

  return {
    registerEmail,
  }
}
