import { useQuery } from '@moonpig/web-core-graphql'
import { Box, SimpleCarousel } from '@moonpig/launchpad-components'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { breakpointUp, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { trackGAEvent, useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { useInView } from 'react-intersection-observer'
import { ModulePlaceholderVariant } from '@moonpig/web-explore-types-graphql'
import {
  createViewItemListGAEvent,
  createProductItemGAEvent,
  createPersonaliseGAEvent,
  ProductItemEventEnum,
  CardProductTile,
} from '@moonpig/web-shared-products'
import { useStoreId } from '@moonpig/web-core-stores'
import { useFindLocaleText } from '../../../text-localisation'
import {
  GetDraftDesignProductLookupGQL,
  GetDraftDesignsGQL,
} from '../../../queries/getDraftDesigns'
import type {
  GetDraftDesignsQuery,
  GetDraftDesignsQueryVariables,
} from '../../../queries/__generated__/GetDraftDesigns'
import { ModuleContainer } from '../../../components/ModuleContainer'
import {
  GetDraftDesignProductLookupQuery,
  GetDraftDesignProductLookupQueryVariables,
} from '../../../queries/__generated__/GetDraftDesigns'
import {
  GetDraftDesignProductLookup_productLookup_products,
  GetDraftDesigns_designs_Designs_items,
} from '../../../queries/types-graphql'

const StyledModuleContainer = styled.section`
  ${s({
    textAlign: 'left',
    py: { xs: 6, lg: 8 },
  })}

  ${breakpointUp('md')} {
    text-align: center;
  }
`

type DraftDesignProduct = {
  design: GetDraftDesigns_designs_Designs_items
  product: GetDraftDesignProductLookup_productLookup_products
}

const getDesignImageUrl = (
  design: GetDraftDesigns_designs_Designs_items,
  productImageUrl: string,
): string => {
  if (design.images.__typename === 'RendererServiceError') {
    return productImageUrl
  }

  return design.images.images[0].variants.small.url
}

const getDesignEditUrl = (
  productKey: string,
  sku: string | null,
  sizeId: string,
  store: string,
): string => {
  return `/${store}/customise/product/${productKey}/${sizeId}/?productVariantSku=${sku}`
}

export type ContinueCreationModuleProps = {
  variant: ModulePlaceholderVariant.Drafts
  trackingDataProps?: {
    pageLocation: string
  }
}

export const ContinueCreationModuleModule: FC<ContinueCreationModuleProps> = ({
  trackingDataProps,
}) => {
  const localiseText = useFindLocaleText()
  const localizedTitle = localiseText('find.continue_creation_title')
  const { trackGAEventOnce } = useTrackGAEventOnce()

  const { data } = useQuery<
    GetDraftDesignsQuery,
    GetDraftDesignsQueryVariables
  >(GetDraftDesignsGQL, {
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        limit: 5,
      },
    },
  })

  const trackViewItemList = useCallback(
    (designItems: DraftDesignProduct[]) => {
      let label = `${localizedTitle} | carousel`
      if (trackingDataProps) {
        const { pageLocation } = trackingDataProps
        label = `${pageLocation} | ${localizedTitle} | carousel`
      }
      const products = designItems.map(({ product }) => product)
      trackGAEventOnce(
        createViewItemListGAEvent({
          products: products.map(product => ({
            ...product,
            isSponsored: false,
          })),
          label,
        }),
      )
    },
    [localizedTitle, trackGAEventOnce, trackingDataProps],
  )

  const trackSelectItem = useCallback(
    (
      product: GetDraftDesignProductLookup_productLookup_products,
      itemCount: number,
      index: number,
    ) => {
      let listName = ''
      if (trackingDataProps) {
        const { pageLocation } = trackingDataProps
        listName = `${pageLocation} | ${localizedTitle} | carousel`
      }
      const label = `${listName} | ${index + 1}/${itemCount} | ${product.id}`

      trackGAEvent(
        createProductItemGAEvent({
          eventType: ProductItemEventEnum.SELECT_ITEM,
          product,
          index,
          label,
          listName,
          variant: product.masterVariant.title,
        }),
      )
    },
    [localizedTitle, trackingDataProps],
  )

  const trackPersonaliseEvent = useCallback(
    (product: GetDraftDesignProductLookup_productLookup_products) => {
      const productData = {
        id: product.id,
        name: product.title,
        price: product.masterVariant.price.centAmount,
        quantity: 1,
        category: product.category.name,
        variant: product.masterVariant.title,
      }
      trackGAEvent(
        createPersonaliseGAEvent({
          componentName: 'continue your creation carousel',
          productData,
        }),
      )
    },
    [],
  )

  const [continueCreationCarouselRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
    delay: 1000,
  })

  const designsResult = data?.designs
  const designsResultSuccess = designsResult?.__typename === 'Designs'

  const productKeys = designsResultSuccess
    ? designsResult.items.map(design => design.productKey)
    : []

  const { data: productData } = useQuery<
    GetDraftDesignProductLookupQuery,
    GetDraftDesignProductLookupQueryVariables
  >(GetDraftDesignProductLookupGQL, {
    ssr: false,
    fetchPolicy: 'network-only',
    skip: productKeys.length === 0,
    variables: {
      productIds: productKeys,
    },
  })

  const designItems: DraftDesignProduct[] = useMemo(
    () =>
      designsResult?.__typename !== 'Designs'
        ? []
        : designsResult.items.reduce<DraftDesignProduct[]>((acc, design) => {
            const product = productData?.productLookup.products.find(
              p => p.id === design.productKey.toLowerCase(),
            )

            if (product !== undefined && design) {
              acc.push({
                product,
                design,
              })
            }
            return acc
          }, []),
    [designsResult, productData?.productLookup.products],
  )

  useEffect(() => {
    /* istanbul ignore if Unable to trigger intersection observer */
    if (designItems && inView) {
      trackViewItemList(designItems)
    }
  }, [inView, designItems, trackViewItemList])

  const store = useStoreId()

  if (designItems.length === 0) {
    return null
  }

  return (
    <StyledModuleContainer
      data-testid={'placeholder-continue-creation'}
      ref={continueCreationCarouselRef}
    >
      <ModuleContainer centerAlignText leftAlignOnMobile title={localizedTitle}>
        <SimpleCarousel gap={0} aria-label={localizedTitle}>
          {designItems.map(
            ({ design, product }: DraftDesignProduct, index: number) => {
              return (
                <Box
                  key={design.id}
                  py={{ xs: 4, md: 5 }}
                  pr={{
                    xs: index === designItems.length - 1 ? 6 : 4,
                    md: 5,
                  }}
                  pl={{ xs: index === 0 ? 6 : 4, md: 5 }}
                  height="100%"
                >
                  <Box
                    width={{
                      xs: '36vw',
                      md: '248px',
                    }}
                    height="100%"
                    display="flex"
                  >
                    <CardProductTile
                      image={{
                        src: getDesignImageUrl(
                          design,
                          product.masterVariant.images[0].url,
                        ),
                        alt: localiseText('find.continue_creation_item_alt', {
                          itemTitle: product.title,
                        }),
                      }}
                      href={getDesignEditUrl(
                        design.productKey.toLowerCase(),
                        product.masterVariant.sku,
                        product.masterVariant.sizeId.toString(),
                        store,
                      )}
                      onClick={() => {
                        trackSelectItem(product, designItems.length, index)
                        trackPersonaliseEvent(product)
                      }}
                    />
                  </Box>
                </Box>
              )
            },
          )}
        </SimpleCarousel>
      </ModuleContainer>
    </StyledModuleContainer>
  )
}
