export const upcomingOccasionsV2LoadEvent = {
  event: 'impression',
  content_data: {
    content_type: 'reminders | shop for reminder load | upcoming occasions v2',
  },
  ecommerce: undefined,
  error_data: undefined,
  event_data: {
    action: 'shop for reminder load',
    category: 'reminders',
    label: 'upcoming occasions v2',
    non_interaction: true,
    value: 1,
  },
}

export const upcomingOccasionsV2ImpressionEvent = {
  event: 'impression',
  content_data: {
    content_type: 'reminders | shop for reminder | upcoming occasions v2',
  },
  ecommerce: undefined,
  error_data: undefined,
  event_data: {
    action: 'shop for reminder',
    category: 'reminders',
    label: 'upcoming occasions v2',
    non_interaction: true,
    value: 1,
  },
}

export const upcomingOccasionV2ShopForReminderEvent = {
  ecommerce: null,
  event: 'select_content',
  event_data: {
    action: 'view all reminders',
    category: 'reminders',
    label: 'shop for reminder | upcoming occasions v2',
    non_interaction: true,
    value: '1',
  },
}
