import { gql } from '@moonpig/web-core-graphql'

export const ModuleHeroFragment = gql`
  fragment ModuleHero on ModuleHero {
    title
    subtitle
    heroVariant: variant
    offer
    promotionId
    image {
      urlSmallPrimary: url(width: 1351, quality: 80, format: webp)
      urlSmallDefault: url(width: 1351, quality: 80, format: jpg)
      urlLargePrimary: url(width: 882, height: 882, quality: 80, format: webp)
      urlLargeDefault: url(width: 882, height: 882, quality: 80, format: jpg)
      urlHeroPrimary: url(height: 600, quality: 80, format: webp)
      urlHeroDefault: url(height: 600, quality: 80, format: jpg)
      urlBlogPrimary: url(width: 900, height: 320, quality: 80, format: webp)
      urlBlogDefault: url(width: 900, height: 320, quality: 80, format: jpg)
      description
    }
    ctas {
      title
      url
      action {
        __typename
        ... on LinkAction {
          ...LinkAction
        }
        ... on AddMembershipToBasketAction {
          ...AddMembershipToBasketAction
        }
      }
    }
  }
`

export const ModuleDynamicHeroFragment = gql`
  fragment ModuleDynamicHero on ModuleDynamicHero {
    title
    subtitle
    heroVariant: variant
    offer
    promotionId
    image {
      urlSmallPrimary: url(width: 1351, quality: 80, format: webp)
      urlSmallDefault: url(width: 1351, quality: 80, format: jpg)
      urlLargePrimary: url(width: 882, height: 882, quality: 80, format: webp)
      urlLargeDefault: url(width: 882, height: 882, quality: 80, format: jpg)
      urlHeroPrimary: url(height: 600, quality: 80, format: webp)
      urlHeroDefault: url(height: 600, quality: 80, format: jpg)
      urlBlogPrimary: url(width: 900, height: 320, quality: 80, format: webp)
      urlBlogDefault: url(width: 900, height: 320, quality: 80, format: jpg)
      description
    }
    ctas {
      title
      url
      action {
        __typename
        ... on LinkAction {
          ...LinkAction
        }
        ... on AddMembershipToBasketAction {
          ...AddMembershipToBasketAction
        }
      }
    }
  }
`
