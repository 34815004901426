import React, { FC } from 'react'
import { Image, Flex, Text, ImageProps } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import {
  USP_HEIGHT_DESKTOP,
  USP_HEIGHT_MOBILE,
  USP_WIDTH_DESKTOP,
  USP_IMAGE_SIZE,
} from './constants'

type USPImage = {
  iconImageUrl: ImageProps['src']
  lazyLoad: boolean
  alt: string
}

const StyledCompactUSP = styled(Flex)`
  align-items: center;
  justify-content: center;
  ${s({
    pr: 5,
    py: 5,
    width: '100vw',
    height: `${USP_HEIGHT_MOBILE}px`,
  })}

  ${breakpointUp('md')} {
    ${s({
      width: `${USP_WIDTH_DESKTOP}px`,
      height: `${USP_HEIGHT_DESKTOP}px`,
    })}
  }
`

const StyledIconImage = styled.div`
  flex-shrink: 0;
  ${s({
    ml: 4,
    width: `${USP_IMAGE_SIZE}px`,
    height: `${USP_IMAGE_SIZE}px`,
  })}
`

const StyledCompactUSPText = styled(Flex)`
  flex-direction: column;
  height: auto;
  ${s({
    mx: 3,
    my: 'auto',
  })}
`

type USPProps = {
  /** The title of the USP */
  title: string
  /** The description of the USP */
  description: string
  /** The image details of the USP */
  image?: USPImage
}

export const USP: FC<USPProps> = ({ title, description, image }) => {
  return (
    <>
      <StyledCompactUSP bgcolor="colorBackground03" boxShadow="2">
        {image && (
          <StyledIconImage>
            <Image
              src={image.iconImageUrl}
              alt={image.alt}
              aspectRatio={1}
              loading={
                image.lazyLoad ? 'lazy' : /* istanbul ignore next */ 'eager'
              }
            />
          </StyledIconImage>
        )}
        <StyledCompactUSPText pl={image ? 0 : 6}>
          <Text mb={2} typography="typeBodyLabel">
            {title}
          </Text>
          <Text typography="typeBodyCaption">{description}</Text>
        </StyledCompactUSPText>
      </StyledCompactUSP>
    </>
  )
}
