import React, { FC } from 'react'
import {
  Flex,
  Heading,
  Text,
  PrimaryButton,
} from '@moonpig/launchpad-components'
import { ILLUSTRATION_ECARD_URL } from './constants'
import { EmailCaptureContainer, HeadingImage } from './Shared'

type Props = {
  heading: string
  text: string
  showContinueShopping?: boolean
  onContinueShoppingClicked?: () => void
}

export const ThankYou: FC<Props> = ({
  showContinueShopping,
  heading,
  text,
  onContinueShoppingClicked,
}) => {
  return (
    <EmailCaptureContainer>
      <Flex>
        <Flex flexDirection="column">
          <Heading
            level="h2"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {heading}
          </Heading>
          <Text as="p" mb={showContinueShopping ? 6 : 0}>
            {text}
          </Text>
        </Flex>
        <HeadingImage
          src={ILLUSTRATION_ECARD_URL}
          alt="Illustration Ecard"
          aria-hidden
          lazyLoad={false}
          height={'105px'}
        />
      </Flex>
      {showContinueShopping && (
        <PrimaryButton
          onClick={onContinueShoppingClicked}
          width={{ xs: '100%', md: 'auto' }}
          mb={6}
        >
          Continue Shopping
        </PrimaryButton>
      )}
    </EmailCaptureContainer>
  )
}
