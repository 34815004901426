export const isDeepLink = (url: string) => url.includes('crpl=')

const createCrplFragment = () => {
  return parseInt((Math.random() * 1000000000).toString(), 10)
    .toString()
    .padStart(9, '0')
}

export const generateTrackingId = () => {
  const rand1 = createCrplFragment()
  const rand2 = createCrplFragment()

  return `${rand1}.${rand2}`
}

export const enrichDeepLink = (url: string, trackingId: string) => {
  return url.replace('{rand1}.{rand2}', trackingId)
}
