import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  Flex,
  Image,
  Heading,
  Text,
  ImageProps,
} from '@moonpig/launchpad-components'

export type InfoBlockProps = {
  /** Determines which side of the text the image is rendered */
  variant: 'iconRight' | 'iconLeft'
  title: string
  body: string
  image: {
    width: number
    height: number
    url: ImageProps['src']
    alt: string
  }
}

const StyledInfoBlock = styled(Flex)<Partial<InfoBlockProps>>`
  ${({ variant }) =>
    s({
      flexDirection: {
        xs: variant === 'iconLeft' ? 'row' : 'row-reverse',
        md: 'column',
      },
      alignItems: 'center',
    })}
`

const StyledImage = styled.div`
  min-width: 104px;
  height: 104px;
  ${s({ mb: { md: 5 } })}
`

const StyledText = styled.div<Partial<InfoBlockProps>>`
  ${({ variant }) =>
    s({
      pl: { xs: variant === 'iconLeft' ? 6 : 0, md: 0 },
      pr: { xs: variant === 'iconRight' ? 6 : 0, md: 0 },
      textAlign: { xs: 'left', md: 'center' },
      width: '100%',
    })}
`

export const InfoBlock: FC<InfoBlockProps> = ({
  title,
  body,
  image,
  variant,
}) => (
  <StyledInfoBlock variant={variant}>
    <StyledImage>
      <Image
        src={image.url}
        alt={image.alt}
        aspectRatio={image.width / image.height}
        width={image.width}
        height={image.height}
      />
    </StyledImage>
    <StyledText variant={variant}>
      <Heading
        level="h3"
        mb={4}
        typography={{ xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' }}
      >
        {title}
      </Heading>
      <Text as="p" mb={0}>
        {body}
      </Text>
    </StyledText>
  </StyledInfoBlock>
)
