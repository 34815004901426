import { gql } from '@moonpig/web-core-graphql'

export const ModuleTilesFragment = gql`
  fragment ModuleTiles on ModuleTiles {
    title
    subtitle
    variant
    items {
      title
      url
      image {
        webpUrl: url(width: 282, quality: 100, format: webp)
        defaultUrl: url(width: 282, quality: 100, format: png)
        description
        dimensions {
          width
          height
        }
      }
      label {
        __typename
        ... on LabelRoundel {
          prefix
          top
          bottom
        }
        ... on LabelPromo {
          text
        }
      }
    }
  }
`
