import React, { SVGProps } from 'react'

export const IconMoonpig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 14"
    {...props}
  >
    <path
      fill="#00204D"
      d="M15.494 5.466c-.324-1.211.316-2.358 1.578-2.697 1.246-.334 2.326.353 2.65 1.564.325 1.211-.262 2.362-1.508 2.696-1.263.338-2.395-.352-2.72-1.563Zm.171 7.36 1.697-.454-1.076-4.016c.702.465 1.537.529 2.486.275 1.978-.53 3.207-2.663 2.647-4.752-.564-2.106-2.702-3.302-4.785-2.744-.828.222-1.47.752-1.907 1.47l-.202-.756-1.68.45 2.82 10.527ZM10.69 6.22C10.201 4.88 9.546 4.62 8.61 4.957c-.862.318-1.4 1.01-.943 2.265l1.394 3.852-1.65.599L6.05 7.908C5.555 6.542 4.884 6.321 3.98 6.65c-.862.318-1.396 1.028-.916 2.337l1.375 3.779-1.65.598L.243 6.363l1.649-.6.23.636c.5-.805.846-1.185 1.646-1.478.965-.354 1.845-.32 2.67.313.348-1.078 1.176-1.718 1.993-2.015 1.6-.586 3.023-.035 3.82 2.165l1.449 3.994-1.65.6L10.69 6.22Z"
    />
  </svg>
)
