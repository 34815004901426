import { gql } from '@moonpig/web-core-graphql'

export const ModuleMissionButtonsFragment = gql`
  fragment ModuleMissionButtons on ModuleMissionButtons {
    missionButtons {
      title
      url
      image {
        default: url(width: 300, quality: 80, format: jpg)
        webp: url(width: 300, quality: 80, format: webp)
      }
    }
    meta {
      experiment {
        key
        value
      }
    }
  }
`
