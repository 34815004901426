import {
  Box,
  Container,
  Grid,
  AspectRatio,
} from '@moonpig/launchpad-components'
import React, { FC, ReactNode } from 'react'

type TwoColumnLayoutProps = {
  a: ReactNode[] // Top content
  b: ReactNode[] // Hero
  c: ReactNode[] // Mobile: Below hero, Dektop: Right of hero
  d: ReactNode[] // Bottom content
}

const SideBySideLayout: FC<{ b: ReactNode[]; c: ReactNode[] }> = ({ b, c }) => (
  <Box pb={{ xs: 6, lg: 8 }}>
    <Container limitWidth>
      <Grid gap>
        <Box width={{ xs: 1, md: 1 / 2 }}>
          <AspectRatio ratio={1}>{b}</AspectRatio>
        </Box>
        <Box width={{ xs: 1, md: 1 / 2 }}>
          <Grid>
            {c.map((element, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <AspectRatio key={index} ratio={2} width={1}>
                <div>{element}</div>
              </AspectRatio>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Container>
  </Box>
)

export const TwoColumnLayout: FC<TwoColumnLayoutProps> = ({ a, b, c, d }) => (
  <Box pt={{ xs: 6, lg: 8 }}>
    {a}
    <SideBySideLayout b={b} c={c} />
    {d}
  </Box>
)
