import React, { FC, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMapCallToActions } from '../CtaAction'
import { ModuleSection } from '../../components/ModuleContainer'
import { ModuleCardFragment } from './__generated__/fragment'
import { Module } from '../types'
import { Card } from '../../components/Card'

export const CardModule: FC<Module<ModuleCardFragment>> = ({
  module: { __typename, title, ctas, ...rest },
  context: { tracking },
}) => {
  const actions = useMapCallToActions({
    ctas,
    onClick: e => {
      tracking.selectPromotion({
        module: 'card',
        label: title,
        action: e.title,
        data: e.type === 'deep-link' ? e.trackingId : undefined,
      })
    },
  })

  const [inViewRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      tracking.viewPromotion({
        module: 'card',
        label: title,
      })
    }
  }, [inView, title, tracking])

  return (
    <ModuleSection
      ref={inViewRef}
      data-testid="module-card"
      withContainer
      padding={{ py: { xs: 6, lg: 7 }, px: 0 }}
    >
      <Card title={title} ctas={actions} {...rest} />
    </ModuleSection>
  )
}
