import { Container, Strip } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { ModuleOfferStrip } from '../../types'

const OfferStripModule: FC<ModuleOfferStrip> = ({ text }) => (
  <Container limitWidth>
    <Strip pb={{ xs: 6, lg: 8 }}>{text}</Strip>
  </Container>
)

export { OfferStripModule }
