import { gql } from '@moonpig/web-core-graphql'

export const ModuleInfoFragment = gql`
  fragment ModuleInfo on ModuleInfo {
    title
    items {
      title
      text
      image {
        url(width: 156, quality: 80, format: png)
        alt: description
        dimensions {
          width
          height
        }
      }
    }
  }
`
