import { gql } from '@moonpig/web-core-graphql'

export const RegisterEmailGQL = gql`
  mutation RegisterEmail($input: RegisterEmailInput!) {
    registerEmail(input: $input) {
      __typename
      ... on RegisterEmailSuccess {
        emailAddress
      }
      __typename
      ... on InvalidEmailError {
        emailAddress
      }
    }
  }
`
