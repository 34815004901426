import React from 'react'
import Head from 'next/head'
import { ContentModule, PageContent } from '../../types'
import { BlogPost } from '../../components/BlogPost'

export type BlogPostPageProps = {
  modules: ContentModule[]
}

export const BlogPostPage: PageContent<BlogPostPageProps> = ({ modules }) => (
  <>
    <Head>
      <meta name="robots" content="max-image-preview:large" />
    </Head>
    <BlogPost modules={modules} />
  </>
)

BlogPostPage.getInitialProps = async ({ modules }) => ({
  props: { modules },
  pageOptions: {},
})
