import React, { FC, useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import {
  AddedToBasketEvent,
  createViewItemListGAEvent,
  useFavouritesState,
  useHandleFavourite,
} from '@moonpig/web-shared-products'
import { ModuleSearchResults } from '../../types'
import { ProductCarousel } from '../../components/ProductCarousel'
import { ModuleHeader, ModuleSection } from '../../components/ModuleContainer'
import {
  ProductListProducts,
  useProductListQuery,
} from '../../queries/ProductList'
import { useBasketWrapper } from '../../utils/useBasketWrapper'
import { useFindLocaleText } from '../../text-localisation'
import { selectContentGAEvent } from '../../analytics/commonGAEvents'

type Props = {
  trackingData: {
    pageLocation: string
    moduleIndex: number
    modulesLength: number
    moduleTitle: string
  }
  backgroundColor?: string
  excludeRudeCards?: boolean
} & ModuleSearchResults

type MapToTrackingInfo = (
  module: Props,
  productList: ProductListProducts,
  product?: { id: string },
) => { listName: string; label: string; itemIndex: number }

const mapToTrackingInfo: MapToTrackingInfo = (module, productList, product) => {
  const index = productList.findIndex(p => p.id === product?.id)
  const length = productList.length

  const listNameParts = [
    module.trackingData.pageLocation,
    module.title,
    `carousel`,
    `${module.trackingData.moduleIndex}/${module.trackingData.modulesLength}`,
  ]

  const labelParts = product?.id
    ? [...listNameParts, `${index + 1}/${length}`, product?.id]
    : listNameParts

  return {
    label: labelParts.join(' | ').toLowerCase(),
    listName: listNameParts.join(' | ').toLowerCase(),
    itemIndex: index,
  }
}

export const ProductListModule: FC<Props> = module => {
  const localise = useFindLocaleText()
  const buttonText = localise(
    module.alternativeButton ? 'find.view_all' : 'find.see_more',
  )

  const [canQueryRef, canQuery] = useInView({
    threshold: 0,
    rootMargin: '100px',
    triggerOnce: true,
  })

  const [canTrackRef, canTrack] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  const productList = useProductListQuery({
    canQuery,
    departments: module.departments as DepartmentsEnum[],
    facets: module.facetDetails,
    keywords: module.keywords,
    promotionId: module.promotionId,
    excludeRudeCards: module.excludeRudeCards,
  })

  const addToBasket = useBasketWrapper()

  useEffect(() => {
    if (canTrack && productList.length) {
      const { listName } = mapToTrackingInfo(module, productList)
      trackGAEvent(
        createViewItemListGAEvent({
          products: productList,
          label: listName,
        }),
      )
    }
  }, [canTrack, productList, module])

  const handleAddToBasket = useCallback<
    (e: AddedToBasketEvent) => Promise<void>
  >(
    async e => {
      await addToBasket(
        e,
        module.keywords,
        module.facetDetails,
        ({ product }) => mapToTrackingInfo(module, productList, product),
      )
    },
    [addToBasket, productList, module],
  )

  const { favourites } = useFavouritesState()
  const handleFavourite = useHandleFavourite({
    tracking: {
      totalCount: productList.length,
      pageLocation: module.trackingData.pageLocation,
    },
    removeWithConfirmation: false,
  })

  return (
    <ModuleSection
      ref={node => {
        canQueryRef(node)
        canTrackRef(node)
      }}
      data-testid="module-product-list"
      withContainer
      fullWidthOnMobile
      backgroundColor={module.backgroundColor}
      padding={{ py: { xs: 6, lg: 8 } }}
    >
      <ModuleHeader title={module.title} subtitle={module.subtitle} />
      {productList.length ? (
        <ProductCarousel
          backgroundColor={module.backgroundColor}
          title={module.title}
          url={module.url}
          productList={productList}
          favourites={favourites}
          buttonText={buttonText}
          onAddToBasket={handleAddToBasket}
          onFavourite={handleFavourite}
          onButtonClick={() =>
            trackGAEvent(
              selectContentGAEvent({
                type: module.title.toLowerCase(),
                context: 'carousel',
                action: 'select content',
                category: buttonText.toLowerCase(),
              }),
            )
          }
          onTrackingInfo={({ product }) =>
            mapToTrackingInfo(module, productList, product)
          }
        />
      ) : null}
    </ModuleSection>
  )
}
