import { Reducer } from 'react'
import { Action, State } from './types'
import { InitialState } from './Context'

export const reducer: Reducer<State, Action> = (state, action) => {
  const newAlertState: State = { ...state }
  switch (action.type) {
    case 'open-alert':
      newAlertState.variant = action.variant
      newAlertState.message = action.message
      newAlertState.open = true
      break
    case 'close-alert':
      newAlertState.variant = InitialState.variant
      newAlertState.message = InitialState.message
      newAlertState.open = false
      break
  }
  return newAlertState
}
