import { Box } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { RichTextModule } from '../../modules/RichText'
import { ContentModule } from '../../types'
import { mapModule } from '../../modules/map-modules'
import { createTracking } from '../../analytics/tracking'

type InfoProps = {
  modules: ContentModule[]
}

const renderModule = ({
  module,
  key,
}: {
  module: ContentModule
  key: number
}) => {
  const context = {
    index: key,
    layout: 'info',
    tracking: createTracking({ page: 'info' }),
  }

  switch (module.type) {
    case 'ModuleRichText':
      return <RichTextModule key={key} module={module} context={context} />
    case 'ModuleBreadcrumbs':
    case 'ModuleHeading':
    case 'ModuleHero':
      return mapModule(module, context)
    default:
      return null
  }
}

export const Info: FC<InfoProps> = ({ modules }) => (
  <Box bgcolor="white" pb={6}>
    {modules.map((module, index) =>
      renderModule({
        module,
        key: index,
      }),
    )}
  </Box>
)
