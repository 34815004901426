import { PageEnvironment } from '@moonpig/web-core-app'
import { GALLERY_PAGE_SIZE } from '../../constants'

type HeadLinkTags = {
  path: string
  offset: number
  count: number
  environment?: PageEnvironment
  canonicalSubPath?: string
}

const getCanonicalOffset = (offset: number, href: string): string | null => {
  try {
    const canonicalUrl = new URL(href)
    if (offset) {
      canonicalUrl.searchParams.set('offset', String(offset))
    } else {
      canonicalUrl.searchParams.delete('offset')
    }
    return canonicalUrl.href
  } catch {
    return null
  }
}

const getNextOffset = (
  offset: number,
  href: string,
  totalCount: number,
): string | null => {
  try {
    const nextOffset = offset + GALLERY_PAGE_SIZE
    if (nextOffset >= totalCount) {
      return null
    }
    const nextUrl = new URL(href)
    nextUrl.searchParams.set('offset', String(nextOffset))
    return nextUrl.href
  } catch {
    /* istanbul ignore next */
    return null
  }
}

const getPrevOffset = (offset: number, href: string): string | null => {
  try {
    const prevOffset = offset - GALLERY_PAGE_SIZE
    if (prevOffset < 0) {
      return null
    }
    const prevUrl = new URL(href)
    if (prevOffset >= GALLERY_PAGE_SIZE) {
      prevUrl.searchParams.set('offset', String(prevOffset))
    } else {
      prevUrl.searchParams.delete('offset')
    }
    return prevUrl.href
  } catch {
    /* istanbul ignore next */
    return null
  }
}

export const getHeadLinksTags = ({
  path,
  offset,
  count,
  environment,
  canonicalSubPath,
}: HeadLinkTags) => {
  if (environment) {
    const href = environment.hostUrl + (canonicalSubPath || path)

    const canonical = getCanonicalOffset(offset, href)

    const next = getNextOffset(offset, href, count)
    const prev = getPrevOffset(offset, href)

    return {
      canonical,
      next,
      prev,
    }
  }
  return null
}
