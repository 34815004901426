import React, { FC, PropsWithChildren, useMemo, useReducer } from 'react'
import { Alert, Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { dispatcherContext, InitialState, stateContext } from './Context'
import { reducer } from './Reducer'
import { Dispatcher } from './types'

const AlertContainer = styled(Box)`
  position: fixed;
  z-index: 2001;

  bottom: 30px;
  width: 100vw;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 600px;
  margin: auto;
  ${s({
    paddingX: 4,
  })}

  animation: alert-fade-in 500ms;

  @keyframes alert-fade-in {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
`

export const AlertProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, InitialState)

  const actions: Dispatcher = useMemo(
    () => ({
      openAlert: params =>
        dispatch({
          type: 'open-alert',
          variant: params.variant,
          message: params.message,
        }),
      closeAlert: () => dispatch({ type: 'close-alert' }),
    }),
    [],
  )

  return (
    <dispatcherContext.Provider value={actions}>
      <stateContext.Provider value={state}>
        {state.open && (
          <AlertContainer>
            <Alert
              variant={state.variant}
              onClickClose={() => actions.closeAlert()}
            >
              {state.message}
            </Alert>
          </AlertContainer>
        )}
        {children}
      </stateContext.Provider>
    </dispatcherContext.Provider>
  )
}
