import { gql } from '@moonpig/web-core-graphql'

const ProductPartsFragment = gql`
  fragment ProductParts on Product {
    id
    title
    isLandscape
    clickRankDocumentCount
    masterVariant {
      title
      sku
      price {
        centAmount
        currencyCode
        amount
      }
      images {
        url
      }
      sizeId
      inStock
    }
    description
    category {
      id
      name
      department
    }
  }
`

export const GetGiftsQuery = gql`
  query GetGiftsQuery($skus: [String!]) {
    productLookup(productIds: $skus) {
      products {
        ...ProductParts
      }
    }
  }
  ${ProductPartsFragment}
`
