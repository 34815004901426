/* eslint-disable @typescript-eslint/no-explicit-any */
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { Tracking } from '../modules/types'

const tracked: string[] = []

type CreateTrackingInput = {
  page: string
}

export const createTracking = (input: CreateTrackingInput): Tracking => {
  const track = (eventObj: any) => {
    const cookies = getBrowserCookies()
    const stringifiedEvent = JSON.stringify(eventObj)

    if (tracked.includes(stringifiedEvent)) {
      return
    }

    trackGAEvent({
      ...eventObj,
      user_data: {
        is_logged_in: (cookies.mnpg_is_authenticated || false).toString(),
      },
    })

    tracked.push(stringifiedEvent)
  }

  const join = (arr: (string | null | undefined)[]) =>
    arr.filter(Boolean).join(' | ')

  const viewPromotion: Tracking['viewPromotion'] = ({ module, label }) => {
    const fullLabel = join([module, label])

    track({
      event: 'impression',
      content_data: {
        content_type:
          `promotion | view promotion | ${fullLabel}`.toLocaleLowerCase(),
      },
      event_data: {
        category: 'promotion',
        action: 'view promotion',
        label: fullLabel.toLocaleLowerCase(),
        non_interaction: true,
      },
    })
  }

  const selectPromotion: Tracking['selectPromotion'] = ({
    module,
    label,
    action,
    data,
  }) => {
    const fullLabel = join([module, label, action])

    track({
      event: 'select_content',
      content_data: {
        content_type:
          `promotion | select promotion | ${fullLabel}`.toLocaleLowerCase(),
        item_id: data?.toLocaleLowerCase(),
      },
      event_data: {
        category: 'promotion',
        action: 'select promotion',
        label: fullLabel.toLocaleLowerCase(),
        non_interaction: true,
      },
    })
  }

  const errorPromotion: Tracking['errorPromotion'] = ({
    module,
    label,
    action,
    code,
    reason,
  }) => {
    const fullLabel = join([module, label, action])
    track({
      event: 'error',
      content_data: {
        content_type:
          `promotion | promotion error | ${fullLabel}`.toLocaleLowerCase(),
      },
      event_data: {
        category: 'promotion',
        action: `promotion error`,
        label: fullLabel.toLocaleLowerCase(),
        non_interaction: true,
      },
      error_data: {
        id: code,
        message: reason,
      },
    })
  }

  const getPage: Tracking['getPage'] = () => input.page

  return {
    selectPromotion,
    viewPromotion,
    errorPromotion,
    getPage,
  }
}
