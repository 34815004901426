import React, { SVGProps } from 'react'

export const IconBolt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 30 40"
    {...props}
  >
    <path
      fill="#000"
      d="M4.5 40a1.499 1.499 0 0 1-1.454-1.864L6.58 24H1.5a1.5 1.5 0 0 1-1.443-1.912l6-21A1.502 1.502 0 0 1 7.5 0h14a1.501 1.501 0 0 1 1.424 1.974L19.58 12h8.92a1.5 1.5 0 0 1 1.081 2.538l-24 25c-.29.303-.684.462-1.082.462Z"
    />
  </svg>
)
