import {
  Link,
  PrimaryButton,
  Heading,
  Text,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'

type HeroCta = {
  title: string
  url?: string
  onClick?: () => void
}

const StyledHeading = styled(Heading)`
  font-weight: bold;
  font-size: 5vw;

  ${breakpoint('sm')} {
    font-size: 4vw;
  }

  ${breakpoint('md')} {
    font-size: 2.4vw;
  }

  ${breakpoint('xl')} {
    font-size: 1.5rem;
  }
`

const StyledContainer = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

const StyledImageWrapper = styled.div<{ imageUrl: string }>`
  display: block;
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-size: 100%;
  height: 100%;
`

const StyledContentContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: ${({ theme: { spacing } }) => spacing[4]}px;
  left: 0;
  display: flex;
  justify-content: center;

  ${breakpoint('sm')} {
    bottom: ${({ theme: { spacing } }) => spacing[6]}px;
  }
`

const StyledContent = styled.div`
  ${s({
    p: 4,
    boxShadow: 1,
  })}

  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: calc(100% - ${({ theme: { spacing } }) => spacing[6]}px);

  ${breakpoint('sm')} {
    width: auto;
    max-width: calc(100% - ${({ theme: { spacing } }) => spacing[10]}px);

    ${s({
      p: 8,
    })}
  }

  ${breakpoint('md')} {
    ${s({
      p: 6,
      boxShadow: 1,
    })}
  }
`

const Cta: FC<{ title: string }> = ({ title }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <PrimaryButton as="div">{title}</PrimaryButton>
)

type Props = {
  title: string
  subtitle?: string
  imageUrl: string
  ctas?: {
    primary?: HeroCta
    secondary?: HeroCta
  }
  offer?: boolean
}

export const HalfWidthHero: FC<Props> = ({
  title,
  subtitle,
  imageUrl,
  ctas,
  offer,
  ...rest
}) => (
  <StyledContainer
    href={!ctas?.primary?.onClick ? ctas?.primary?.url : undefined}
    onClick={ctas?.primary?.onClick}
    role={ctas?.primary?.onClick ? 'button' : 'link'}
    aria-label={ctas?.primary?.title}
    {...rest}
  >
    <StyledImageWrapper
      data-testid="mp-find-half-width-hero-image"
      imageUrl={imageUrl}
    >
      <StyledContentContainer>
        <StyledContent>
          <StyledHeading
            level="h2"
            color={offer ? 'colorInteractionTextLink' : 'colorTextHeadline'}
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {title}
          </StyledHeading>
          {subtitle && (
            <Text
              as="p"
              color={offer ? 'colorInteractionTextLink' : 'colorTextAlternate'}
              typography={offer ? 'typeDisplay05' : 'typeBodyText'}
            >
              {subtitle}
            </Text>
          )}
          {ctas?.primary?.title && <Cta title={ctas?.primary?.title} />}
        </StyledContent>
      </StyledContentContainer>
    </StyledImageWrapper>
  </StyledContainer>
)
