import { gql } from '@moonpig/web-core-graphql'

export const ModuleEmailCaptureFragment = gql`
  fragment ModuleEmailCapture on ModuleEmailCapture {
    heading
    text
    thankYouHeading
    thankYouText
    display
  }
`
